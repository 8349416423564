import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { fraisInterface } from "./fraisSlice";
import { forfaitInterface } from "./forfaitSlice";
import { adherentInterface } from "./adherentSlice";
import { setToast } from "./ToastSlice";

export interface paiementInterface {
  id_paiement: any;
  frais: fraisInterface[];
  prixremise: number;
  forfaits: forfaitInterface[];
  sessions: sessionInterface[];
  RefundPaiement: paiementInterface[];
  RefundedPaiement: paiementInterface;
  //coachings: choachingInterface[];
  adherent_id: string;
  abonnement_id: string;
  Adherent: adherentInterface;
  observation: string;
  datepaiement: Date;
  numero: string;
  modereglement: string;
  status: string;
  total: number;
  refunded_paiement_id?: string;
  datenextpaiement?: Date | null | string;
  resteapayer?: number;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface sessionInterface {
  forfait_id: string | number;
  total: number | null;
  nombresessions: number | null;
  datedebut: Date | null | string;
  datefin: Date | null | string;
}
export interface choachingInterface {
  coach_id: string | number;
  prixcoaching: number | null;
  moiscoaching: number | null;
  datedebut: Date | null | string;
  datefin: Date | null | string;
}

export interface NewpaiementInterface {
  adherent_id: string | number;
  abonnement_id?: string | number;
  prixremise: number | null;
  observation: string;
  frais: any;
  forfaits: any;
  sessions?: any;
  //coachings?: any;
  datepaiement: Date | null | string;
  modereglement: string;
  datenextpaiement?: Date | null | string;
  resteapayer?: number;
  total: number;
  status: string;
}

const initialState = {
  paiements: [],
  paiementsAbonnement: [],
  allPaiements: [],
  allPaiementsAbonnement: [],
  paiement: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingListeAbonnement: true,
  loadingOne: true,
  errorListe: "",
};

const paiementSlice = createSlice({
  name: "paiement",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allPaiement(state, action) {
      state.paiements = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allPaiementTrue(state, action) {
      state.allPaiements = action.payload;
      state.error = "";
    },
    allPaiementAbonnement(state, action) {
      state.paiementsAbonnement = action.payload;
      state.loadingListeAbonnement = false;
      state.errorListe = "";
    },
    allPaiementAbonnementTrue(state, action) {
      state.allPaiementsAbonnement = action.payload;
      state.error = "";
    },
    onePaiement(state, action) {
      state.paiement = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
    cleanPaiementAbonnement(state) {
      state.paiementsAbonnement = [];
      state.loadingListeAbonnement = true;
      state.errorListe = "";
    },
  },
});

export const {
  allPaiement,
  allPaiementTrue,
  allPaiementAbonnement,
  allPaiementAbonnementTrue,
  onePaiement,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
  cleanPaiementAbonnement,
} = paiementSlice.actions;

export const getAllPaiement =
  (
    page?: number,
    adherent_id?: string,
    numero?: string,
    status?: string,
    moisforfait?: string,
    avecremise?: boolean,
    datepaiementmax?: Date | string,
    datepaiementmin?: Date | string,
    createdonmax?: Date | string,
    createdonmin?: Date | string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.paiementApi}${
          page ? "/?page=" + page : "/?all=true"
        }${adherent_id ? "&adherent_id=" + adherent_id : ""}${
          numero ? "&numero=" + numero : ""
        }${status ? "&status=" + status : ""}${
          moisforfait ? "&moisforfait=" + moisforfait : ""
        }${avecremise ? "&avecremise=true" : ""}${
          datepaiementmax && datepaiementmax !== "Invalid date"
            ? "&datepaiementmax=" + datepaiementmax
            : ""
        }${
          datepaiementmin && datepaiementmin !== "Invalid date"
            ? "&datepaiementmin=" + datepaiementmin
            : ""
        }${
          createdonmax && createdonmax !== "Invalid date"
            ? "&createdonmax=" + createdonmax
            : ""
        }${
          createdonmin && createdonmin !== "Invalid date"
            ? "&createdonmin=" + createdonmin
            : ""
        }`
      );
      let paiements: any;
      paiements = page ? response.data : response.data.data;
      page
        ? dispatch(allPaiement(paiements))
        : dispatch(allPaiementTrue(paiements));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };
export const getAllPaiementAbonnement =
  (page?: number, abonnement_id?: string) => async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${api.baseApi}${api.paiementApi}${
          page ? "/?page=" + page : "/?all=true"
        }${abonnement_id ? "&abonnement_id=" + abonnement_id : ""}`
      );
      let paiements: any;
      paiements = response.data;
      dispatch(allPaiementAbonnement(paiements));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getNumeroPaiement = () => async (dispatch: any) => {
  try {
    const numero = await axios.get(
      api.baseApi + api.paiementApi + "/nextnumero"
    );
    return numero.data.nextnum;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};
export const getOnePaiement = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.paiementApi + "/" + id);
    let paiement = response.data;
    dispatch(onePaiement(paiement));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addPaiement =
  (paiement: NewpaiementInterface) => async (dispatch: any) => {
    try {
      const newPaiement = await axios.post(
        api.baseApi + api.paiementApi,
        paiement
      );
      return newPaiement;
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const addPaiementRefund =
  (paiement: NewpaiementInterface) => async (dispatch: any) => {
    try {
      const newPaiement = await axios.post(
        api.baseApi + api.paiementRefundApi,
        paiement
      );
      return newPaiement;
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const addPaiementAbonnement =
  (paiement: NewpaiementInterface) => async (dispatch: any) => {
    try {
      await axios.post(api.baseApi + api.paiementAbonnementApi, paiement);
      dispatch(
        setToast({
          message: "Paiement est ajouté avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const editPaiement =
  (paiement: paiementInterface, id: number, status: string) =>
  async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.paiementApi + "/" + id, paiement);
      dispatch(
        setToast({
          message:
            status === "PAID"
              ? "Paiement est comfirmé avec succes"
              : "Paiement est annulé avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export default paiementSlice.reducer;
