import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { addVirement, getAllVirement } from "../../redux/slices/virementSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import {
  employeInterface,
  getAllEmploye,
} from "../../redux/slices/employeSlice";

export interface MyFormValues {
  observation: string;
  employe_id: any;
  datevirement: Date | null | string;
  moisapayer: Date | null | string;
  montant: number | null;
  modereglement: string;
}

export default function AddVirement({ modal, handleClose, employeId }: any) {
  const currentDate = new Date();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [loadingSend, setLoadingSend] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const employes: employeInterface[] = useSelector(
    (state: any) => state.employe.allEmployes
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllEmploye());
    if (employeId) {
      formik.setFieldValue("montant", employeId.salaire);
    }
  }, []);

  const schema = yup.object({
    employe_id: employeId
      ? yup.string()
      : yup.string().required("Employé est obligatoire"),
    montant: yup
      .number()
      .required("Montant est obligatoire")
      .notOneOf([0], "Montant est obligatoire"),
    modereglement: yup.string().required("Mode de Réglement est obligatoire"),
  });

  const formik = useFormik<MyFormValues>({
    initialValues: {
      employe_id: "",
      observation: "",
      montant: 0,
      modereglement: "ESPECE",
      datevirement: moment(currentDate).format("YYYY-MM-DD"),
      moisapayer: moment(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      ).format("YYYY-MM-DD"),
    },
    validationSchema: schema,
    onSubmit: () => {
      createVirement();
    },
  });

  const createVirement = async () => {
    let object = { ...formik.values };
    if (employeId) object.employe_id = employeId.id_employe;
    try {
      setLoadingSend(true);
      await dispatch(addVirement(object));
    } finally {
      if (modal) {
        dispatch(getAllVirement(1, employeId.id_employe));
        handleClose();
      } else {
        navigate("/virement");
      }
      setLoadingSend(false);
    }
  };

  return (
    <div>
      {!modal && (
        <Link className="w-auto inline-block" to="/virement">
          <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
            <ArrowBackIosIcon className="!text-sm	" /> Liste des Virements
            Salaires
          </div>
        </Link>
      )}
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Ajouter un Virement Salaire{" "}
      </h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {employeId ? (
            <Autocomplete
              id="employe_id"
              size="small"
              options={employes}
              getOptionLabel={(option) => {
                return (option.nom + " " + option.prenom).toUpperCase();
              }}
              value={employeId}
              disabled
              onChange={(event, value) => {
                if (value) {
                  formik.setFieldValue("employe_id", value?.id_employe);
                  formik.setFieldValue("montant", value?.salaire);
                } else {
                  formik.setFieldValue("montant", 0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Employé"
                  error={
                    formik.touched.employe_id &&
                    Boolean(formik.errors.employe_id)
                  }
                  helperText={
                    formik.touched.employe_id &&
                    (typeof formik.errors.employe_id === "string" ||
                      formik.errors.employe_id instanceof String)
                      ? formik.errors.employe_id
                      : ""
                  }
                />
              )}
            />
          ) : (
            <Autocomplete
              id="employe_id"
              size="small"
              options={employes}
              getOptionLabel={(option) => {
                return (option.nom + " " + option.prenom).toUpperCase();
              }}
              onChange={(event, value) => {
                if (value) {
                  formik.setFieldValue("employe_id", value?.id_employe);
                  formik.setFieldValue("montant", value?.salaire);
                } else {
                  formik.setFieldValue("montant", 0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Employé"
                  error={
                    formik.touched.employe_id &&
                    Boolean(formik.errors.employe_id)
                  }
                  helperText={
                    formik.touched.employe_id &&
                    (typeof formik.errors.employe_id === "string" ||
                      formik.errors.employe_id instanceof String)
                      ? formik.errors.employe_id
                      : ""
                  }
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Montant"
            name="montant"
            size="small"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            onChange={formik.handleChange}
            value={formik.values.montant}
            fullWidth
            error={!!formik.errors.montant && formik.touched.montant}
            helperText={
              formik.errors.montant && formik.touched.montant
                ? formik.errors.montant
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(value) => {
                const date = moment(value).format("YYYY-MM-DD");
                formik.setFieldValue("datevirement", date);
              }}
              value={moment(formik.values.datevirement)}
              label="Date de Virement"
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!formik.errors.datevirement,
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
          {formik.errors.datevirement && (
            <span className={styles.errorHandle}>
              {formik.errors.datevirement}
            </span>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              views={["month", "year"]}
              format="MM/YYYY"
              onChange={(value) => {
                const date = moment(value)
                  .startOf("month")
                  .format("YYYY-MM-DD");
                formik.setFieldValue("moisapayer", date);
              }}
              value={moment(formik.values.moisapayer)}
              label="Mois à payer"
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!formik.errors.moisapayer,
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            error={
              formik.touched.modereglement && !!formik.errors.modereglement
            }
            size="small"
            fullWidth
          >
            <InputLabel id="demo-simple-select-label">
              Mode de réglement
            </InputLabel>
            <Select
              name="modereglement"
              labelId="demo-simple-select-label"
              label="Mode de réglement"
              onChange={formik.handleChange}
              value={formik.values.modereglement}
            >
              <MenuItem value={"VIREMENT"}>Virement</MenuItem>
              <MenuItem value={"CHEQUE"}>Chèque</MenuItem>
              <MenuItem value={"ESPECE"}>Espèce</MenuItem>
            </Select>
            {formik.touched.modereglement && formik.errors.modereglement && (
              <FormHelperText>{formik.errors.modereglement}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Observation"
            name="observation"
            onChange={formik.handleChange}
            value={formik.values.observation}
            fullWidth
            error={!!formik.errors.observation}
            helperText={
              formik.errors.observation ? formik.errors.observation : ""
            }
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="border-t-2 border-gray-900/18">
            <div className="mt-4 flex items-center justify-end gap-x-6">
              {modal ? (
                <Button
                  className={styles.ButtonCancel}
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  onClick={handleClose}
                  disabled={loadingSend}
                  variant="contained"
                >
                  Annuler
                </Button>
              ) : (
                <Link to="/virement">
                  <Button
                    className={styles.ButtonCancel}
                    startIcon={loadingSend && <CircularProgress size={20} />}
                    disabled={loadingSend}
                    variant="contained"
                  >
                    Annuler
                  </Button>
                </Link>
              )}
              <Button
                className={styles.ButtonValid}
                startIcon={loadingSend && <CircularProgress size={20} />}
                disabled={loadingSend}
                variant="contained"
                onClick={() => formik.handleSubmit()}
              >
                Ajouter
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
