import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  type: "",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToast(state, action) {
      state.open = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    resetToast(state) {
      state.open = false;
      state.message = "";
    },
  },
});

export const { setToast, resetToast } = toastSlice.actions;

export default toastSlice.reducer;
