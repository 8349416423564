import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { addDepense } from "../../redux/slices/depenseSlice";
import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

export interface MyFormValues {
  libelle: string;
  montant: string;
  datedepense: Date | string | null;
}

export default function AddDepense() {
  const [loadingSend, setLoadingSend] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const schema = yup.object({
    libelle: yup.string().required("Libélle est obligatoire"),
    montant: yup.string().required("Montant est obligatoire"),
    datedepense: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
  });

  const initialValues: MyFormValues = {
    libelle: "",
    montant: "",
    datedepense: moment(new Date()).format("YYYY-MM-DD"),
  };
  return (
    <div>
      <Link className="w-auto inline-block" to="/depense">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des dépenses
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Ajouter une dépense{" "}
      </h1>
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            setLoadingSend(true);
            await dispatch(addDepense(values));
          } finally {
            setLoadingSend(false);
            navigate("/depense");
          }
        }}
      >
        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Libelle"
                  name="libelle"
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  error={!!errors.libelle}
                  helperText={errors.libelle ? errors.libelle : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Montant"
                  name="montant"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.montant}
                  helperText={errors.montant ? errors.montant : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setFieldValue("datedepense", date);
                    }}
                    value={moment(values.datedepense)}
                    label="Date de dépense"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.datedepense,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
                {errors.datedepense && (
                  <span className={styles.errorHandle}>
                    {errors.datedepense}
                  </span>
                )}
              </Grid>
              <Grid item xs={12}>
                <div className="border-t-2 border-gray-900/18">
                  <div className="mt-4 flex items-center justify-end gap-x-6">
                    <Link to="/depense">
                      <Button
                        className={styles.ButtonCancel}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                      >
                        Annuler
                      </Button>
                    </Link>
                    <Button
                      className={styles.ButtonValid}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                      variant="contained"
                      type="submit"
                    >
                      Ajouter
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
