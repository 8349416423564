import React, { useEffect, useState } from "react";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Spinner from "../../components/Spinner";
import { chartColors } from "../../services/constants";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
      text: "Revenus par Forfait",
    },
  },
};

interface Props {
  dataEntree: any;
  forfait: any[];
}

export default function ForfaitChart({ dataEntree, forfait }: Props) {
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>({ labels: [], datasets: [] });

  useEffect(() => {
    setError(false);
    if (forfait && dataEntree && dataEntree.length > 0) {
      let arrayData: number[] = [];
      let arrayBackgroundColor: string[] = [];
      let arrayBorderColor: string[] = [];
      let arrayLabels: string[] = [];

      dataEntree = dataEntree.filter((d: any) => {
        return d.forfait !== "Tous les forfaits";
      });

      const labels = dataEntree.map((element: any, index: any) => {
        if (
          forfait.length === 0 ||
          (forfait.length > 0 &&
            forfait.some((forf: any) => forf.libelle === element.forfait))
        ) {
          const total = element.data.reduce(function (
            accumulator: any,
            currentValue: any
          ) {
            return accumulator + currentValue.revenus;
          },
          0);
          arrayData.push(total);
          arrayBackgroundColor.push(chartColors[index]);
          arrayBorderColor.push(chartColors[index]);
          arrayLabels.push(element.forfait);
        }
      });
      const dataLine = {
        labels: arrayLabels,
        datasets: [
          {
            label: "Revenus",
            data: arrayData,
            backgroundColor: arrayBackgroundColor,
            borderColor: arrayBorderColor,
            borderWidth: 1,
          },
        ],
      };
      setData(dataLine);
      if (arrayData.length === 0) {
        setError(true);
      }
    }
  }, [dataEntree, forfait]);

  return (
      <div
          style={{
            height: error ? "15rem" : "100%",
            maxHeight: "40rem"
          }}
          className="shadow-sm w-full flex flex-col justify-center border-2 rounded-md p-5"
      >
        <h1 className="text-2xl font-bold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl pb-5">Revenus
          par Forfait</h1>
        {data && !error && (
            <Doughnut
                options={options}
                data={data}
            />
        )}
        {error && (
            <div
                className="flex justify-center items-center text-white bg-red-500 p-10 m-auto"
                style={{
                  height: "200px",
                }}
            >
              <div className="shadow-sm border-2 rounded-md pt-2">
                <label className="m-auto">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Graphique Revenu par Forfait ne
                  contient <strong>aucune donnée</strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
              </div>
            </div>
        )}
        {!data && !error && <Spinner/>}
      </div>
  );
}
