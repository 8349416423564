import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Spinner from "../../components/Spinner";
import getMonthNameInFrench from "../../utils/getMonthNameInFrench";
import { chartColors } from "../../services/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    intersect: false,
    mode: 'index' as const,
  },
  scales: {
    y: {
      beginAtZero: true,
      min: 0
    }
  },
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
      text: "Revenus Forfaits par Mois",
    },
  },
};

interface Props {
  dataEntree: any;
  forfait: any[];
}

export default function ForfaitMonthChart({ dataEntree, forfait }: Props) {
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>({ labels: [], datasets: [] });

  useEffect(() => {
    setError(false);
    if (forfait && dataEntree && dataEntree.length > 0) {
      var labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
        getMonthNameInFrench
      );
      var arrayOfData: any[] = [];
      const datasets = dataEntree.map((element: any, index: any) => {
        let arrayData: number[] = [];
        if (
          forfait.length === 0 ||
          (forfait.length > 0 &&
            forfait.some((forf: any) => forf.libelle === element.forfait))
        ) {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach((month: any) => {
            const ele = element.data.filter((el: any) => el.month === month);
            if (ele.length > 0) {
              arrayData.push(ele[0].revenus);
            } else {
              arrayData.push(0);
            }
          });
          arrayOfData.push({
            label: element.forfait,
            data: arrayData,
            borderColor: chartColors[index],
            backgroundColor: chartColors[index],
          });
        }
      });
      const dataLine = {
        labels,
        datasets: arrayOfData,
      };
      setData(dataLine);

      if (arrayOfData.length === 0) {
        setError(true);
      }
    }
  }, [dataEntree, forfait]);

  return (
    <div
      style={{
        height: error ? "15rem" : "auto",

      }}
      className="shadow-sm w-full flex flex-col justify-center border-2 rounded-md p-5"
    >
      <h1 className="text-2xl font-bold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl pb-5">Revenus par Mois</h1>

      {data && !error && (
        <Line
          options={options}
          data={data}
        />
      )}
      {error && (
        <div
          className="flex justify-center items-center text-white bg-red-500 p-10 m-auto"
          style={{
            height: "200px",
          }}
        >
          <div className="shadow-sm border-2 rounded-md pt-2">
            <label className="m-auto">
              Graphique Revenu Forfaits par Mois ne contient{" "}
              <strong>aucune donnée</strong>
            </label>
          </div>
        </div>
      )}
      {!data && !error && <Spinner />}
    </div>
  );
}
