import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { setToast } from "./ToastSlice";

export interface gymInterface {
  id_gym: any;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

const initialState = {
  gyms: [],
  allGyms: [],
  gym: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
};

const gymSlice = createSlice({
  name: "gym",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allGym(state, action) {
      state.gyms = action.payload;
      state.loadingListe = false;
    },
    allGymTrue(state, action) {
      state.allGyms = action.payload;
      state.error = "";
    },
    oneGym(state, action) {
      state.gym = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allGym,
  allGymTrue,
  oneGym,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = gymSlice.actions;

export const getAllGym = (page?: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingFalse());
    const response = await axios.get(
      `${api.baseApi}${api.gymApi}${page ? "/?page=" + page : "/?all=true"}`
    );
    let gyms: any;
    gyms = page ? response.data : response.data.data;
    page ? dispatch(allGym(gyms)) : dispatch(allGymTrue(gyms));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export const getOnegym = (id: number | string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.gymApi + "/" + id);
    let gym = response.data;
    dispatch(oneGym(gym));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export default gymSlice.reducer;
