import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  userInterface,
  editUser,
  getOneUser,
} from "../../redux/slices/userSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SpinnerBlock from "../../components/SpinnerBlock";
import { getAllRole, roleInterface } from "../../redux/slices/roleSlice";

export default function EditUser() {
  const [loadingSend, setLoadingSend] = useState(false);
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user: userInterface = useSelector((state: any) => state.user.user);
  const roles: roleInterface[] = useSelector(
    (state: any) => state.role.allRoles
  );
  const loading = useSelector((state: any) => state.user.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneUser(id));
      dispatch(getAllRole());
    } else {
      navigate("/user");
    }
  }, []);

  const schema = yup.object({
    nom: yup.string().required("Nom est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
    username: yup.string().required("Username est obligatoire"),
    role_id: yup.string().required("Role est obligatoire"),
  });

  return (
    <div>
      <Link className="w-auto inline-block" to="/user">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des utilisateurs
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Modifier un utilisateur{" "}
      </h1>

      {!loading && Object.keys(user).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={user}
          onSubmit={async (values) => {
            let { password, ...object } = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            try {
              setLoadingSend(true);
              await dispatch(editUser(object, values.id_user));
            } finally {
              setLoadingSend(false);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Nom"
                    name="nom"
                    onChange={handleChange}
                    value={values.nom}
                    fullWidth
                    size="small"
                    disabled
                    error={!!touched.nom && !!errors.nom}
                    helperText={touched.nom ? errors.nom : ""}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Prénom"
                    name="prenom"
                    onChange={handleChange}
                    value={values.prenom}
                    fullWidth
                    size="small"
                    disabled
                    error={!!touched.prenom && !!errors.prenom}
                    helperText={touched.prenom ? errors.prenom : ""}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Username"
                    name="username"
                    onChange={handleChange}
                    value={values.username}
                    fullWidth
                    size="small"
                    disabled
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username ? errors.username : ""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Role de l'utilisateur
                    </InputLabel>
                    <Select
                      name="role_id"
                      labelId="demo-simple-select-label"
                      value={values.role_id}
                      label="Role de l'utilisateur"
                      onChange={handleChange}
                    >
                      {roles.length > 0 &&
                        roles.map((role: any) => {
                          return (
                            <MenuItem value={role.id_role}>
                              {role.libelle}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="border-t-2 border-gray-900/18">
                    <div className="mt-4 flex items-center justify-end gap-x-6">
                      <Link to="/user">
                        <Button
                          className={styles.ButtonCancel}
                          startIcon={
                            loadingSend && <CircularProgress size={20} />
                          }
                          disabled={loadingSend}
                          variant="contained"
                        >
                          Annuler
                        </Button>
                      </Link>
                      <Button
                        className={styles.ButtonCancel}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        <svg
                          className="w-4 h-4 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
