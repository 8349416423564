import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  venteInterface,
  getAllVentes,
  cancelVente,
} from "../../redux/slices/gestionStockSlice";
import { Meta } from "../../services/interfaces";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SkeletonTable from "../../components/SkeletonTable";
import { Pagination, ThemeProvider } from "@mui/material";
import { theme } from "../../styles/global";
import SearchBar from "../../components/SearchBar";
import moment from "moment";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

const MySwal = withReactContent(Swal);

interface ventePaginationInterface {
  meta: Meta;
  data: venteInterface[];
}

export default function Vente() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.gestionStock.loadingVente);
  const ventes: ventePaginationInterface = useSelector(
    (state: any) => state.gestionStock.ventes
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllVentes(1));
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const annulerVente = (id: any) => {
    MySwal.fire({
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Annuler",
      title: "Voulez-vous Annuler cette Vente",
      customClass: {
        confirmButton: "shadow-none",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(cancelVente(id));
        } finally {
          dispatch(getAllVentes(page));
        }
      }
    });
  };
  return (
    <>
      <SearchBar
        getData={getAllVentes}
        page={page}
        setPage={handleChange}
        loading={loading}
        isFirstRender={isFirstRender}
        AddText="un vente"
        types={["dateminmax", "produit"]}
        titlePage={"Liste des Ventes"}
      />

      {!loading ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Nom Produit
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantité
                </th>
                <th scope="col" className="px-6 py-3">
                  Prix de Vente
                </th>
                <th scope="col" className="px-6 py-3">
                  Date de Vente
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {ventes?.data?.length > 0 ? (
                ventes.data.map((vente, key) => {
                  return (
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {vente.Produit?.libelle}
                      </th>
                      <td className="px-6 py-4">{vente.quantite}</td>
                      <td className="px-6 py-4 uppercase">{vente.prixvente}</td>
                      <td className="px-6 py-4 uppercase">
                        {vente.datevente
                          ? moment(vente.datevente).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td className="px-6 py-4 uppercase">
                        {vente.cancelled ? "Annuler" : "Valide"}
                      </td>
                      {!vente.cancelled && (
                        <td className="px-6 py-4 uppercase">
                          <span
                            onClick={() => annulerVente(vente.id_vente)}
                            className="font-medium text-red-700 hover:underline cursor-pointer"
                          >
                            <CancelPresentationIcon />
                          </span>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div
                      className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                      role="alert"
                    >
                      <span className="font-medium">Aucun Vente Trouvé</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {ventes.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 15,
                marginBottom: 5,
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={ventes.meta.totalPages}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}
