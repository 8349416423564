import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SkeletonTable from "../../components/SkeletonTable";
import {
  getSummaryEntreeApi,
  getSummarySortieApi,
} from "../../redux/slices/statistiquesSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import "moment/locale/fr";
import styles from "../../styles/Global.module.scss";
import getMonthNameInFrench from "../../utils/getMonthNameInFrench";

function formatNumber(n: number) {
  return new Intl.NumberFormat("fr-FR").format(n);
}
export default function Dashboard() {
  const [year, setYear] = useState(moment(new Date()).year());
  const [layout, setLayout] = useState("MONTH");
  const [toShow, setToShow] = useState("entree");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const summaryEntree = useSelector(
    (state: any) => state.statistique.summaryEntree
  );
  const summarySortie = useSelector(
    (state: any) => state.statistique.summarySortie
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getSummaryEntreeApi(year, layout));
    dispatch(getSummarySortieApi(year, layout));
  }, [year, layout]);

  useEffect(() => {
    if (summaryEntree && summarySortie) {
      setLoading(false);
    }
  }, [summaryEntree, summarySortie]);

  const idDayExists = (array: any[], day: any) => {
    return array.filter((obj) => obj.day === day);
  };
  const idMonthExists = (array: any[], month: any) => {
    return array.filter((obj) => obj.month === month);
  };
  const idSemestreExists = (array: any[], semestre: any) => {
    return array.filter((obj) => obj.semestre === semestre);
  };
  const idTrimestreExists = (array: any[], trimestre: any) => {
    return array.filter((obj) => obj.trimestre === trimestre);
  };

  return (
    <>
      <div className="m-auto max-w-5xl">
        <Grid
          sx={{
            marginBottom: "16px",
            marginTop: "16px",
            width: "100%!important",
          }}
          justifyContent="center"
          container
          item
          xs={12}
          lg={12}
          spacing={2}
        >
          <Grid
            container
            item
            xs={12}
            lg={4}
            spacing={1}
            justifyContent="center"
          >
            <Grid item xs={10} lg={8}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  views={["year"]}
                  format="YYYY"
                  onChange={(value) => {
                    const date = moment(value).year();
                    setYear(date);
                  }}
                  sx={{
                    backgroundColor: "#fff",
                  }}
                  value={year ? moment().year(year) : null}
                  label="Année"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => setYear(moment(new Date()).year())}
                variant="contained"
                className={`${styles.ButtonCancel} w-full`}
              >
                <RestartAltIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={4}
            spacing={1}
            justifyContent="center"
          >
            <Grid item xs={10} lg={8}>
              <FormControl
                sx={{
                  backgroundColor: "#fff",
                }}
                size="small"
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">
                  Par mois / semestre / trimestre
                </InputLabel>
                <Select
                  name="par mois / semestre / trimestre"
                  labelId="demo-simple-select-label"
                  label="par jour / mois / semestre / trimestre"
                  onChange={(e) => setLayout(e.target.value)}
                  value={layout}
                >
                  <MenuItem value={"DAY"}>Par Jour</MenuItem>
                  <MenuItem value={"MONTH"}>Par Mois</MenuItem>
                  <MenuItem value={"SEMESTRE"}>Par Semestre</MenuItem>
                  <MenuItem value={"TRIMESTRE"}>Par Trimestre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => setLayout("MONTH")}
                variant="contained"
                className={`${styles.ButtonCancel} w-full`}
              >
                <RestartAltIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className="flex gap-y-6 items-center">
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <Button
            onClick={() => setToShow("entree")}
            disabled={toShow === "entree"}
            className={styles.ButtonValid}
          >
            Entrée
          </Button>
          <Button
            onClick={() => setToShow("sortie")}
            disabled={toShow === "sortie"}
            className={styles.ButtonValid}
          >
            Sortie
          </Button>
        </ButtonGroup>
      </div>

      {toShow === "entree" ? (
        !loading && summaryEntree.length > 0 ? (
          <div className="relative height-dashboard overflow-auto shadow-md">
            <h2 className="mb-2 mt-4 text-xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-2xl">
              Chiffre Entrée
            </h2>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="sticky top-0 z-10 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {layout === "DAY" && (
                    <th scope="col" className="px-3 py-3 border-2">
                      Jours
                    </th>
                  )}
                  {layout === "MONTH" && (
                    <th scope="col" className="px-3 py-3 border-2">
                      Mois
                    </th>
                  )}
                  {layout === "SEMESTRE" && (
                    <th scope="col" className="px-3 py-3 border-2">
                      Semestre
                    </th>
                  )}
                  {layout === "TRIMESTRE" && (
                    <th scope="col" className="px-3 py-3 border-2">
                      Trimestre
                    </th>
                  )}
                  {/* <th scope="col" className="px-3 py-3 border-2 min-w-32">
                  Total coaching
                </th> */}
                  {summaryEntree.map((sum: any, key: any) => {
                    return (
                      <>
                        <th scope="col" className="px-3 py-3 border-2 min-w-32">
                          {sum.forfait}
                        </th>
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {summaryEntree.length > 0 ? (
                  summarySortie.map((sum: any, key: any) => {
                    return (
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        {layout === "DAY" && (
                          <td className="px-3 py-4 border-2">{sum.day}</td>
                        )}
                        {layout === "MONTH" && (
                          <td className="px-3 py-4 border-2">
                            {getMonthNameInFrench(sum.month)}
                          </td>
                        )}
                        {layout === "SEMESTRE" && (
                          <td className="px-3 py-4 border-2">
                            Semestre {sum.semestre}
                          </td>
                        )}
                        {layout === "TRIMESTRE" && (
                          <td className="px-3 py-4 border-2">
                            Trimestre {sum.trimestre}
                          </td>
                        )}
                        {/* <td className="px-3 py-4 border-2">
                        {formatNumber(sum.total_coaching)}
                      </td> */}
                        {summaryEntree.map((sumentree: any, key: any) => {
                          let data = [];
                          if (layout === "DAY")
                            data = idDayExists(sumentree.data, sum.day);
                          if (layout === "MONTH")
                            data = idMonthExists(sumentree.data, sum.month);
                          if (layout === "SEMESTRE")
                            data = idSemestreExists(
                              sumentree.data,
                              sum.semestre
                            );

                          if (layout === "TRIMESTRE")
                            data = idTrimestreExists(
                              sumentree.data,
                              sum.trimestre
                            );

                          if (data.length === 0) {
                            data = [
                              {
                                payants: 0,
                                revenus: 0,
                              },
                            ];
                          }
                          return (
                            <>
                              <td className="px-3 py-4 border-2">
                                <div className="flex flex-col">
                                  <span>
                                    Adherents : {formatNumber(data[0].payants)}
                                  </span>
                                  <span>
                                    Revenue : {formatNumber(data[0].revenus)}{" "}
                                    Dhs
                                  </span>
                                </div>
                              </td>
                            </>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={12}>
                      <div
                        className="p-4 mb-4 flex justify-center text-sm text-red-800 bg-red-50"
                        role="alert"
                      >
                        <span className="font-medium">
                          Aucun Summary Entrée Trouvé
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )
      ) : (
        ""
      )}

      {toShow === "sortie" ? (
        !loading ? (
          <div className="relative height-dashboard overflow-auto shadow-md">
            <h2 className="mb-2 mt-4 text-xl font-extrabold leading-none tracking-tight text-gray-800  lg:text-2xl">
              Chiffre Sortie
            </h2>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="sticky top-0 z-10 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {layout === "DAY" && (
                    <th scope="col" className="px-3 py-3 border-2">
                      Jours
                    </th>
                  )}
                  {layout === "MONTH" && (
                    <th scope="col" className="px-3 py-3 border-2">
                      Mois
                    </th>
                  )}
                  {layout === "SEMESTRE" && (
                    <th scope="col" className="px-3 py-3 border-2">
                      Semestre
                    </th>
                  )}
                  {layout === "TRIMESTRE" && (
                    <th scope="col" className="px-3 py-3 border-2">
                      Trimestre
                    </th>
                  )}

                  <th scope="col" className="px-3 py-3 border-2">
                    Total
                  </th>
                  <th scope="col" className="px-3 py-3 border-2">
                    Charges
                  </th>
                  <th scope="col" className="px-3 py-3 border-2">
                    Salaires
                  </th>
                </tr>
              </thead>
              <tbody>
                {summarySortie.length > 0 ? (
                  summarySortie.map((sum: any, key: any) => {
                    return (
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        {layout === "DAY" && (
                          <td className="px-3 py-4 border-2">{sum.day}</td>
                        )}
                        {layout === "MONTH" && (
                          <td className="px-3 py-4 border-2">
                            {getMonthNameInFrench(sum.month)}
                          </td>
                        )}
                        {layout === "SEMESTRE" && (
                          <td className="px-3 py-4 border-2">
                            Semestre {sum.semestre}
                          </td>
                        )}
                        {layout === "TRIMESTRE" && (
                          <td className="px-3 py-4 border-2">
                            Trimestre {sum.trimestre}
                          </td>
                        )}
                        <td className="px-3 py-4 border-2">
                          {formatNumber(sum.total)}
                        </td>
                        <td className="px-3 py-4 border-2">
                          {formatNumber(sum.depenses)}
                        </td>
                        <td className="px-3 py-4 border-2">
                          {formatNumber(sum.salaires)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={12}>
                      <div
                        className="p-4 mb-4 flex justify-center text-sm text-red-800 bg-red-50"
                        role="alert"
                      >
                        <span className="font-medium">
                          Aucun Summary Sortie Trouvé
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )
      ) : (
        ""
      )}
    </>
  );
}
