import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import * as yup from "yup";
import {
  employeInterface,
  editEmploye,
  getOneEmploye,
} from "../../redux/slices/employeSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import SpinnerBlock from "../../components/SpinnerBlock";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

interface EditInterface {
  annulerModif: () => void;
}
export default function EditEmploye({ annulerModif }: EditInterface) {
  const [loadingSend, setLoadingSend] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const employe: employeInterface = useSelector(
    (state: any) => state.employe.employe
  );
  const loading = useSelector((state: any) => state.employe.loadingOne);

  const schema = yup.object({
    nom: yup.string().required("Nom est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
    profession: yup.string().required("Profession est obligatoire"),
    salaire: yup.number().required("Salaire est obligatoire"),
    datedebut: yup.string().required("Date de début est obligatoire"),
  });

  return (
    <div>
      <h2 className="mb-2 mt-4 text-xl font-extrabold leading-none tracking-tight text-gray-800 md:text-xl lg:text-xl">
        Information employé
      </h2>
      {!loading && Object.keys(employe).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={employe}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            try {
              setLoadingSend(true);
              await dispatch(editEmploye(object, values.id_employe));
            } finally {
              dispatch(getOneEmploye(values.id_employe));
              setLoadingSend(false);
              annulerModif();
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Prénom"
                    name="prenom"
                    onChange={handleChange}
                    value={values.prenom}
                    fullWidth
                    size="small"
                    error={!!touched.prenom && !!errors.prenom}
                    helperText={touched.prenom ? errors.prenom : ""}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Nom"
                    name="nom"
                    onChange={handleChange}
                    value={values.nom}
                    fullWidth
                    size="small"
                    error={!!touched.nom && !!errors.nom}
                    helperText={touched.nom ? errors.nom : ""}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Salaire"
                    name="salaire"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={values.salaire}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.salaire}
                    helperText={errors.salaire ? errors.salaire : ""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(value) => {
                        const date = moment(value).format("YYYY-MM-DD");
                        setFieldValue("datedebut", date);
                      }}
                      value={moment(values.datedebut)}
                      label="Date de début"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.datedebut,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {/* {errors.datedebut && (
                    <span className={styles.errorHandle}>
                      {errors.datedebut}
                    </span>
                  )} */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    error={!!errors.profession}
                    size="small"
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-label">
                      Profession
                    </InputLabel>
                    <Select
                      name="profession"
                      labelId="demo-simple-select-label"
                      value={values.profession}
                      label="Profession"
                      onChange={handleChange}
                    >
                      <MenuItem value="Coach">Coach</MenuItem>
                      <MenuItem value="Reception">Récéption</MenuItem>
                      <MenuItem value="FemmeDeMenage">Femme de Ménage</MenuItem>
                    </Select>
                    {errors.profession && (
                      <FormHelperText>{errors.profession}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="border-t-2 border-gray-900/18">
                    <div className="mt-4 flex items-center justify-end gap-x-6">
                      <Button
                        className={styles.ButtonCancel}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        onClick={annulerModif}
                      >
                        Annuler
                      </Button>
                      <Button
                        className={styles.ButtonValid}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
