import moment from "moment";
import { abonnementInterface } from "../redux/slices/abonnementSlice";
import { Box, Chip } from "@mui/material";
import { BorderLinearProgress } from "../styles/global";

export const checkExpireDate = (abonnement: abonnementInterface) => {
  const date = new Date();
  return Math.ceil(
    moment(abonnement.expiresat).diff(moment(date), "days", true)
  );
};
export const statusAbonnement = (abonnement: abonnementInterface) => {
  if (abonnement.status === "ACTIVE") {
    if (checkExpireDate(abonnement) < 5) {
      const value = checkExpireDate(abonnement);
      const progressValue = (500 - 100 * value) / 5;
      const textExpire =
        value > 0
          ? `Expire dans ${value} jour${value === 1 ? "" : "s"}`
          : "Expire Aujourd'hui";
      return (
        <Box sx={{ width: "150px" }}>
          <span className="text-xs italic text-gray-400 text-right">
            {textExpire}
          </span>
          <BorderLinearProgress variant="determinate" value={progressValue} />
        </Box>
      );
    }
    return <Chip label="Actif" color="success" />;
  } else {
    if (abonnement.status === "PENDING") {
      return <Chip label="En Attente" color="warning" />;
    } else {
      return <Chip label="Expiré" color="error" />;
    }
  }
};
