import moment from "moment";
import { statusPaiement } from "../../services/constants";
export default function PaiementForm(props: any) {
  const { formik, formikAdherent } = props;
  return (
    <>
      <h2 className="mb-2 mt-4 text-xl font-extrabold leading-none tracking-tight text-gray-800 md:text-xl lg:text-xl">
        Paiement
      </h2>
      <div className="flex justify-between px-4">
        <div>
          <h6 className="font-bold mb-3">
            Numero de Paiement :{" "}
            <span className="text-sm font-medium">{formik.values.numero}</span>
          </h6>
          <h6 className="font-bold mb-3">
            Adhérent :{" "}
            <span className="text-sm font-medium uppercase">
              {formikAdherent.values.prenom + " " + formikAdherent.values.nom}
            </span>
          </h6>
          <h6 className="font-bold mb-3">
            Date de Paiement :{" "}
            <span className="text-sm font-medium">
              {formik.values.datepaiement
                ? moment(formik.values.datepaiement).format("YYYY-MM-DD")
                : ""}
            </span>
          </h6>
          {formik.values.status === "PAID_INCOMPLETE" && (
            <h6 className="font-bold mb-3">
              Prochain paiement :{" "}
              <span className="text-sm font-medium">
                {formik.values.datenextpaiement
                  ? moment(formik.values.datenextpaiement).format("YYYY-MM-DD")
                  : ""}
              </span>
            </h6>
          )}
        </div>
        <div>
          <h6 className="font-bold mb-3">
            Date Début :{" "}
            <span className="text-sm font-medium">
              {formik.values.datedebut
                ? moment(formik.values.datedebut).format("YYYY-MM-DD")
                : ""}
            </span>
          </h6>
          <h6 className="font-bold mb-3">
            Date Fin :{" "}
            <span className="text-sm font-medium">
              {formik.values.datefin
                ? moment(formik.values.datefin).format("YYYY-MM-DD")
                : ""}
            </span>
          </h6>
          {formik.values.status === "PAID_INCOMPLETE" && (
            <h6 className="font-bold mb-3">
              Montat payé :{" "}
              <span className="text-sm font-medium">
                {formik.values.resteapayer
                  ? parseFloat(formik.values.total.toString()) -
                    parseFloat(formik.values.resteapayer.toString()) +
                    " dhs"
                  : ""}
              </span>
            </h6>
          )}
          <h6 className="font-bold mb-3">
            Status :{" "}
            <span className="text-sm font-medium">
              {statusPaiement(formik.values.status)}
            </span>
          </h6>
        </div>
      </div>
      <div className="p-4">
        <div className="border-b border-gray-200 shadow">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr className="text-left">
                <th className="px-6 py-2 text-xs text-gray-500 border-2 text-center">
                  #
                </th>
                <th className="px-6 py-2 text-xs text-gray-500 border-2 ">
                  Services
                </th>
                <th className="px-6 py-2 text-xs text-gray-500 border-2"></th>
                <th className="px-6 py-2 text-xs text-gray-500 border-2 text-center">
                  Prix
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {props.forfaitSession === "forfait" && formik.values.forfaits ? (
                <tr className="whitespace-nowrap">
                  <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                    1
                  </td>
                  <td className="px-6 py-2 border-2">
                    <div className="text-sm text-gray-900">
                      {formik.values.forfaits.libelle}
                    </div>
                  </td>
                  <td className="px-6 py-2 border-2"></td>
                  <td className="px-6 py-2 text-center border-2">
                    {formik.values.montantForfait ?? 0}
                  </td>
                </tr>
              ) : null}
              {props.forfaitSession === "session" && formik.values.sessions ? (
                <tr className="whitespace-nowrap">
                  <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                    1
                  </td>
                  <td className="px-6 py-2">
                    <div className="text-sm text-gray-900 border-2">
                      {formik.values.sessions.forfait_id.libelle}
                    </div>
                  </td>
                  <td className="px-6 py-2 border-2"></td>
                  <td className="px-6 py-2 text-center border-2">
                    {formik.values.sessions.total ?? 0}
                  </td>
                </tr>
              ) : null}
              {/* {formik.values.coachings ? (
                <tr className="whitespace-nowrap">
                  <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                    {formik.values.coachings.moiscoaching ?? 1}
                  </td>
                  <td className="px-6 py-2 border-2">
                    <div className="text-sm text-gray-900">Coaching</div>
                  </td>
                  <td className="px-6 py-2 border-2"></td>
                  <td className="px-6 py-2 text-center border-2">
                    {formik.values.coachings.prixcoaching ?? 0}
                  </td>
                </tr>
              ) : null} */}
              {formik.values.frais
                ? formik.values.frais.map((value: any) => {
                    return (
                      <tr className="whitespace-nowrap">
                        <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                          1
                        </td>
                        <td className="px-6 py-2 border-2">
                          <div className="text-sm text-gray-900">
                            {value.libelle}
                          </div>
                        </td>
                        <td className="px-6 py-2 border-2"></td>
                        <td className="px-6 py-2 border-2 text-center">
                          {value.montant ?? 0}
                        </td>
                      </tr>
                    );
                  })
                : null}
              {formik.values.prixremise ? (
                <tr className="whitespace-nowrap">
                  <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                    1
                  </td>
                  <td className="px-6 py-2 border-2">
                    <div className="text-sm text-gray-900">Remise</div>
                  </td>
                  <td className="px-6 py-2 border-2"></td>
                  <td className="px-6 py-2 border-2 text-center">
                    - {formik.values.prixremise ?? 0}
                  </td>
                </tr>
              ) : null}
              <tr className="text-white bg-gray-800">
                <th colSpan={2}></th>
                <td className="text-sm font-bold px-4 py-2 text-center">
                  <b>Total</b>
                </td>
                <td className="text-sm font-bold px-4 py-2 text-center">
                  <b>{formik.values.total}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
