import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { addFrais } from "../../redux/slices/fraisSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const MySwal = withReactContent(Swal);

export interface MyFormValues {
  libelle: string;
  montant: number | null;
  mois: number | null;
}

export default function AddFrais() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const schema = yup.object({
    libelle: yup.string().required("Libélle est obligatoire"),
    montant: yup.number().required("Montant est obligatoire"),
    mois: yup.number().required("Nombre de Mois est obligatoire"),
  });

  const initialValues: MyFormValues = {
    libelle: "",
    montant: null,
    mois: 0,
  };
  return (
    <div>
      <Link className="w-auto inline-block" to="/frais">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des frais
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Ajouter un frais{" "}
      </h1>
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          const newFrais = await dispatch(addFrais(values));
          if (newFrais?.status) {
            MySwal.fire({
              icon: "success",
              title: "Frais est ajouté avec succes",
              customClass: {
                confirmButton: "shadow-none",
              },
            }).then((result) => {
              setLoadingSend(false);
              navigate("/frais");
            });
          }
        }}
      >
        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Libelle"
                  name="libelle"
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  value={values.libelle}
                  error={!!errors.libelle}
                  helperText={errors.libelle ? errors.libelle : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Montant"
                  name="montant"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange}
                  fullWidth
                  value={values.montant}
                  error={!!errors.montant}
                  helperText={errors.montant ? errors.montant : ""}
                />
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={8}>
                  <TextField
                    label="Nombre de mois"
                    name="mois"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    disabled={checked}
                    value={values.mois}
                    error={!!errors.mois}
                    helperText={errors.mois ? errors.mois : ""}
                  />
                </Grid>
                <Grid className="!ml-3" item xs={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.checked) {
                            setFieldValue("mois", -1);
                          } else {
                            setFieldValue("mois", 0);
                          }
                          setChecked(event.target.checked);
                        }}
                      />
                    }
                    label="Indéfini"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="border-t-2 border-gray-900/18">
                  <div className="mt-4 flex items-center justify-end gap-x-6">
                    <Link to="/frais">
                      <Button
                        className={styles.ButtonCancel}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                      >
                        Annuler
                      </Button>
                    </Link>
                    <Button
                      className={styles.ButtonValid}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                      variant="contained"
                      type="submit"
                    >
                      Ajouter
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
