import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { setToast } from "./ToastSlice";

const initialState = {
  summaryEntree: null,
  summarySortie: null,
};

const statistiqueSlice = createSlice({
  name: "statistique",
  initialState,
  reducers: {
    getSummaryEntree(state, action) {
      state.summaryEntree = action.payload;
    },
    getSummarySortie(state, action) {
      state.summarySortie = action.payload;
    },
  },
});

export const { getSummaryEntree, getSummarySortie } = statistiqueSlice.actions;

export const getSummaryEntreeApi =
  (year?: number, layout?: string) => async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${api.baseApi}${api.summaryEntreeApi}/?${year ? "&year=" + year : ""}${
          layout ? "&layout=" + layout : ""
        }`
      );
      let summaries: any;
      summaries = response.data.data.sort((a: any, b: any) => {
        if (a.forfait === "Tous les forfaits") return 1;
        if (b.forfait === "Tous les forfaits") return -1;
        return 0;
      });
      dispatch(getSummaryEntree(summaries));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getSummaryEntree({}));
    }
  };
export const getSummarySortieApi =
  (year?: number, layout?: string) => async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${api.baseApi}${api.summarySortieApi}/?${year ? "&year=" + year : ""}${
          layout ? "&layout=" + layout : ""
        }`
      );
      let summaries: any;
      summaries = response.data.data;
      dispatch(getSummarySortie(summaries));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getSummarySortie({}));
    }
  };

export default statistiqueSlice.reducer;
