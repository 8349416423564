import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "../../styles/Global.module.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { getAllForfait } from "../../redux/slices/forfaitSlice";
import moment from "moment";
import axios from "axios";
import api from "../../redux/api";

export default function Revenus() {
  const [dateFilter, setdateFilter] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);

  const dispatch = useDispatch<AppDispatch>();
  const localStorageUser = localStorage.getItem("user");
  let gymid = 1;
  if (localStorageUser) {
    let userObject = JSON.parse(localStorageUser);
    gymid = userObject.gym_id;
  }

  useEffect(() => {
    dispatch(getAllForfait());
  }, []);

  const downloadJournal = () => {
    if (dateFilter != null && dateFilter !== "Invalid date") {
      axios
        .post(
          `${api.pythonApi}/stats/month`,
          {
            gym_id: gymid,
            month: moment(dateFilter).format("M"),
            year: moment(dateFilter).format("YYYY"),
          },
          {
            responseType: "blob", // Set the responseType to 'arraybuffer' to get binary data
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");

          link.href = url;
          const noSpecialChars =
            "_" +
            moment(dateFilter)
              .format("M")
              .replace(/[^a-zA-Z0-9 ]/g, "") +
            "_" +
            moment(dateFilter)
              .format("YYYY")
              .replace(/[^a-zA-Z0-9 ]/g, "");
          link.download = "revenue" + noSpecialChars + ".pdf"; // The default name for the downloaded file
          link.click();
          URL.revokeObjectURL(url);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error downloading PDF:", error);
        });
    }
  };

  return (
    <>
      <div className="flex flex-col-reverse lg:flex-row mb-8 items-center justify-between">
        <h1 className="text-2xl text-center font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
          Revenus Mensuels
        </h1>
      </div>
      <div className={`relative flex flex-col lg:flex-row gap-2 mb-2 lg:mb-4`}>
        <div className="flex items-center flex-1 lg:w-full">
          <div className="relative w-full">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="MM/YYYY"
                views={["month", "year"]}
                onChange={(value) => {
                  const date = moment(value).format("YYYY-MM-DD");
                  setdateFilter(date);
                }}
                disabled={loading}
                value={dateFilter ? moment(dateFilter) : null}
                label={"Date"}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <Button
          onClick={() => downloadJournal()}
          variant="contained"
          disabled={loading}
          className={`${styles.ButtonValid}`}
        >
          Télécharger
        </Button>
      </div>
    </>
  );
}
