import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { userInterface, getAllUser } from "../../redux/slices/userSlice";
import { Meta } from "../../services/interfaces";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Pagination,
  ThemeProvider,
} from "@mui/material";
import { theme } from "../../styles/global";
import styles from "../../styles/Global.module.scss";
import SkeletonCard from "../../components/SkeletonCard";
import logoProfile from "../../assets/profile.webp";
import EditIcon from "@mui/icons-material/Edit";
import SearchBar from "../../components/SearchBar";
interface userPaginationInterface {
  meta: Meta;
  data: userInterface[];
}

export default function User() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.user.loadingListe);
  const users: userPaginationInterface = useSelector(
    (state: any) => state.user.users
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllUser(1));
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      <SearchBar
        getData={getAllUser}
        page={page}
        setPage={handleChange}
        loading={loading}
        isFirstRender={isFirstRender}
        AddText="un utilisateur"
        rechecheHolder="Nom"
        types={["search"]}
        titlePage={"Liste des Utilisateurs"}
      />

      {!loading ? (
        <>
          {users?.data?.length > 0 ? (
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
              {users.data.map((user, key) => {
                return (
                  <Grid key={key} item>
                    <Card
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        position: "relative",
                      }}
                    >
                      <Link
                        to={`` + user.id_user + ``}
                        className={styles.ButtonEditUser}
                      >
                        <EditIcon />
                      </Link>
                      <CardMedia
                        component="img"
                        sx={{ width: 120, height: 120 }}
                        alt={user.nom}
                        image={logoProfile}
                        className={styles.imageUser}
                      />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <p className={styles.userLabel}>
                          <span className={styles.userLabel2}>Nom : </span>
                          <span className="uppercase">
                            {user.nom ? user.nom : "-"}
                          </span>
                        </p>
                        <p className={styles.userLabel}>
                          <span className={styles.userLabel2}>Prénom : </span>
                          <span className="uppercase">
                            {user.prenom ? user.prenom : "-"}
                          </span>
                        </p>
                        {/* <p className='user-label'><span className={styles.userLabel2}>Email : </span>{user.email ? user.email : '-'}</p>
                                                        <p className='user-label'><span className={styles.userLabel2}>Téléphone : </span>{user. ? user.tel : '-'}</p> */}
                        <p className={styles.userLabel}>
                          <span className={styles.userLabel2}>Rôle : </span>
                          {user.role_id ? user.Role.libelle : "-"}
                        </p>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}{" "}
              {users.meta.totalPages > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                    marginBottom: 5,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Pagination
                      count={users.meta.totalPages}
                      page={page}
                      onChange={handleChange}
                      color="primary"
                    />
                  </ThemeProvider>
                </div>
              )}
            </ul>
          ) : (
            <div
              className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              <span className="font-medium">Aucun Utilisateur Trouvé</span>
            </div>
          )}
        </>
      ) : (
        <SkeletonCard />
      )}
    </>
  );
}
