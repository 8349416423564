import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { addForfait } from "../../redux/slices/forfaitSlice";
import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Autocomplete from "@mui/material/Autocomplete";
import { fraisInterface, getAllFrais } from "../../redux/slices/fraisSlice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface MyFormValues {
  libelle: string;
  montantannee: number | null;
  montantsixmois: number | null;
  montanttroismois: number | null;
  montantunmois: number | null;
  frais: Frais[];
}

interface Frais {
  frais_id: string;
}

export default function AddForfait() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [loadingSend, setLoadingSend] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const frais: fraisInterface[] = useSelector(
    (state: any) => state.frais.allFrais
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllFrais());
  }, []);

  const schema = yup.object({
    libelle: yup.string().required("Libélle est obligatoire"),
    montantannee: yup.number().required("Montant Pour 12 mois est obligatoire"),
    montantsixmois: yup
      .number()
      .required("Montant Pour 6 mois est obligatoire"),
    montanttroismois: yup
      .number()
      .required("Montant Pour 3 mois est obligatoire"),
    montantunmois: yup.number().required("Montant Pour 1 mois est obligatoire"),
    frais: yup.array(),
  });

  const initialValues: MyFormValues = {
    libelle: "",
    montantannee: null,
    montantsixmois: null,
    montanttroismois: null,
    montantunmois: null,
    frais: [],
  };

  return (
    <div>
      <Link className="w-auto inline-block" to="/forfait">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des forfaits
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Ajouter un forfait{" "}
      </h1>
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            setLoadingSend(true);
            await dispatch(addForfait(values));
          } finally {
            setLoadingSend(false);
            navigate("/forfait");
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Libelle"
                  name="libelle"
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  error={!!errors.libelle}
                  helperText={errors.libelle ? errors.libelle : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Montant 1 mois"
                  name="montantunmois"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.montantunmois}
                  helperText={errors.montantunmois ? errors.montantunmois : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Montant 3 mois"
                  name="montanttroismois"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.montanttroismois}
                  helperText={
                    errors.montanttroismois ? errors.montanttroismois : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Montant 6 mois"
                  name="montantsixmois"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.montantsixmois}
                  helperText={
                    errors.montantsixmois ? errors.montantsixmois : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Montant 12 mois"
                  name="montantannee"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.montantannee}
                  helperText={errors.montantannee ? errors.montantannee : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  id="frais"
                  size="small"
                  options={frais}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.libelle}
                  onChange={(_, value) => {
                    setFieldValue(
                      "frais",
                      value.map((value) => ({
                        frais_id: value.id_frais,
                      }))
                    );
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.libelle}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Frais"
                      error={
                        touched.frais &&
                        (typeof errors.frais === "string" ||
                          errors.frais instanceof String)
                      }
                      helperText={
                        touched.frais &&
                        (typeof errors.frais === "string" ||
                          errors.frais instanceof String)
                          ? errors.frais
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="border-t-2 border-gray-900/18">
                  <div className="mt-4 flex items-center justify-end gap-x-6">
                    <Link to="/forfait">
                      <Button
                        className={styles.ButtonCancel}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                      >
                        Annuler
                      </Button>
                    </Link>
                    <Button
                      className={styles.ButtonValid}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                      variant="contained"
                      type="submit"
                    >
                      Ajouter
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
