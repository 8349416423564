import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { setToast } from "./ToastSlice";

export interface adherentInterface {
  id_adherent: any;
  reference: string;
  nom: string;
  prenom: string;
  email: string;
  numero: string;
  adresse: string;
  cin: string;
  datenaissance: Date;
  coach_id: string;
  genre: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewadherentInterface {
  nom: string;
  prenom: string;
  email: string;
  numero: string;
  adresse: string;
  cin: string;
  datenaissance: Date | string | null;
  coach_id?: string;
  genre: string;
}

const initialState = {
  adherents: [],
  allAdherents: [],
  adherent: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const adherentSlice = createSlice({
  name: "adherent",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allAdherent(state, action) {
      state.adherents = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allAdherentTrue(state, action) {
      state.allAdherents = action.payload;
      state.error = "";
    },
    oneAdherent(state, action) {
      state.adherent = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allAdherent,
  allAdherentTrue,
  oneAdherent,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = adherentSlice.actions;

export const getAllAdherent =
  (
    page?: number,
    nomprenom?: string,
    reference?: string,
    tel?: string,
    cin?: string,
    createdonmax?: Date | string,
    createdonmin?: Date | string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.adherentApi}${
          page ? "/?page=" + page : "/?all=true"
        }${nomprenom ? "&nomprenom=" + nomprenom : ""}${
          reference ? "&reference=" + reference : ""
        }${tel ? "&numero=" + tel : ""}${cin ? "&cin=" + cin : ""}${
          createdonmax && createdonmax !== "Invalid date"
            ? "&createdonmax=" + createdonmax
            : ""
        }${
          createdonmin && createdonmin !== "Invalid date"
            ? "&createdonmin=" + createdonmin
            : ""
        }`
      );
      let adherents: any;
      adherents = page ? response.data : response.data.data;
      page
        ? dispatch(allAdherent(adherents))
        : dispatch(allAdherentTrue(adherents));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneadherent =
  (id: number | string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingOneFalse());
      const response = await axios.get(
        api.baseApi + api.adherentApi + "/" + id
      );
      let adherent = response.data;
      dispatch(oneAdherent(adherent));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const addAdherent =
  (adherent: NewadherentInterface) => async (dispatch: any) => {
    try {
      const newAdherent = await axios.post(
        api.baseApi + api.adherentApi,
        adherent
      );
      dispatch(
        setToast({
          message: "Adhérent est ajouté avec succes",
          type: "success",
        })
      );
      return newAdherent;
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const editAdherent =
  (adherent: NewadherentInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.adherentApi + "/" + id, adherent);
      dispatch(
        setToast({
          message: "Adhérent est modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export default adherentSlice.reducer;
