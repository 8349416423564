import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanPaiementAbonnement,
  /* getAllPaiement,
  paiementInterface, */
} from "../../redux/slices/paiementSlice";
import { Meta } from "../../services/interfaces";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  adherentInterface,
  editAdherent,
  getOneadherent,
} from "../../redux/slices/adherentSlice";
import moment from "moment";
import InformationForm from "./InformationForm";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Grid,
  Button,
  CircularProgress,
  ThemeProvider,
  Pagination,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SpinnerBlock from "../../components/SpinnerBlock";
import SkeletonTable from "../../components/SkeletonTable";
import { theme } from "../../styles/global";
import {
  abonnementInterface,
  getAllAbonnement,
} from "../../redux/slices/abonnementSlice";
//import { statusPaiement } from "../../services/constants";
import {
  //checkExpireDate,
  statusAbonnement,
} from "../../utils/statusAbonnement";
import {
  coachingInterface,
  getAllCoaching,
} from "../../redux/slices/coachingSlice";
import checkBirthday from "../../utils/checkBirthday";

/* interface paiementPaginationInterface {
  meta: Meta;
  data: paiementInterface[];
} */
interface abonnementPaginationInterface {
  meta: Meta;
  data: abonnementInterface[];
}
interface coachingPaginationInterface {
  meta: Meta;
  data: coachingInterface[];
}
export interface MyFormValues {
  reference: string;
  nom: string;
  prenom: string;
  email: string;
  genre: string;
  numero: string;
  adresse: string;
  cin: string;
  datenaissance: Date | string | null;
  coach_id?: string;
}

export default function AdherentDetails() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loadingSend, setLoadingSend] = useState(false);
  //const [page, setPage] = useState(1);
  const [pageAbo, setPageAbo] = useState(1);
  const [pageCoaching, setPageCoaching] = useState(1);
  const [edit, setEdit] = useState(false);
  const [abonnementId, setAbonnementId] = useState("");
  const [paiementId, setPaiementId] = useState("");
  const [sessionId, setSessionId] = useState("");

  //const loading = useSelector((state: any) => state.paiement.loadingListe);

  const loadingAbonnement = useSelector(
    (state: any) => state.abonnement.loadingListe
  );
  const loadingAdherent = useSelector(
    (state: any) => state.adherent.loadingOne
  );
  const loadingCoaching = useSelector(
    (state: any) => state.coaching.loadingListe
  );

  /* const paiements: paiementPaginationInterface = useSelector(
    (state: any) => state.paiement.paiements
  ); */
  const abonnements: abonnementPaginationInterface = useSelector(
    (state: any) => state.abonnement.abonnements
  );
  const coachings: coachingPaginationInterface = useSelector(
    (state: any) => state.coaching.coachings
  );

  const adherent: adherentInterface = useSelector(
    (state: any) => state.adherent.adherent
  );

  const schema1 = yup.object({
    email: yup.string().required("Email est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
    nom: yup.string().required("Nom est obligatoire"),
    numero: yup.string().required("Numero est obligatoire"),
    genre: yup.string().required("Genre est obligatoire"),
    cin: yup.string().required("CIN est obligatoire"),
  });

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneadherent(id));
      //dispatch(getAllPaiement(1, id.toString()));
      dispatch(getAllAbonnement(1, "", id.toString()));
      dispatch(getAllCoaching(1, id.toString()));

      const localStorageAbonnement = localStorage.getItem("abonnement");

      if (localStorageAbonnement) {
        let abonnementObject = JSON.parse(localStorageAbonnement);
        setAbonnementId(abonnementObject.id_abonnement);
      }

      const localStoragePaiement = localStorage.getItem("paiement");

      if (localStoragePaiement) {
        let paiementObject = JSON.parse(localStoragePaiement);
        setPaiementId(paiementObject.id_paiement);
      }

      const localStorageSession = localStorage.getItem("session");

      if (localStorageSession) {
        let sessionObject = JSON.parse(localStorageSession);
        setSessionId(sessionObject.id_paiement_session);
      }
    } else {
      navigate("/adherent");
    }
    return () => {
      dispatch(cleanPaiementAbonnement());
      localStorage.removeItem("abonnement");
      localStorage.removeItem("paiement");
    };
  }, []);

  /* const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getAllPaiement(value, id.toString()));
      setPage(value);
    }
  }; */

  const handleChangeAbonnement = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getAllAbonnement(value, "", id.toString()));
      setPageAbo(value);
    }
  };

  const handleChangeCoaching = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getAllCoaching(value, id.toString()));
      setPageCoaching(value);
    }
  };

  const formikPersonalInfo = useFormik<MyFormValues>({
    initialValues: {
      reference: "",
      nom: "",
      prenom: "",
      email: "",
      numero: "",
      genre: "",
      cin: "",
      adresse: "",
      datenaissance: null,
    },
    validationSchema: schema1,
    onSubmit: (values) => {
      updateAdherent();
    },
  });

  // Submit Create Adherent Step
  const updateAdherent = async () => {
    var data = {
      email: formikPersonalInfo.values.email,
      nom: formikPersonalInfo.values.nom,
      prenom: formikPersonalInfo.values.prenom,
      datenaissance: formikPersonalInfo.values.datenaissance,
      numero: formikPersonalInfo.values.numero,
      genre: formikPersonalInfo.values.genre,
      cin: formikPersonalInfo.values.cin,
      adresse: formikPersonalInfo.values.adresse,
    };
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      try {
        setLoadingSend(true);
        await dispatch(editAdherent(data, id));
      } finally {
        dispatch(getOneadherent(id));
        setLoadingSend(false);
        setEdit(false);
      }
    }
  };

  useEffect(() => {
    if (adherent) {
      formikPersonalInfo.setValues({
        reference: adherent.reference,
        nom: adherent.nom,
        prenom: adherent.prenom,
        email: adherent.email,
        numero: adherent.numero,
        genre: adherent.genre,
        datenaissance: adherent.datenaissance,
        cin: adherent.cin,
        adresse: adherent.adresse,
      });
    }
  }, [adherent]);

  const addAbonnement = () => {
    localStorage.setItem("adherent", JSON.stringify(adherent));
    navigate("/abonnement/new");
  };

  const addCoaching = () => {
    localStorage.setItem("adherent", JSON.stringify(adherent));
    navigate("/coaching/new");
  };

  const genderAdherent = (gender: string) => {
    return gender === "H" ? "Homme" : "Femme";
  };

  return (
    <div>
      <Link
        className="w-auto inline-block"
        to={
          abonnementId
            ? "/abonnement/" + abonnementId
            : paiementId
            ? "/paiement/" + paiementId
            : sessionId
            ? "/session/" + sessionId
            : "/adherent/"
        }
      >
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm" />{" "}
          {abonnementId
            ? "Retour Abonnement"
            : paiementId
            ? "Retour Paiement"
            : sessionId
            ? "Retour Session"
            : "Liste des adhérents"}
        </div>
      </Link>
      {!loadingAdherent ? (
        <>
          {!edit && (
            <div className="relative p-6 flex flex-col items-center md:flex-row sm:space-x-6 bg-gray-800 text-gray-100 rounded-md">
              <Button
                className={styles.ButtonEditInfo}
                onClick={() => setEdit(true)}
              >
                <EditIcon />
              </Button>
              <div className="relative w-32 h-32 overflow-hidden bg-gray-100 rounded-full bg-gray-600 mb-3 md:mb-0">
                <svg
                  className="absolute w-36 h-36 text-gray-400 -left-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="flex flex-col space-y-4 justify-center items-center md:justify-normal md:items-start">
                <div className="text-center md:text-left">
                  <h2 className="text-3xl font-semibold mb-1 uppercase">
                    <div className="flex items-center">
                      {adherent.nom + " " + adherent.prenom}
                      {checkBirthday(adherent.datenaissance)}
                    </div>
                  </h2>
                  <p className="text-sm text-gray-400">
                    Date de naissance :{" "}
                    {adherent.datenaissance
                      ? moment(adherent.datenaissance).format("YYYY-MM-DD")
                      : ""}
                  </p>
                  <p className="text-sm text-gray-400 uppercase">
                    CIN : {adherent.cin ?? ""}
                  </p>
                  <p className="text-sm text-gray-400">
                    créé par: {adherent.createdby ?? ""}
                  </p>
                </div>
                <div className=" md:flex-row flex-col flex justify-between items-center">
                  <div className="space-y-1 md:me-10">
                    <span className="flex items-center justify-center md:justify-normal space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        aria-label="Email address"
                        className="w-4 h-4"
                      >
                        <path
                          fill="currentColor"
                          d="M274.6,25.623a32.006,32.006,0,0,0-37.2,0L16,183.766V496H496V183.766ZM464,402.693,339.97,322.96,464,226.492ZM256,51.662,454.429,193.4,311.434,304.615,256,268.979l-55.434,35.636L57.571,193.4ZM48,226.492,172.03,322.96,48,402.693ZM464,464H48V440.735L256,307.021,464,440.735Z"
                        ></path>
                      </svg>
                      <span className="text-gray-400">{adherent.email}</span>
                    </span>
                    <span className="flex items-center justify-center md:justify-normal space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        aria-label="Phonenumber"
                        className="w-4 h-4"
                      >
                        <path
                          fill="currentColor"
                          d="M449.366,89.648l-.685-.428L362.088,46.559,268.625,171.176l43,57.337a88.529,88.529,0,0,1-83.115,83.114l-57.336-43L46.558,362.088l42.306,85.869.356.725.429.684a25.085,25.085,0,0,0,21.393,11.857h22.344A327.836,327.836,0,0,0,461.222,133.386V111.041A25.084,25.084,0,0,0,449.366,89.648Zm-20.144,43.738c0,163.125-132.712,295.837-295.836,295.837h-18.08L87,371.76l84.18-63.135,46.867,35.149h5.333a120.535,120.535,0,0,0,120.4-120.4v-5.333l-35.149-46.866L371.759,87l57.463,28.311Z"
                        ></path>
                      </svg>
                      <span className="text-gray-400">{adherent.numero}</span>
                    </span>
                  </div>
                  <div className="space-y-1">
                    <span className="flex items-center justify-center md:justify-normal space-x-2">
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 4H1m3 4H1m3 4H1m3 4H1m6.071.286a3.429 3.429 0 1 1 6.858 0M4 1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm9 6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
                        />
                      </svg>
                      <span className="text-gray-400">{adherent.adresse}</span>
                    </span>
                    <span className="flex items-center justify-center md:justify-normal space-x-2">
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
                        />
                      </svg>
                      <span className="text-gray-400">
                        {genderAdherent(adherent.genre)}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {edit && (
            <>
              <InformationForm formik={formikPersonalInfo} />
              <Grid item xs={12}>
                <div className="mt-4 border-t-2 border-gray-900/18">
                  <div className="mt-4 flex items-center justify-end gap-x-6">
                    <Button
                      className={styles.ButtonCancel}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                      variant="contained"
                      onClick={() => setEdit(false)}
                    >
                      Retour
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => formikPersonalInfo.handleSubmit()}
                      className={styles.ButtonValid}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                    >
                      Modifier
                    </Button>
                  </div>
                </div>
              </Grid>
            </>
          )}

          <div className="flex justify-between items-center">
            <h1 className="mb-4 mt-4 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
              Liste Paiements Coaching{" "}
            </h1>
            <Button
              variant="contained"
              className={`${styles.ButtonValid} w-32`}
              onClick={() => addCoaching()}
            >
              <span className="me-3">Ajouter</span>
              <svg
                className="w-3 h-3 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </Button>
          </div>

          {!loadingCoaching ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Coach
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Date début
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Date fin
                    </th>
                    <th scope="col" className="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {coachings?.data?.length > 0 ? (
                    coachings.data.map((coaching, key) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {coaching.Coach?.nom + " " + coaching.Coach?.prenom}
                          </th>
                          <td className="px-6 py-4">
                            {coaching.datedebut
                              ? moment(coaching.datedebut).format("YYYY-MM-DD")
                              : ""}
                          </td>
                          <td className="px-6 py-4">
                            {coaching.datefin
                              ? moment(coaching.datefin).format("YYYY-MM-DD")
                              : ""}
                          </td>
                          <td className="px-6 py-4">
                            <span
                              onClick={() => {
                                localStorage.setItem(
                                  "adherent",
                                  JSON.stringify(adherent)
                                );
                                navigate(
                                  `/coaching/` +
                                    coaching.id_paiement_coaching +
                                    ``
                                );
                              }}
                              className="font-medium text-blue-600 hover:underline cursor-pointer"
                            >
                              <svg
                                className="w-4 h-4 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 10"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"
                                />
                              </svg>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <div
                          className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                          role="alert"
                        >
                          <span className="font-medium">
                            Aucun Coching Trouvé
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {coachings.meta.totalPages > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                    marginBottom: 5,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Pagination
                      count={coachings.meta.totalPages}
                      page={pageCoaching}
                      onChange={handleChangeCoaching}
                      color="primary"
                    />
                  </ThemeProvider>
                </div>
              )}
            </div>
          ) : (
            <SkeletonTable />
          )}
          <div className="flex justify-between items-center">
            <h1 className="mb-4 mt-4 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
              Liste des abonnements{" "}
            </h1>
            <Button
              variant="contained"
              className={`${styles.ButtonValid} w-32`}
              onClick={() => addAbonnement()}
            >
              <span className="me-3">Ajouter</span>
              <svg
                className="w-3 h-3 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </Button>
          </div>
          {!loadingAbonnement ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Forfait
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Date d'expiration
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {abonnements?.data?.length > 0 ? (
                    abonnements.data.map((abonnement, key) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-6 py-4">
                            {abonnement.Forfait?.libelle}
                          </td>
                          <td className="px-6 py-4">
                            {abonnement.expiresat
                              ? moment(abonnement.expiresat).format(
                                  "YYYY-MM-DD"
                                )
                              : ""}
                          </td>
                          <td className="px-6 py-4">
                            {statusAbonnement(abonnement)}
                          </td>
                          <td className="px-6 py-4">
                            <span
                              onClick={() => {
                                localStorage.setItem(
                                  "adherent",
                                  JSON.stringify(adherent)
                                );
                                navigate(
                                  `/abonnement/` + abonnement.id_abonnement + ``
                                );
                              }}
                              className="font-medium cursor-pointer text-blue-600 hover:underline"
                            >
                              <svg
                                className="w-4 h-4 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 10"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"
                                />
                              </svg>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <div
                          className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                          role="alert"
                        >
                          <span className="font-medium">
                            Aucun Abonnement Trouvé
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {abonnements.meta.totalPages > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                    marginBottom: 5,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Pagination
                      count={abonnements.meta.totalPages}
                      page={pageAbo}
                      onChange={handleChangeAbonnement}
                      color="primary"
                    />
                  </ThemeProvider>
                </div>
              )}
            </div>
          ) : (
            <SkeletonTable />
          )}
          {/* <h1 className="mb-4 mt-4 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
            Liste des paiements{" "}
          </h1>
          {!loading ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Numéro
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Forfait
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Prix / Durée
                    </th>

                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {paiements?.data?.length > 0 ? (
                    paiements.data.map((paiement, key) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {paiement.numero}
                          </th>
                          <td className="px-6 py-4">
                            {paiement.status === "REFUNDED"
                              ? "-"
                              : paiement.forfaits.length > 0
                              ? paiement.forfaits.map((value: any) => {
                                  return value.Forfait?.libelle ?? "";
                                })
                              : paiement.sessions.length > 0
                              ? paiement.sessions.map((value: any) => {
                                  return value.Forfait?.libelle ?? "";
                                })
                              : null}
                          </td>
                          <td className="px-6 py-4">
                            {paiement.status === "REFUNDED"
                              ? "-"
                              : paiement.forfaits.length > 0
                              ? paiement.forfaits.map((value: any) => {
                                  return (
                                    value.prixforfait +
                                    " dhs / " +
                                    value.moisforfait +
                                    " mois"
                                  );
                                })
                              : paiement.sessions.length > 0
                              ? paiement.sessions.map((value: any) => {
                                  let session =
                                    value.nombresessions > 1
                                      ? value.nombresessions + " Sessions"
                                      : value.nombresessions + " Session";
                                  return value.total + " dhs / " + session;
                                })
                              : null}
                          </td>
                          <td className="px-6 py-4">
                            {statusPaiement(paiement.status)}
                          </td>
                          <td className="px-6 py-4">
                            <span
                              onClick={() => {
                                localStorage.setItem(
                                  "adherent",
                                  JSON.stringify(adherent)
                                );
                                navigate(
                                  `/paiement/` + paiement.id_paiement + ``
                                );
                              }}
                              className="font-medium cursor-pointer text-blue-600 hover:underline"
                            >
                              <svg
                                className="w-4 h-4 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 10"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"
                                />
                              </svg>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div
                          className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                          role="alert"
                        >
                          <span className="font-medium">
                            Aucune Paiement Trouvé
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {paiements.meta.totalPages > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                    marginBottom: 5,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Pagination
                      count={paiements.meta.totalPages}
                      page={page}
                      onChange={handleChange}
                      color="primary"
                    />
                  </ThemeProvider>
                </div>
              )}
            </div>
          ) : (
            <SkeletonTable />
          )} */}
        </>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
