import React from "react";
import { theme } from "../styles/global";
import { Box, CircularProgress, ThemeProvider } from "@mui/material";

export default function SpinnerBlock() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "500px",
      }}
    >
      <ThemeProvider theme={theme}>
        <CircularProgress size={100} />
      </ThemeProvider>
    </Box>
  );
}
