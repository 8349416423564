import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import authSlice from "./slices/authSclice";
import Cookies from "universal-cookie";
import adherentSlice from "./slices/adherentSlice";
import userSlice from "./slices/userSlice";
import remiseSlice from "./slices/remiseSlice";
import forfaitSlice from "./slices/forfaitSlice";
import disciplineSlice from "./slices/disciplineSlice";
import fraisSlice from "./slices/fraisSlice";
import paiementSlice from "./slices/paiementSlice";
import roleSlice from "./slices/roleSlice";
import depenseSlice from "./slices/depenseSlice";
import statistiquesSlice from "./slices/statistiquesSlice";
import employeSlice from "./slices/employeSlice";
import virementSlice from "./slices/virementSlice";
import abonnementSlice from "./slices/abonnementSlice";
import ToastSlice from "./slices/ToastSlice";
import coachingSlice from "./slices/coachingSlice";
import sessionSlice from "./slices/sessionSlice";
import gestionStockSlice from "./slices/gestionStockSlice";
import gymSlice from "./slices/gymSlice";
const cookies = new Cookies();

const token = cookies.get("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const store = configureStore({
  reducer: {
    auth: authSlice,
    adherent: adherentSlice,
    discipline: disciplineSlice,
    user: userSlice,
    remise: remiseSlice,
    forfait: forfaitSlice,
    frais: fraisSlice,
    paiement: paiementSlice,
    role: roleSlice,
    coaching: coachingSlice,
    depense: depenseSlice,
    statistique: statistiquesSlice,
    employe: employeSlice,
    virement: virementSlice,
    abonnement: abonnementSlice,
    toast: ToastSlice,
    session: sessionSlice,
    gestionStock: gestionStockSlice,
    gym: gymSlice,
  },
});
export type AppDispatch = typeof store.dispatch;

export default store;
