import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import TableChartIcon from "@mui/icons-material/TableChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import InventoryIcon from "@mui/icons-material/Inventory";
import SellIcon from "@mui/icons-material/Sell";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CategoryIcon from "@mui/icons-material/Category";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

export interface MenuProps {
  name?: string;
  show: string[];
  title?: string;
  params?: string;
  icon?: any;
  link?: string;
  items?: MenuProps[];
  children?: MenuProps[];
}

const Menus: MenuProps[] = [
  {
    title: "Tableau de bord",
    show: ["admin"],
    items: [
      {
        name: "Résumé",
        link: "summary-dashboard",
        show: ["admin"],
        icon: (
          <ShowChartIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
      {
        name: "Détails",
        link: "details-dashboard",
        show: ["admin"],
        icon: (
          <TableChartIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
      {
        name: "Revenus mensuels",
        link: "revenu-mensuel",
        show: ["admin"],
        icon: (
          <RequestQuoteIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
    ],
  },

  {
    title: "Facturation",
    show: ["admin", "recept"],
    items: [
      {
        name: "Adhérents",
        link: "adherent",
        show: ["admin", "recept"],
        icon: (
          <svg
            className="flex-shrink-0 w-5 h-5 transition duration-75"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
          </svg>
        ),
      },
      {
        name: "Abonnements",
        link: "abonnement",
        show: ["admin", "recept"],
        icon: (
          <CardMembershipIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
      {
        name: "Paiements",
        link: "paiement",
        show: ["admin", "recept"],
        icon: (
          <ReceiptIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
      {
        name: "Coaching",
        link: "coaching",
        show: ["admin", "recept"],
        icon: (
          <SportsKabaddiIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
      {
        name: "Sessions",
        link: "session",
        show: ["admin"],
        icon: (
          <DirectionsRunIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
      {
        name: "Journal de Paiement",
        link: "journal",
        show: ["admin", "recept"],
        icon: (
          <NewspaperIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
    ],
  },
  {
    title: "Dépenses et employés",
    show: ["admin", "recept"],
    items: [
      {
        name: "Dépenses",
        link: "depense",
        show: ["admin", "recept"],
        icon: (
          <svg
            className="w-5 h-5 transition duration-75 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 22 21"
          >
            <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
            <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
          </svg>
        ),
      },
      {
        name: "Salaires",
        link: "virement",
        show: ["admin"],
        icon: (
          <svg
            className="flex-shrink-0 w-5 h-5 transition duration-75 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 11 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1.75 15.363a4.954 4.954 0 0 0 2.638 1.574c2.345.572 4.653-.434 5.155-2.247.502-1.813-1.313-3.79-3.657-4.364-2.344-.574-4.16-2.551-3.658-4.364.502-1.813 2.81-2.818 5.155-2.246A4.97 4.97 0 0 1 10 5.264M6 17.097v1.82m0-17.5v2.138"
            />
          </svg>
        ),
      },

      {
        name: "Employés",
        link: "employe",
        show: ["admin"],
        icon: (
          <svg
            className="flex-shrink-0 w-5 h-5 transition duration-75 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
          </svg>
        ),
      },
    ],
  },
  {
    title: "Gestion de Stock",
    show: ["admin", "recept"],
    items: [
      {
        name: "Produits",
        link: "produit",
        show: ["admin", "recept"],
        icon: (
          <CategoryIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
      {
        name: "Ventes",
        link: "vente",
        show: ["admin", "recept"],
        icon: (
          <ShoppingCartIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
      {
        name: "Achats",
        link: "achat",
        show: ["admin", "recept"],
        icon: (
          <SellIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
      {
        name: "Stocks",
        link: "stock",
        show: ["admin", "recept"],
        icon: (
          <InventoryIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
        ),
      },
    ],
  },
  {
    title: "Administration",
    show: ["admin"],
    items: [
      {
        name: "Utilisateurs",
        link: "user",
        show: ["admin"],
        icon: (
          <svg
            className="flex-shrink-0 w-5 h-5 transition duration-75 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
          </svg>
        ),
      },
      {
        name: "Paramétrage",
        params: "setting",
        show: ["admin"],
        icon: (
          <svg
            className="flex-shrink-0 w-5 h-5 transition duration-75"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M1 5h1.424a3.228 3.228 0 0 0 6.152 0H19a1 1 0 1 0 0-2H8.576a3.228 3.228 0 0 0-6.152 0H1a1 1 0 1 0 0 2Zm18 4h-1.424a3.228 3.228 0 0 0-6.152 0H1a1 1 0 1 0 0 2h10.424a3.228 3.228 0 0 0 6.152 0H19a1 1 0 0 0 0-2Zm0 6H8.576a3.228 3.228 0 0 0-6.152 0H1a1 1 0 0 0 0 2h1.424a3.228 3.228 0 0 0 6.152 0H19a1 1 0 0 0 0-2Z" />
          </svg>
        ),
        children: [
          {
            name: "Frais",
            link: "frais",
            show: ["admin"],
          },
          {
            name: "Forfaits",
            link: "forfait",
            show: ["admin"],
          },
        ],
      },
    ],
  },
];

export default Menus;
