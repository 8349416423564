import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  paiementInterface,
  getAllPaiementAbonnement,
  cleanPaiementAbonnement,
} from "../../redux/slices/paiementSlice";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Meta } from "../../services/interfaces";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../styles/global";
import { Button, Pagination } from "@mui/material";
import SkeletonTable from "../../components/SkeletonTable";
import { statusPaiement } from "../../services/constants";
import {
  abonnementInterface,
  getFraisabonnement,
  getOneabonnement,
} from "../../redux/slices/abonnementSlice";
import moment from "moment";
import {
  checkExpireDate,
  statusAbonnement,
} from "../../utils/statusAbonnement";
import SpinnerBlock from "../../components/SpinnerBlock";
import styles from "../../styles/Global.module.scss";
import { statusFrais } from "../../utils/statusFrais";
import checkBirthday from "../../utils/checkBirthday";

interface paiementPaginationInterface {
  meta: Meta;
  data: paiementInterface[];
}
export default function AbonnementDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const [adherentId, setAdherentId] = useState("");

  const loading = useSelector(
    (state: any) => state.paiement.loadingListeAbonnement
  );
  const loadingAbonnement = useSelector(
    (state: any) => state.abonnement.loadingOne
  );
  const loadingFraisAbonnement = useSelector(
    (state: any) => state.abonnement.loadingFraisAbonnement
  );
  const paiements: paiementPaginationInterface = useSelector(
    (state: any) => state.paiement.paiementsAbonnement
  );
  const abonnement: abonnementInterface = useSelector(
    (state: any) => state.abonnement.abonnement
  );
  const fraisAbonnement = useSelector(
    (state: any) => state.abonnement.fraisAbonnement
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneabonnement(id));

      const localStorageAdherentAbo = localStorage.getItem("adherentAbo");
      const localStorageAdherent = localStorage.getItem("adherent");

      if (localStorageAdherent) {
        let abonnementObject = JSON.parse(localStorageAdherent);
        setAdherentId(abonnementObject.id_adherent);
      }

      if (localStorageAdherentAbo) {
        let abonnementObject = JSON.parse(localStorageAdherentAbo);
        setAdherentId(abonnementObject.id_adherent);
        localStorage.setItem("adherent", JSON.stringify(abonnementObject));
        localStorage.removeItem("adherentAbo");
      }
    } else {
      navigate("/paiement");
    }
    return () => {
      dispatch(cleanPaiementAbonnement());
      localStorage.removeItem("adherent");
    };
  }, []);

  useEffect(() => {
    if (abonnement && abonnement.id_abonnement) {
      dispatch(getAllPaiementAbonnement(1, abonnement.id_abonnement));
      dispatch(getFraisabonnement(abonnement.id_abonnement));
    }
  }, [abonnement]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllPaiementAbonnement(value, abonnement.id_abonnement));
  };

  const renouvelerPaiement = (abonnement: any) => {
    const localStorageAbonnement = localStorage.getItem("adherent");
    if (localStorageAbonnement) {
      let abonnementObject = JSON.parse(localStorageAbonnement);
      localStorage.setItem("adherentAbo", JSON.stringify(abonnementObject));
    }
    localStorage.setItem("abonnement", JSON.stringify(abonnement));
    localStorage.setItem("from", "abonnement");
    navigate("/paiement/new");
  };

  return (
    <>
      <Link
        className="w-auto inline-block"
        to={adherentId ? "/adherent/" + adherentId : "/abonnement"}
      >
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" />
          {adherentId ? "Retour Adhérent" : "Liste des abonnements"}
        </div>
      </Link>

      {!loadingAbonnement ? (
        <>
          <div className="relative p-6 flex flex-col items-center mb-6 md:flex-row sm:space-x-6 bg-gray-700 text-gray-100 rounded-md">
            <div className="flex flex-col space-y-4 justify-center items-center md:justify-normal md:items-start">
              <div className="text-center md:text-left">
                <h2 className="text-3xl mb-3 font-semibold">
                  {abonnement.Forfait?.libelle}
                </h2>
                <p className="flex items-center text-base text-gray-400">
                  Adhérent :{" "}
                  <span
                    onClick={() => {
                      localStorage.setItem(
                        "abonnement",
                        JSON.stringify(abonnement)
                      );
                      navigate(
                        `/adherent/` + abonnement.Adherent.id_adherent + ``
                      );
                    }}
                    className="cursor-pointer font-bold uppercase"
                  >
                    <div className="flex items-center">
                      {abonnement.Adherent?.nom +
                        " " +
                        abonnement.Adherent?.prenom}
                      {checkBirthday(abonnement.Adherent?.datenaissance)}
                    </div>
                  </span>
                </p>
                <p className="text-base text-gray-400">
                  Expiré dans :{" "}
                  <span className="font-bold">
                    {abonnement.expiresat
                      ? moment(abonnement.expiresat).format("YYYY-MM-DD")
                      : ""}
                  </span>
                </p>
                <p className="text-base text-gray-400">
                  Créé par :{" "}
                  <span className="font-bold">{abonnement.createdby}</span>
                </p>
                <p className="text-base text-gray-400 mt-3">
                  <span className="font-bold">
                    {statusAbonnement(abonnement)}
                  </span>
                </p>
              </div>

              {!loadingFraisAbonnement && (
                <div className="flex flex-wrap md:absolute bottom-4 right-3">
                  {fraisAbonnement.length > 0 &&
                    fraisAbonnement.map((frais: any) => {
                      if (frais.frais_id) {
                        return (
                          <div className="ml-3 md:mb-0 mb-3">
                            {statusFrais(frais)}
                          </div>
                        );
                      }
                    })}
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between align-center mb-8">
            <h1 className="text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
              Liste des Paiements{" "}
            </h1>
            {(true ||
              checkExpireDate(abonnement) < 5 ||
              abonnement.status === "PENDING") && (
              <div className="flex justify-end items-center">
                <Button
                  variant="contained"
                  onClick={() => renouvelerPaiement(abonnement)}
                  className={styles.ButtonValid}
                >
                  Renouveler Paiement
                </Button>
              </div>
            )}
          </div>
          {!loading ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Numéro
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Date paiement
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Total
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {paiements?.data?.length > 0 ? (
                    paiements.data.map((paiement, key) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {paiement.numero}
                          </th>
                          <td className="px-6 py-4">
                            {paiement.datepaiement
                              ? moment(paiement.datepaiement).format(
                                  "YYYY-MM-DD"
                                )
                              : ""}
                          </td>
                          <td className="px-6 py-4">{paiement.total}</td>
                          <td className="px-6 py-4">
                            {statusPaiement(paiement.status)}
                          </td>
                          <td className="px-6 py-4">
                            <span
                              onClick={() => {
                                localStorage.setItem(
                                  "abonnement",
                                  JSON.stringify(abonnement)
                                );
                                const localStorageAbonnement =
                                  localStorage.getItem("adherent");

                                if (localStorageAbonnement) {
                                  let abonnementObject = JSON.parse(
                                    localStorageAbonnement
                                  );
                                  localStorage.setItem(
                                    "adherentAbo",
                                    JSON.stringify(abonnementObject)
                                  );
                                }
                                navigate(
                                  `/paiement/` + paiement.id_paiement + ``
                                );
                              }}
                              className="font-medium text-blue-600 cursor-pointer dark:text-blue-500 hover:underline"
                            >
                              <svg
                                className="w-4 h-4 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 10"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"
                                />
                              </svg>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3}>
                        <div
                          className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                          role="alert"
                        >
                          <span className="font-medium">
                            Aucune Paiement Trouvé
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {paiements.meta.totalPages > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                    marginBottom: 5,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Pagination
                      count={paiements.meta.totalPages}
                      page={page}
                      onChange={handleChange}
                      color="primary"
                    />
                  </ThemeProvider>
                </div>
              )}
            </div>
          ) : (
            <SkeletonTable />
          )}
        </>
      ) : (
        <SpinnerBlock />
      )}
    </>
  );
}
