import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { forfaitInterface } from "./forfaitSlice";
import { paiementInterface } from "./paiementSlice";

export interface sessionInterface {
  id_paiement_session: any;
  paiement_id: string;
  Paiement: paiementInterface;
  forfait_id: string;
  Forfait: forfaitInterface;
  total: number;
  nombresessions: number;
  datedebut: Date;
  datefin: Date;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

const initialState = {
  sessions: [],
  sessionsAbonnement: [],
  allSessions: [],
  sessionStat: {},
  session: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allSession(state, action) {
      state.sessions = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allSessionTrue(state, action) {
      state.allSessions = action.payload;
      state.error = "";
    },
    sessionStat(state, action) {
      state.sessionStat = action.payload;
      state.error = "";
    },
    oneSession(state, action) {
      state.session = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allSession,
  allSessionTrue,
  oneSession,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
  sessionStat,
} = sessionSlice.actions;

export const getAllSession =
  (
    page?: number,
    forfait_id?: string,
    datedebutmax?: Date | string,
    datedebutmin?: Date | string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.sessionApi}${
          page ? "/?page=" + page : "/?all=true"
        }${forfait_id ? "&forfait_id=" + forfait_id : ""}${
          datedebutmax && datedebutmax !== "Invalid date"
            ? "&datedebutmax=" + datedebutmax
            : ""
        }${
          datedebutmin && datedebutmin !== "Invalid date"
            ? "&datedebutmin=" + datedebutmin
            : ""
        }`
      );
      let sessions: any;
      sessions = page ? response.data : response.data.data;
      page
        ? dispatch(allSession(sessions))
        : dispatch(allSessionTrue(sessions));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneSession = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.sessionApi + "/" + id);
    let session = response.data;
    dispatch(oneSession(session));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const getSessionStat =
  (
    forfait_id?: string,
    datedebutmax?: Date | string,
    datedebutmin?: Date | string
  ) =>
  async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${api.baseApi}${api.sessionApi}/stats/?${
          forfait_id ? "&forfait_id=" + forfait_id : ""
        }${
          datedebutmax && datedebutmax !== "Invalid date"
            ? "&datedebutmax=" + datedebutmax
            : ""
        }${
          datedebutmin && datedebutmin !== "Invalid date"
            ? "&datedebutmin=" + datedebutmin
            : ""
        }`
      );
      let session = response.data;
      dispatch(sessionStat(session[0]));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default sessionSlice.reducer;
