import { Chip } from "@mui/material";
import moment from "moment";

const checkBirthday = (dateOfBirth: Date) => {
  // Parse the date of birth using Moment.js
  const dob = moment(dateOfBirth);

  // Get today's date using Moment.js
  const today = moment();

  // Calculate the age difference
  const ageDifference = today.diff(dob, "years");

  // Check if the person is 18 years old or older
  if (ageDifference < 18)
    return (
      <span className="ml-3">
        <Chip label="-18" color="warning" />
      </span>
    );
};

export default checkBirthday;
