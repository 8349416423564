const ListApi = {
  baseApi: process.env.REACT_APP_API_URL,
  pythonApi: process.env.REACT_APP_API_JOURNAL_URL,
  loginApi: "api/auth/login",
  refreshApi: "api/auth/refresh",
  adherentApi: "api/adherent",
  userApi: "api/user",
  remiseApi: "api/remise",
  disciplineApi: "api/discipline",
  forfaitApi: "api/forfait",
  fraisApi: "api/frais",
  paiementApi: "api/paiement",
  coachingApi: "api/paiement_coaching",
  sessionApi: "api/paiement_session",
  paiementRefundApi: "api/paiement/refund",
  paiementAbonnementApi: "api/paiement/withabonnement",
  roleApi: "api/role",
  abonnementApi: "api/abonnement",
  fraisAbonnementApi: "api/abonnement/frais",
  depenseApi: "api/depense",
  employeApi: "api/employe",
  virementApi: "api/virement_salaire",
  summaryApi: "api/statistic/summary",
  summaryEntreeApi: "api/statistic/table_entrees",
  summarySortieApi: "api/statistic/table_sorties",
  totalEntreeApi: "api/statistic/totalentree",
  totalSortieApi: "api/statistic/totalSortie",
  revenuPerForfaitApi: "api/statistic/revenuPerForfait",
  revenuPerForfaitAndMonthApi: "api/statistic/revenuPerForfaitAndMonth",
  achatApi: "api/achat",
  venteApi: "api/vente",
  stockApi: "api/stock",
  produitApi: "api/produit",
  gymApi: "api/gym",
};
export default ListApi;
