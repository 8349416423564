import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllVirement,
  virementInterface,
} from "../../redux/slices/virementSlice";
import { Meta } from "../../services/interfaces";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  employeInterface,
  getOneEmploye,
} from "../../redux/slices/employeSlice";
import moment from "moment";
import { Button, ThemeProvider, Pagination } from "@mui/material";
import styles from "../../styles/Global.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SpinnerBlock from "../../components/SpinnerBlock";
import SkeletonTable from "../../components/SkeletonTable";
import EditEmploye from "./EditEmploye";
import Virements from "../../components/CreateModals/Virements";
import { theme } from "../../styles/global";

interface virementPaginationInterface {
  meta: Meta;
  data: virementInterface[];
}
export interface MyFormValues {
  reference: string;
  nom: string;
  prenom: string;
  email: string;
  genre: string;
  numero: string;
  datenaissance: Date | string | null;
  coach_id?: string;
}

export default function EmployeDetails() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [openVirement, setOpenVirement] = useState(false);
  const [page, setPage] = useState(1);
  const loading = useSelector((state: any) => state.virement.loadingListe);
  const loadingEmploye = useSelector((state: any) => state.employe.loadingOne);
  const virements: virementPaginationInterface = useSelector(
    (state: any) => state.virement.virements
  );
  const employe: employeInterface = useSelector(
    (state: any) => state.employe.employe
  );

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneEmploye(id));
      dispatch(getAllVirement(1, id.toString()));
    } else {
      navigate("/employes");
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getAllVirement(value, id.toString()));
      setPage(value);
    }
  };
  const addPaiment = () => {
    setOpenVirement(true);
  };

  return (
    <div>
      <Link className="w-auto inline-block" to="/employe">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des employés
        </div>
      </Link>
      {!loadingEmploye ? (
        <>
          {!edit && (
            <div className="relative p-6 flex flex-col items-center md:flex-row sm:space-x-6 bg-gray-800 text-gray-100 rounded-md">
              <Button
                className={styles.ButtonEditInfo}
                onClick={() => setEdit(true)}
              >
                <EditIcon />
              </Button>
              <div className="relative w-32 h-32 overflow-hidden bg-gray-100 rounded-full bg-gray-600 mb-3 md:mb-0">
                <svg
                  className="absolute w-36 h-36 text-gray-400 -left-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="flex flex-col space-y-4 justify-center items-center md:justify-normal md:items-start">
                <div className="text-center md:text-left">
                  <h2 className="text-2xl font-semibold uppercase">
                    {employe.nom + " " + employe.prenom}
                  </h2>
                  <span className="text-sm text-gray-400">
                    {employe.profession}
                  </span>
                </div>
                <div className="space-y-1">
                  <span className="flex items-center justify-center md:justify-normal space-x-2">
                    <svg
                      className="w-5 h-5 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z" />
                    </svg>
                    <span className="text-gray-400">
                      {employe.datedebut
                        ? moment(employe.datedebut).format("YYYY-MM-DD")
                        : ""}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
          {edit && (
            <>
              <EditEmploye annulerModif={() => setEdit(false)} />
            </>
          )}
          <h1 className="mb-4 mt-4 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
            Liste des virements{" "}
            <button onClick={() => addPaiment()} className=" ml-3">
              <svg
                className="w-3 h-3 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </button>
          </h1>
          {!loading ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Mois Payé
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Montant
                    </th>
                    {/* <th scope="col" className="px-6 py-3"></th> */}
                  </tr>
                </thead>
                <tbody>
                  {virements?.data?.length > 0 ? (
                    virements.data.map((virement, key) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {virement.moisapayer
                              ? moment(virement.moisapayer).format("YYYY-MM")
                              : ""}
                          </th>
                          <td className="px-6 py-4">{virement.montant}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <div
                          className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                          role="alert"
                        >
                          <span className="font-medium">
                            Aucune Virement Trouvé
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {virements.meta.totalPages > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                    marginBottom: 5,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Pagination
                      count={virements.meta.totalPages}
                      page={page}
                      onChange={handleChange}
                      color="primary"
                    />
                  </ThemeProvider>
                </div>
              )}
            </div>
          ) : (
            <SkeletonTable />
          )}
        </>
      ) : (
        <SpinnerBlock />
      )}
      <Virements
        open={openVirement}
        handleClose={() => setOpenVirement(false)}
        employeId={employe}
      />
    </div>
  );
}
