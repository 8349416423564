import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { setToast } from "./ToastSlice";

export interface employeInterface {
  id_employe: any;
  nom: string;
  prenom: string;
  profession: string;
  datedebut: Date;
  salaire: number;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewemployeInterface {
  nom: string;
  prenom: string;
  profession: string;
  datedebut: Date | null | string;
  salaire: number | null;
}

const initialState = {
  employes: [],
  allEmployes: [],
  employe: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const employeSlice = createSlice({
  name: "employe",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allEmploye(state, action) {
      state.employes = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allEmployeTrue(state, action) {
      state.allEmployes = action.payload;
      state.error = "";
    },
    oneEmploye(state, action) {
      state.employe = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allEmploye,
  allEmployeTrue,
  oneEmploye,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = employeSlice.actions;

export const getAllEmploye =
  (page?: number, nomprenom?: string, profession?: string) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.employeApi}${
          page ? "/?page=" + page : "/?all=true"
        }${nomprenom ? "&nomprenom=" + nomprenom : ""}${
          profession ? "&profession=" + profession : ""
        }`
      );
      let employes: any;
      employes = page ? response.data : response.data.data;
      page
        ? dispatch(allEmploye(employes))
        : dispatch(allEmployeTrue(employes));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneEmploye = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.employeApi + "/" + id);
    let employe = response.data;
    dispatch(oneEmploye(employe));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export const addEmploye =
  (employe: NewemployeInterface) => async (dispatch: any) => {
    try {
      await axios.post(api.baseApi + api.employeApi, employe);
      dispatch(
        setToast({
          message: "Employé est ajouté avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const editEmploye =
  (employe: NewemployeInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.employeApi + "/" + id, employe);
      dispatch(
        setToast({
          message: "Employé est modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export default employeSlice.reducer;
