import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { setToast } from "./ToastSlice";

export interface depenseInterface {
  id_depense: number;
  montant: number;
  libelle: string;
  datedepense: Date;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewdepenseInterface {
  montant: string;
  libelle: string;
  datedepense: Date | null | string;
}

const initialState = {
  depenses: [],
  allDepenses: [],
  depense: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const depenseSlice = createSlice({
  name: "depense",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allDepense(state, action) {
      state.depenses = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allDepenseTrue(state, action) {
      state.allDepenses = action.payload;
      state.error = "";
    },
    oneDepense(state, action) {
      state.depense = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allDepense,
  allDepenseTrue,
  oneDepense,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = depenseSlice.actions;

export const getAllDepense =
  (
    page?: number,
    libelle?: string,
    datedepensemax?: Date | string,
    datedepensemin?: Date | string,
    createdonmax?: Date | string,
    createdonmin?: Date | string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.depenseApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${
          datedepensemax && datedepensemax !== "Invalid date"
            ? "&datedepensemax=" + datedepensemax
            : ""
        }${
          datedepensemin && datedepensemin !== "Invalid date"
            ? "&datedepensemin=" + datedepensemin
            : ""
        }${
          createdonmax && createdonmax !== "Invalid date"
            ? "&createdonmax=" + createdonmax
            : ""
        }${
          createdonmin && createdonmin !== "Invalid date"
            ? "&createdonmin=" + createdonmin
            : ""
        }`
      );
      let depenses: any;
      depenses = page ? response.data : response.data.data;
      page
        ? dispatch(allDepense(depenses))
        : dispatch(allDepenseTrue(depenses));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneDepense = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.depenseApi + "/" + id);
    let depense = response.data;
    dispatch(oneDepense(depense));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export const addDepense =
  (depense: NewdepenseInterface) => async (dispatch: any) => {
    try {
      await axios.post(api.baseApi + api.depenseApi, depense);
      dispatch(
        setToast({
          message: "Dépense est ajoutée avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const editDepense =
  (depense: depenseInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.depenseApi + "/" + id, depense);
      dispatch(
        setToast({
          message: "Dépense est modifiée avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const deleteDepense = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.delete(
      api.baseApi + api.depenseApi + "/" + id
    );
    let depense = response.data;
    dispatch(oneDepense(depense));
    dispatch(
      setToast({
        message: "Dépense est supprimée avec succes",
        type: "success",
      })
    );
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export default depenseSlice.reducer;
