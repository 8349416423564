import {
  LinearProgress,
  createTheme,
  linearProgressClasses,
  styled,
} from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(31 41 55)", // Set your primary color here
      contrastText: "#fff",
    },
  },
});

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#d54646",
  },
}));
