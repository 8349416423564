import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  sessionInterface,
  getOneSession,
} from "../../redux/slices/sessionSlice";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SpinnerBlock from "../../components/SpinnerBlock";
import {
  employeInterface,
  getAllEmploye,
} from "../../redux/slices/employeSlice";
import SkeletonTable from "../../components/SkeletonTable";
import checkBirthday from "../../utils/checkBirthday";

export default function DetailSession() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const session: sessionInterface = useSelector(
    (state: any) => state.session.session
  );
  const loading = useSelector((state: any) => state.session.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneSession(id));
    } else {
      navigate("/session");
    }
  }, []);

  return (
    <div>
      <Link className="w-auto inline-block" to="/session">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" />
          Liste des Paiements Sessions
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Details Paiement Session{" "}
      </h1>
      {!loading ? (
        <>
          <div className="flex justify-between flex-col md:flex-row px-4">
            <div>
              <h6 className="font-bold mb-3">
                Numero de Paiement :{" "}
                <span className="text-sm font-medium">
                  {session.Paiement.numero}
                </span>
              </h6>
              <h6 className="flex items-center font-bold mb-3">
                Adhérent :{" "}
                <span
                  onClick={() => {
                    localStorage.setItem("session", JSON.stringify(session));
                    navigate(
                      `/adherent/` + session.Paiement.Adherent.id_adherent + ``
                    );
                  }}
                  className="text-sm font-medium cursor-pointer uppercase"
                >
                  <div className="flex items-center">
                    {session.Paiement.Adherent?.prenom +
                      " " +
                      session.Paiement.Adherent?.nom}
                    {session.Paiement.Adherent &&
                      checkBirthday(session.Paiement.Adherent?.datenaissance)}
                  </div>
                </span>
              </h6>
              <h6 className="font-bold mb-3">
                Date de Paiement :{" "}
                <span className="text-sm font-medium">
                  {session.Paiement.datepaiement
                    ? moment(session.Paiement.datepaiement).format("YYYY-MM-DD")
                    : ""}
                </span>
              </h6>
              <h6 className="font-bold mb-3">
                Créé par :{" "}
                <span className="text-sm font-medium">
                  {session.Paiement.createdby}
                </span>
              </h6>
            </div>
            <div>
              <h6 className="font-bold mb-3">
                Date Début :{" "}
                <span className="text-sm font-medium">
                  {session.datedebut
                    ? moment(session.datedebut).format("YYYY-MM-DD")
                    : ""}
                </span>
              </h6>
              <h6 className="font-bold mb-3">
                Date Fin :{" "}
                <span className="text-sm font-medium">
                  {session.datefin
                    ? moment(session.datefin).format("YYYY-MM-DD")
                    : ""}
                </span>
              </h6>
              <h6 className="font-bold mb-3">
                Nombre de sessions :{" "}
                <span className="text-sm font-medium">
                  {session.nombresessions ?? ""}
                </span>
              </h6>
            </div>
          </div>
          <div className="p-4">
            <div className="border-b border-gray-200 shadow">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr className="text-left">
                    <th className="px-6 py-2 text-xs text-gray-500 border-2 text-center">
                      #
                    </th>
                    <th className="px-6 py-2 text-xs text-gray-500 border-2 ">
                      Services
                    </th>
                    <th className="px-6 py-2 text-xs text-gray-500 border-2"></th>
                    <th className="px-6 py-2 text-xs text-gray-500 border-2 text-center">
                      Prix
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                      1
                    </td>
                    <td className="px-6 py-2 border-2">
                      <div className="text-sm text-gray-900">
                        {session.Forfait.libelle}
                      </div>
                    </td>
                    <td className="px-6 py-2 border-2"></td>
                    <td className="px-6 py-2 text-center border-2">
                      {session.total}
                    </td>
                  </tr>
                  <tr className="text-white bg-gray-800">
                    <th colSpan={2}></th>
                    <td className="text-sm font-bold px-4 py-2 text-center">
                      <b>Total</b>
                    </td>
                    <td className="text-sm font-bold px-4 py-2 text-center">
                      <b>{session.total}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
}
