import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  abonnementInterface,
  getAllAbonnement,
} from "../../redux/slices/abonnementSlice";
import { Meta } from "../../services/interfaces";
import { Link } from "react-router-dom";
import SkeletonTable from "../../components/SkeletonTable";
import { Pagination, ThemeProvider } from "@mui/material";
import { theme } from "../../styles/global";
import SearchBar from "../../components/SearchBar";
import moment from "moment";
import { statusAbonnement } from "../../utils/statusAbonnement";
import checkBirthday from "../../utils/checkBirthday";

interface abonnementPaginationInterface {
  meta: Meta;
  data: abonnementInterface[];
}

export default function Abonnement() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.abonnement.loadingListe);
  const abonnements: abonnementPaginationInterface = useSelector(
    (state: any) => state.abonnement.abonnements
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllAbonnement(1));
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      <SearchBar
        getData={getAllAbonnement}
        setPage={handleChange}
        page={page}
        loading={loading}
        isFirstRender={isFirstRender}
        noButtonAdd
        dateHolder={"Expire"}
        types={["adherent", "dateminmax", "statusabo", "forfait"]}
        titlePage={"Liste des Abonnements"}
      />

      {!loading ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Adhérent
              </th>
              <th scope="col" className="px-6 py-3">
                Téléphone
              </th>
              <th scope="col" className="px-6 py-3">
                Forfait
              </th>
              <th scope="col" className="px-6 py-3">
                Date d'expiration
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3"></th>
            </tr>
            </thead>
            <tbody>
            {abonnements?.data?.length > 0 ? (
                abonnements.data.map((abonnement, key) => {
                  return (
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white uppercase"
                        >
                          <div className="flex items-center">
                            {abonnement.Adherent?.nom +
                                " " +
                                abonnement.Adherent?.prenom}
                            {checkBirthday(abonnement.Adherent?.datenaissance)}
                          </div>
                        </th>
                        <td className="px-6 py-4">
                          {abonnement.Adherent?.numero}
                        </td>
                        <td className="px-6 py-4">
                          {abonnement.Forfait?.libelle}
                        </td>
                        <td className="px-6 py-4">
                          {abonnement.expiresat
                              ? moment(abonnement.expiresat).format("YYYY-MM-DD")
                              : ""}
                        </td>
                        <td className="px-6 py-4">
                          {statusAbonnement(abonnement)}
                        </td>
                        <td className="px-6 py-4">
                          <Link
                              to={`` + abonnement.id_abonnement + ``}
                              className="font-medium text-blue-600 hover:underline"
                          >
                            <svg
                                className="w-4 h-4 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 10"
                            >
                              <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"
                              />
                            </svg>
                          </Link>
                        </td>
                      </tr>
                  );
                })
            ) : (
                <tr>
                  <td colSpan={5}>
                    <div
                      className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                      role="alert"
                    >
                      <span className="font-medium">
                        Aucun Abonnement Trouvé
                      </span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {abonnements.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 15,
                marginBottom: 5,
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={abonnements.meta.totalPages}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}
