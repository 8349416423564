import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  paiementInterface,
  editPaiement,
  getOnePaiement,
  addPaiementRefund,
} from "../../redux/slices/paiementSlice";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SkeletonTable from "../../components/SkeletonTable";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import SpinnerPage from "../../components/SpinnerPage";
import { useFormik } from "formik";
import * as yup from "yup";
import { statusPaiement } from "../../services/constants";
import moment from "moment";
import checkBirthday from "../../utils/checkBirthday";
import ReceiptPdf from "../../components/ReceiptPdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { getOnegym, gymInterface } from "../../redux/slices/gymSlice";

const MySwal = withReactContent(Swal);
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};

export interface MyFormValues2 {
  adherent_id: string | number;
  observation: string;
  prixremise: number | null;
  frais: null;
  forfaits: null;
  sessions: any | null;
  datedebut: Date | null | string;
  datefin: Date | null | string;
  montantForfait: number | null;
  moisForfait: number | null;
  status: string;
  datepaiement: Date | null | string;
  modereglement: string;
  total: number;
}

export default function PaiementDetails() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const paiement: paiementInterface = useSelector(
    (state: any) => state.paiement.paiement
  );
  const gym: gymInterface = useSelector((state: any) => state.gym.gym);

  const [abonnementId, setAbonnementId] = useState("");
  const [adherentId, setAdherentId] = useState("");

  const [datedebut, setDatedebut] = useState<any>(null);
  const [datefin, setDatefin] = useState<any>(null);
  const [remboursementPopUp, setRemboursementPopUp] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const loading = useSelector((state: any) => state.paiement.loadingOne);
  const loadingGym = useSelector((state: any) => state.gym.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOnePaiement(id));
      dispatch(getOnegym(id));
      const localStorageAbonnement = localStorage.getItem("abonnement");
      const localStorageAdherent = localStorage.getItem("adherent");
      const localStorageUser = localStorage.getItem("user");
      if (localStorageUser) {
        let userObject = JSON.parse(localStorageUser);
        dispatch(getOnegym(userObject.gym_id));
      }
      if (localStorageAbonnement) {
        let abonnementObject = JSON.parse(localStorageAbonnement);
        setAbonnementId(abonnementObject.id_abonnement);
      }
      if (localStorageAdherent) {
        let adherentObject = JSON.parse(localStorageAdherent);
        setAdherentId(adherentObject.id_adherent);
      }
    } else {
      navigate("/paiement");
    }

    return () => {
      localStorage.removeItem("abonnement");
      localStorage.removeItem("adherent");
    };
  }, [params]);

  const schema2 = yup.object({
    total: yup.number().required("Montant a Remboursé est obligatoire"),
  });

  const formik = useFormik<MyFormValues2>({
    initialValues: {
      adherent_id: "",
      observation: "",
      prixremise: null,
      frais: null,
      forfaits: null,
      sessions: null,
      datedebut: new Date(),
      datefin: new Date(),
      datepaiement: new Date(),
      modereglement: "",
      status: "PAID",
      total: 0,
      montantForfait: 0,
      moisForfait: 0,
    },
    validationSchema: schema2,
    onSubmit: (values) => {
      saveInformationPaiement();
    },
  });
  const saveInformationPaiement = async () => {
    var dataPaiement = { ...formik.values };
    let id: number;

    if (params.id) {
      id = parseInt(params.id);
      const values = {
        refunded_paiement_id: id,
        adherent_id: paiement.adherent_id,
        abonnement_id: paiement.abonnement_id,
        observation: dataPaiement.observation,
        prixremise: 0,
        frais: null,
        forfaits: null,
        sessions: null,
        datepaiement: paiement.datepaiement,
        modereglement: paiement.modereglement,
        total: Math.abs(dataPaiement.total) * -1,
        status: "REFUNDED",
      };
      setLoadingSend(true);
      const newPaiement = await dispatch(addPaiementRefund(values));
      if (newPaiement?.status) {
        MySwal.fire({
          icon: "success",
          title: "Remboursement est ajouté avec succes",
          customClass: {
            confirmButton: "shadow-none",
          },
        }).then((result) => {
          setRemboursementPopUp(false);
          dispatch(getOnePaiement(paiement.id_paiement));
        });
      }
      setLoadingSend(false);
    }
  };

  useEffect(() => {
    if (paiement) {
      if (paiement.forfaits?.length > 0) {
        setDatedebut(paiement.forfaits[0].datedebut);
        setDatefin(paiement.forfaits[0].datefin);
      }
      if (paiement.sessions?.length > 0) {
        setDatedebut(paiement.sessions[0].datedebut);
        setDatefin(paiement.sessions[0].datefin);
      }
    }
  }, [paiement]);

  const saveStatus = async (status: string) => {
    const values = { ...paiement, status: status };
    MySwal.fire({
      icon: "warning",
      title: "Voulez vous modifier le status du paiment",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui",
      customClass: {
        confirmButton: "shadow-none",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoadingChange(true);
          await dispatch(editPaiement(values, paiement.id_paiement, status));
        } finally {
          setLoadingChange(false);
          dispatch(getOnePaiement(paiement.id_paiement));
        }
      }
    });
  };

  return (
    <div>
      <Link
        className="w-auto inline-block"
        to={
          abonnementId
            ? "/abonnement/" + abonnementId
            : adherentId
            ? "/adherent/" + adherentId
            : "/paiement"
        }
      >
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" />
          {abonnementId
            ? "Retour Abonnement"
            : adherentId
            ? "Retour Adhérent"
            : "Liste des paiments"}
        </div>
      </Link>
      <div className="flex justify-between items-center w-full mb-8">
        <h1 className="text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
          Details Paiement{" "}
        </h1>

        {!loading && !loadingGym ? (
          <PDFDownloadLink
            document={<ReceiptPdf paiement={paiement} gym={gym} />}
            fileName={"facture_" + paiement.numero}
          >
            {({ loading }) =>
              loading ? (
                <Button
                  variant="contained"
                  className={styles.ButtonValid}
                  disabled={true}
                >
                  Télécharger
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={styles.ButtonValid}
                  disabled={loading}
                >
                  Télécharger
                </Button>
              )
            }
          </PDFDownloadLink>
        ) : (
          <Button
            variant="contained"
            className={styles.ButtonValid}
            disabled={true}
          >
            Télécharger
          </Button>
        )}
      </div>
      {!loading ? (
        <>
          <div className="flex justify-between flex-col md:flex-row px-4">
            <div>
              <h6 className="font-bold mb-3">
                Numero de Paiement :{" "}
                <span className="text-sm font-medium">{paiement.numero}</span>
              </h6>
              <h6 className="flex items-center font-bold mb-3">
                Adhérent :{" "}
                <span
                  onClick={() => {
                    localStorage.setItem("paiement", JSON.stringify(paiement));
                    navigate(`/adherent/` + paiement.Adherent.id_adherent + ``);
                  }}
                  className="text-sm font-medium cursor-pointer uppercase"
                >
                  <span className="flex items-center">
                    {paiement.Adherent?.nom + " " + paiement.Adherent?.prenom}
                    {checkBirthday(paiement.Adherent?.datenaissance)}
                  </span>
                </span>
              </h6>
              <h6 className="font-bold mb-3">
                Date de Paiement :{" "}
                <span className="text-sm font-medium">
                  {paiement.datepaiement
                    ? moment(paiement.datepaiement).format("YYYY-MM-DD")
                    : ""}
                </span>
              </h6>
              {paiement.status === "PAID_INCOMPLETE" && (
                <h6 className="font-bold mb-3">
                  Prochain paiement :{" "}
                  <span className="text-sm font-medium">
                    {paiement.datenextpaiement
                      ? moment(paiement.datenextpaiement).format("YYYY-MM-DD")
                      : ""}
                  </span>
                </h6>
              )}
              {paiement.status === "PAID_REFUNDED" &&
                paiement.RefundPaiement.length > 0 && (
                  <h6 className="font-bold mb-3">
                    Remboursement de Paiement :{" "}
                    <span
                      onClick={() => {
                        navigate(
                          `/paiement/` +
                            paiement.RefundPaiement[0].id_paiement +
                            ``
                        );
                      }}
                      className="text-sm font-medium cursor-pointer"
                    >
                      {paiement.RefundPaiement[0].numero}
                    </span>
                  </h6>
                )}
              {paiement.status === "REFUNDED" && paiement.RefundedPaiement && (
                <h6 className="font-bold mb-3">
                  Paiement Remboursé :{" "}
                  <span
                    onClick={() => {
                      navigate(
                        `/paiement/` +
                          paiement.RefundedPaiement.id_paiement +
                          ``
                      );
                    }}
                    className="text-sm font-medium cursor-pointer"
                  >
                    {paiement.RefundedPaiement.numero}
                  </span>
                </h6>
              )}
              <h6 className="font-bold mb-3">
                Créé par :{" "}
                <span className="text-sm font-medium">
                  {paiement.createdby}
                </span>
              </h6>
            </div>
            <div>
              <h6 className="font-bold mb-3">
                Date Début :{" "}
                <span className="text-sm font-medium">
                  {datedebut ? moment(datedebut).format("YYYY-MM-DD") : ""}
                </span>
              </h6>
              <h6 className="font-bold mb-3">
                Date Fin :{" "}
                <span className="text-sm font-medium">
                  {datefin ? moment(datefin).format("YYYY-MM-DD") : ""}
                </span>
              </h6>
              {paiement.status === "PAID_INCOMPLETE" && (
                <h6 className="font-bold mb-3">
                  Montat payé :{" "}
                  <span className="text-sm font-medium">
                    {paiement.resteapayer
                      ? parseFloat(paiement.total.toString()) -
                        parseFloat(paiement.resteapayer.toString()) +
                        " dhs"
                      : ""}
                  </span>
                </h6>
              )}
              <h6 className="font-bold mb-3">
                Status :{" "}
                <span className="text-sm font-medium">
                  {statusPaiement(paiement.status)}
                </span>
              </h6>
            </div>
          </div>
          <div className="p-4">
            <div className="border-b border-gray-200 shadow">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr className="text-left">
                    <th className="px-6 py-2 text-xs text-gray-500 border-2 text-center">
                      #
                    </th>
                    <th className="px-6 py-2 text-xs text-gray-500 border-2 ">
                      Services
                    </th>
                    <th className="px-6 py-2 text-xs text-gray-500 border-2"></th>
                    <th className="px-6 py-2 text-xs text-gray-500 border-2 text-center">
                      Prix
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {paiement.forfaits
                    ? paiement.forfaits.map((value: any) => {
                        return (
                          <tr className="whitespace-nowrap">
                            <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                              1
                            </td>
                            <td className="px-6 py-2 border-2">
                              <div className="text-sm text-gray-900">
                                {value.Forfait?.libelle ?? ""}
                              </div>
                            </td>
                            <td className="px-6 py-2 border-2"></td>
                            <td className="px-6 py-2 text-center border-2">
                              {value.prixforfait}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                  {paiement.sessions
                    ? paiement.sessions.map((value: any) => {
                        return (
                          <tr className="whitespace-nowrap">
                            <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                              1
                            </td>
                            <td className="px-6 py-2 border-2">
                              <div className="text-sm text-gray-900">
                                {value.Forfait?.libelle ?? ""}
                              </div>
                            </td>
                            <td className="px-6 py-2 border-2"></td>
                            <td className="px-6 py-2 text-center border-2">
                              {value.total}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                  {paiement.frais
                    ? paiement.frais.map((value: any) => {
                        return (
                          <tr className="whitespace-nowrap">
                            <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                              1
                            </td>
                            <td className="px-6 py-2 border-2">
                              <div className="text-sm text-gray-900">
                                {value.Frais.libelle}
                              </div>
                            </td>
                            <td className="px-6 py-2 border-2"></td>
                            <td className="px-6 py-2 border-2 text-center">
                              {value.Frais.montant}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                  {paiement.prixremise ? (
                    <tr className="whitespace-nowrap">
                      <td className="px-6 py-2 text-sm text-gray-500 border-2 text-center">
                        1
                      </td>
                      <td className="px-6 py-2 border-2">
                        <div className="text-sm text-gray-900">Remise</div>
                      </td>
                      <td className="px-6 py-2 border-2"></td>
                      <td className="px-6 py-2 border-2 text-center">
                        - {paiement.prixremise}
                      </td>
                    </tr>
                  ) : null}
                  <tr className="text-white bg-gray-800">
                    <th colSpan={2}></th>
                    <td className="text-sm font-bold px-4 py-2 text-center">
                      <b>Total</b>
                    </td>
                    <td className="text-sm font-bold px-4 py-2 text-center">
                      <b>{paiement.total}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="mt-4 flex items-center justify-end gap-x-6">
                  {(paiement.status === "PENDING" ||
                    paiement.status === "PAID") && (
                    <Button
                      variant="contained"
                      className={styles.ButtonError}
                      color="error"
                      onClick={() => saveStatus("CANCELLED")}
                    >
                      Annuler le paiement
                    </Button>
                  )}
                  {(paiement.status === "PENDING" ||
                    paiement.status === "PAID_INCOMPLETE") && (
                    <Button
                      variant="contained"
                      className={styles.ButtonSuccess}
                      color="success"
                      onClick={() => saveStatus("PAID")}
                    >
                      Confirmer le paiement
                    </Button>
                  )}
                  {paiement.status === "PAID" && (
                    <Button
                      variant="contained"
                      className={styles.ButtonValid}
                      onClick={() => setRemboursementPopUp(true)}
                    >
                      Rembourser
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <SkeletonTable />
      )}
      {loadingChange && <SpinnerPage />}
      <Modal
        open={remboursementPopUp}
        onClose={() => setRemboursementPopUp(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
            Rembourser le Paiement{" "}
          </h1>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Montant a Remboursé"
                name="total"
                size="small"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                onChange={formik.handleChange}
                value={formik.values.total}
                fullWidth
                error={!!formik.errors.total}
                helperText={formik.errors.total ? formik.errors.total : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observation"
                name="observation"
                onChange={formik.handleChange}
                value={formik.values.observation}
                fullWidth
                error={
                  !!formik.touched.observation && !!formik.errors.observation
                }
                helperText={
                  formik.touched.observation ? formik.errors.observation : ""
                }
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="mt-4 flex items-center justify-end gap-x-6">
                <Button
                  className={styles.ButtonCancel}
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  disabled={loadingSend}
                  variant="contained"
                  onClick={() => setRemboursementPopUp(false)}
                >
                  Retour
                </Button>
                <Button
                  variant="contained"
                  onClick={() => formik.handleSubmit()}
                  className={styles.ButtonValid}
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  disabled={loadingSend}
                >
                  Ajouter le Remboursement
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
