import React from "react";

interface HeaderProps {
  logout: () => void;
  handleDrawerToggle: () => void;
  user: any;
}

export default function Header({
  user,
  logout,
  handleDrawerToggle,
}: HeaderProps) {
  return (
    <div className="w-100 p-2 flex justify-between justify-items-center mb-10">
      <div className="flex items-center gap-4">
        <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full bg-gray-600">
          <svg
            className="absolute w-12 h-12 text-gray-400 -left-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>

        <div className="font-medium dark:text-white">
          <div>{user?.nom + " " + user?.prenom}</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {user?.role}
          </div>
        </div>
      </div>
      <svg
        className="w-5 h-5 text-gray-800 dark:text-white cursor-pointer hidden md:block"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 15"
        onClick={() => logout()}
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 7.5h11m0 0L8 3.786M12 7.5l-4 3.714M12 1h3c.53 0 1.04.196 1.414.544.375.348.586.82.586 1.313v9.286c0 .492-.21.965-.586 1.313A2.081 2.081 0 0 1 15 14h-3"
        />
      </svg>
      <button
        type="button"
        onClick={handleDrawerToggle}
        className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 hover:bg-gray-700 focus:ring-white"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
    </div>
  );
}
