import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import routes from "./services/routes";
import { NoMatch } from "./pages/RedirectPage/NoMatch";

function App() {
  return (
    <div className="App">
      <Routes>
        {/*<Route path="/" element={<HomePage />} />*/}
        <Route path="/login" element={<Login />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Navigate to="/summary-dashboard" />} />
          {routes &&
            routes.map((route, index) => {
              if (route.element) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element && <route.element />}
                  />
                );
              }
              return (
                <Route key={index} path={route.path}>
                  {route.children &&
                    route.children.length > 0 &&
                    route.children.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={route.element && <route.element />}
                      />
                    ))}
                </Route>
              );
            })}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

export default App;
