import moment from "moment";
import { Chip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

export interface fraisAbonnement {
  id_abonnement: string;
  id_frais: string;
  libelle: string;
  lastdatepaiement: string | null;
  datenextpaiement: string | null;
}
export const checkExpireDate = (frais: fraisAbonnement) => {
  const date = new Date();
  return Math.ceil(
    moment(frais.datenextpaiement).diff(moment(date), "days", true)
  );
};

export const statusFrais = (frais: fraisAbonnement) => {
  if (frais.lastdatepaiement === null) {
    return (
      <Chip icon={<ClearIcon />} label={`${frais.libelle}`} color="error" />
    );
  } else {
    if (frais.datenextpaiement === null) {
      return (
        <Chip
          icon={<CheckIcon />}
          label={`${frais.libelle} `}
          color="success"
        />
      );
    } else {
      if (checkExpireDate(frais) < 5) {
        return (
          <Chip
            icon={<CheckIcon />}
            label={`${frais.libelle} `}
            color="warning"
          />
        );
      } else {
        return (
          <Chip
            icon={<CheckIcon />}
            label={`${frais.libelle} `}
            color="success"
          />
        );
      }
    }
  }
};
