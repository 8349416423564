import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  coachingInterface,
  getAllCoaching,
} from "../../redux/slices/coachingSlice";
import { Meta } from "../../services/interfaces";
import { Link } from "react-router-dom";
import moment from "moment";
import SkeletonTable from "../../components/SkeletonTable";
import { Pagination, ThemeProvider } from "@mui/material";
import { theme } from "../../styles/global";
import SearchBar from "../../components/SearchBar";
import checkBirthday from "../../utils/checkBirthday";

interface coachingPaginationInterface {
  meta: Meta;
  data: coachingInterface[];
}
export default function Coaching() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.coaching.loadingListe);
  const coachings: coachingPaginationInterface = useSelector(
    (state: any) => state.coaching.coachings
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllCoaching(1));
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      <SearchBar
        getData={getAllCoaching}
        page={page}
        setPage={handleChange}
        loading={loading}
        isFirstRender={isFirstRender}
        noButtonAdd
        dateHolder="Fin"
        types={["dateminmax", "coach", "adherent"]}
        titlePage={"Liste Paiements Coaching"}
      />

      {!loading ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Adhérent
                </th>
                <th scope="col" className="px-6 py-3">
                  Coach
                </th>
                <th scope="col" className="px-6 py-3">
                  Date début
                </th>
                <th scope="col" className="px-6 py-3">
                  Date fin
                </th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {coachings?.data?.length > 0 ? (
                coachings.data.map((coaching, key) => {
                  return (
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                      <td className="px-6 py-4 uppercase">
                        <div className="flex items-center">
                          {coaching.Adherent
                            ? coaching.Adherent?.nom +
                              " " +
                              coaching.Adherent?.prenom
                            : "-"}
                          {coaching.Adherent &&
                            checkBirthday(coaching.Adherent?.datenaissance)}
                        </div>
                      </td>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white uppercase"
                      >
                        {coaching.Coach?.nom + " " + coaching.Coach?.prenom}
                      </th>
                      <td className="px-6 py-4">
                        {coaching.datedebut
                          ? moment(coaching.datedebut).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td className="px-6 py-4">
                        {coaching.datefin
                          ? moment(coaching.datefin).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td className="px-6 py-4">
                        {" "}
                        <Link
                          to={`` + coaching.id_paiement_coaching + ``}
                          className="font-medium text-blue-600 hover:underline"
                        >
                          <svg
                            className="w-4 h-4 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div
                      className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                      role="alert"
                    >
                      <span className="font-medium">Aucun Coching Trouvé</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {coachings.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 15,
                marginBottom: 5,
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={coachings.meta.totalPages}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}
