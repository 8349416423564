import { useFormik } from "formik";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  addAdherent,
  adherentInterface,
  editAdherent,
  getOneadherent,
} from "../../redux/slices/adherentSlice";
import { Grid, Button, CircularProgress } from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import InformationForm from "./InformationForm";
import AdditionalInfoForm from "./AdditionalInfoForm";
import PaiementForm from "./PaiementForm";
import PaymentIcon from "@mui/icons-material/Payment";
import FeedIcon from "@mui/icons-material/Feed";
import PersonIcon from "@mui/icons-material/Person";
import { addPaiementAbonnement } from "../../redux/slices/paiementSlice";

export interface MyFormValues {
  nom: string;
  prenom: string;
  email: string;
  genre: string;
  numero: string;
  adresse: string;
  cin: string;
  datenaissance: Date | string | null;
  //coach_id: string;
}
export interface MyFormValues2 {
  adherent_id: string | number;
  observation: string;
  prixremise: number | null;
  frais: any[];
  forfaits: any | null;
  sessions: any | null;
  datedebut: Date | null | string;
  datefin: Date | null | string;
  montantForfait: number | null;
  moisForfait: number | null;
  status: string;
  datepaiement: Date | null | string;
  modereglement: string;
  total: number;
  datenextpaiement?: Date | null | string;
  resteapayer?: number;
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

export default function AddAdherent() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [montantPaye, setMontantPaye] = useState(0);
  const [forfaitSession, setForfaitSession] = useState(
    localStorage.getItem("forfaitSession") ?? "forfait"
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(
    localStorage.getItem("adherent")
      ? localStorage.getItem("paiement")
        ? 2
        : 1
      : 0
  );

  const adherent: adherentInterface = useSelector(
    (state: any) => state.adherent.adherent
  );

  const schema1 = yup.object({
    email: yup.string().required("Email est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
    nom: yup.string().required("Nom est obligatoire"),
    numero: yup.string().required("Téléphone est obligatoire"),
    genre: yup.string().required("Genre est obligatoire"),
    cin: yup.string().required("CIN est obligatoire"),
  });
  const schema2 = yup.object({
    forfaits: yup.object().required("Forfait est obligatoire"),
    modereglement: yup.string().required("Mode de réglement est obligatoire"),
    moisForfait: yup.number().required("Mois Forfait est obligatoire"),
    montantForfait: yup.number().required("Montant Forfait est obligatoire"),
  });

  const steps = ["Information adhérent", "Forfait", "Paiement"];

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <PersonIcon />,
      2: <FeedIcon />,
      3: <PaymentIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const formikPersonalInfo = useFormik<MyFormValues>({
    initialValues: {
      nom: "",
      prenom: "",
      email: "",
      numero: "",
      genre: "H",
      cin: "",
      adresse: "",
      datenaissance: null,
    },
    validationSchema: schema1,
    onSubmit: (values) => {
      CreateAdherent();
    },
  });

  const formikAdditionalInfo = useFormik<MyFormValues2>({
    initialValues: {
      adherent_id: "",
      observation: "",
      prixremise: null,
      frais: [],
      forfaits: null,
      sessions: null,
      datedebut: new Date(),
      datefin: new Date(),
      datepaiement: new Date(),
      modereglement: "espece",
      status: "PAID",
      total: 0,
      montantForfait: 0,
      moisForfait: 0,
      datenextpaiement: new Date(),
      resteapayer: 0,
    },
    validationSchema: schema2,
    onSubmit: (values) => {
      saveInformationPaiement();
    },
  });

  const formikPaiement = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      handleFormSubmit();
    },
  });

  const calculTotal = useMemo(() => {
    var dataPaiement = { ...formikAdditionalInfo.values };
    const totalPriceFrais = dataPaiement.frais.reduce(
      (total: any, obj: any) => parseFloat(total) + parseFloat(obj.montant),
      0
    );
    let total = totalPriceFrais;
    total = total - (dataPaiement.prixremise ?? 0);
    if (forfaitSession === "forfait")
      total = total + (dataPaiement.montantForfait ?? 0);
    if (forfaitSession === "session")
      total = total + (parseFloat(dataPaiement.sessions.total) ?? 0);

    return total;
  }, [formikAdditionalInfo.values]);

  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return (
          <>
            <InformationForm isEdit formik={formikPersonalInfo} />
          </>
        );
      case 1:
        return (
          <>
            <AdditionalInfoForm
              formik={formikAdditionalInfo}
              formikAdherent={formikPersonalInfo}
              forfaitSession={forfaitSession}
              setForfaitSession={setForfaitSession}
              montantPaye={montantPaye}
              setMontantPaye={setMontantPaye}
              calculTotal={calculTotal}
            />
          </>
        );
      case 2:
        return (
          <>
            <PaiementForm
              formik={formikAdditionalInfo}
              formikAdherent={formikPersonalInfo}
              forfaitSession={forfaitSession}
            />
          </>
        );
      default:
        return "Unknown step";
    }
  };

  // Submit Paiement Second Step
  const saveInformationPaiement = async () => {
    if (localStorage.getItem("adherent")) {
      const totalPrice = formikAdditionalInfo.values.frais.reduce(
        (total: any, obj: any) => parseFloat(total) + parseFloat(obj.montant),
        0
      );

      let total = totalPrice;
      let resteapayer = 0;
      total = total - (formikAdditionalInfo.values.prixremise ?? 0);
      if (forfaitSession === "forfait")
        total = total + (formikAdditionalInfo.values.montantForfait ?? 0);
      if (forfaitSession === "session")
        total =
          total + (parseFloat(formikAdditionalInfo.values.sessions.total) ?? 0);

      resteapayer = total - montantPaye;
      formikAdditionalInfo.setValues({
        ...formikAdditionalInfo.values,
        total,
        resteapayer,
      });
      localStorage.setItem(
        "paiement",
        JSON.stringify({ ...formikAdditionalInfo.values, total })
      );
      localStorage.setItem("forfaitSession", forfaitSession);
      handleNext();
    }
  };

  // Submit Validation Paiement Step
  const handleFormSubmit = async () => {
    var dataPaiement = { ...formikAdditionalInfo.values };
    let values: any = {
      adherent_id: dataPaiement.adherent_id,
      observation: dataPaiement.observation,
      prixremise: dataPaiement.prixremise,
      frais: dataPaiement.frais.map((value: any) => ({
        frais_id: value.id_frais,
        prixfrais: value.montant,
        moisfrais: value.mois,
      })),
      forfaits:
        forfaitSession === "forfait"
          ? [
              {
                forfait_id: dataPaiement.forfaits?.id_forfait,
                prixforfait: dataPaiement.montantForfait,
                moisforfait:
                  dataPaiement.moisForfait &&
                  parseInt(dataPaiement.moisForfait.toString()),
                datedebut: dataPaiement.datedebut,
                datefin: dataPaiement.datefin,
              },
            ]
          : null,
      sessions:
        forfaitSession === "session"
          ? [
              {
                forfait_id: dataPaiement.sessions.forfait_id.id_forfait,
                datedebut: dataPaiement.sessions.datedebut,
                datefin: dataPaiement.sessions.datefin,
                nombresessions: dataPaiement.sessions.nombresessions,
                total: dataPaiement.sessions.total,
              },
            ]
          : null,
      datepaiement: dataPaiement.datepaiement,
      modereglement: dataPaiement.modereglement,
      total: dataPaiement.total,
      status: dataPaiement.status,
    };
    if (dataPaiement.status === "PAID_INCOMPLETE") {
      values = {
        ...values,
        datenextpaiement: dataPaiement.datenextpaiement,
        resteapayer: calculTotal - montantPaye,
      };
    }
    try {
      setLoadingSend(true);
      await dispatch(addPaiementAbonnement(values));
    } finally {
      setLoadingSend(false);
      localStorage.removeItem("adherent");
      localStorage.removeItem("paiement");
      localStorage.removeItem("forfaitSession");
      navigate("/adherents");
    }
  };

  // Submit Create Adherent Step
  const CreateAdherent = async () => {
    var data = {
      email: formikPersonalInfo.values.email,
      nom: formikPersonalInfo.values.nom,
      prenom: formikPersonalInfo.values.prenom,
      datenaissance: formikPersonalInfo.values.datenaissance,
      numero: formikPersonalInfo.values.numero,
      genre: formikPersonalInfo.values.genre,
      cin: formikPersonalInfo.values.cin,
      adresse: formikPersonalInfo.values.adresse,
    };
    if (localStorage.getItem("adherent")) {
      const id = localStorage.getItem("adherent");
      if (id !== null) {
        try {
          setLoadingSend(true);
          await dispatch(editAdherent(data, parseInt(id, 10)));
        } finally {
          setLoadingSend(false);
          handleNext();
        }
      }
    } else {
      try {
        setLoadingSend(true);
        const newAdherent = await dispatch(addAdherent(data));
        if (newAdherent?.status) {
          localStorage.setItem("adherent", newAdherent.data.id_adherent);
          formikAdditionalInfo.setFieldValue(
            "adherent_id",
            newAdherent.data.id_adherent
          );
        }
      } finally {
        setLoadingSend(false);
        handleNext();
      }
    }
  };

  // handle all Steps Submit
  const handleSubmit = () => {
    switch (activeStep) {
      case 0:
        formikPersonalInfo.handleSubmit();
        break;
      case 1:
        formikAdditionalInfo.handleSubmit();
        break;
      case 2:
        formikPaiement.handleSubmit();
        break;
      default:
        break;
    }
  };

  // use Effect without Dep
  useEffect(() => {
    if (localStorage.getItem("adherent")) {
      const id = localStorage.getItem("adherent");
      if (id !== null) {
        dispatch(getOneadherent(id));

        if (localStorage.getItem("paiement")) {
          const data = localStorage.getItem("paiement");
          if (data !== null) {
            const dataPaiement = JSON.parse(data);
            formikAdditionalInfo.setValues({ ...dataPaiement });
          }
        } else {
          formikAdditionalInfo.setFieldValue("adherent_id", id);
        }
      }
    }
    return () => {
      localStorage.removeItem("adherent");
      localStorage.removeItem("paiement");
      localStorage.removeItem("forfaitSession");
    };
  }, []);

  // use Effect with adherent Dep
  useEffect(() => {
    if (adherent && localStorage.getItem("adherent")) {
      formikPersonalInfo.setValues({
        nom: adherent.nom,
        prenom: adherent.prenom,
        email: adherent.email,
        numero: adherent.numero,
        genre: adherent.genre,
        datenaissance: adherent.datenaissance,
        cin: adherent.cin,
        adresse: adherent.adresse,
      });
    }
  }, [adherent]);

  return (
    <div>
      <Link className="w-auto inline-block" to="/adherent">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des adhérents
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Ajouter un adhérent{" "}
      </h1>
      <form noValidate autoComplete="off">
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <>{getStepContent(activeStep)}</>

          <React.Fragment>
            <Grid item xs={12}>
              <div className="mt-4 border-t-2 border-gray-900/18">
                <div className="mt-4 flex items-center justify-end gap-x-6">
                  <Button
                    className={styles.ButtonCancel}
                    startIcon={loadingSend && <CircularProgress size={20} />}
                    disabled={loadingSend || activeStep === 0}
                    variant="contained"
                    onClick={handleBack}
                  >
                    Retour
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      className={styles.ButtonValid}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                    >
                      Valider le paiement
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      className={styles.ButtonValid}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                    >
                      Suivant
                    </Button>
                  )}
                </div>
              </div>
            </Grid>
          </React.Fragment>
        </div>
      </form>
    </div>
  );
}
