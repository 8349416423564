import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { setToast } from "./ToastSlice";

export interface forfaitInterface {
  id_forfait: number;
  montantannee: number;
  montantsixmois: number;
  montanttroismois: number;
  montantunmois: number;
  libelle: string;
  //disciplines: any;
  frais: any;
  Forfait: forfaitInterface[];
  datedebut?: Date;
  datefin?: Date;
  createdon?: Date;
  createdby?: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewforfaitInterface {
  libelle: string;
  montantannee: number | null;
  montantsixmois: number | null;
  montanttroismois: number | null;
  montantunmois: number | null;
  //disciplines: Discipline[];
  frais: Frais[];
}
/* interface Discipline {
  discipline_id: string;
} */
interface Frais {
  frais_id: string;
}

const initialState = {
  forfaits: [],
  allForfaits: [],
  forfait: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const forfaitSlice = createSlice({
  name: "forfait",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allForfait(state, action) {
      state.forfaits = action.payload;
      state.forfait = {};
      state.loadingListe = false;
      state.errorListe = "";
    },
    allForfaitTrue(state, action) {
      state.allForfaits = action.payload;
      state.error = "";
    },
    oneForfait(state, action) {
      state.forfait = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allForfait,
  allForfaitTrue,
  oneForfait,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = forfaitSlice.actions;

export const getAllForfait =
  (page?: number, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.forfaitApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}`
      );
      let forfaits: any;
      forfaits = page ? response.data : response.data.data;
      page
        ? dispatch(allForfait(forfaits))
        : dispatch(allForfaitTrue(forfaits));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneForfait = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.forfaitApi + "/" + id);
    let forfait = response.data;
    dispatch(oneForfait(forfait));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export const addForfait =
  (forfait: NewforfaitInterface) => async (dispatch: any) => {
    try {
      await axios.post(api.baseApi + api.forfaitApi, forfait);
      dispatch(
        setToast({
          message: "Forfait est ajouté avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const editForfait =
  (forfait: forfaitInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.forfaitApi + "/" + id, forfait);
      dispatch(
        setToast({
          message: "Forfait est modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export default forfaitSlice.reducer;
