import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { adherentInterface } from "./adherentSlice";
import { setToast } from "./ToastSlice";
import { employeInterface } from "./employeSlice";

export interface coachingInterface {
  id_paiement_coaching: any;
  adherent_id: string;
  Adherent: adherentInterface;
  coach_id: string;
  Coach: employeInterface;
  prixcoaching: number;
  moiscoaching: number;
  datedebut: Date;
  datefin: Date;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewcoachingInterface {
  adherent_id: string | number;
  coach_id: string | number;
  prixcoaching: number;
  moiscoaching: number;
  datedebut: Date | null | string;
  datefin: Date | null | string;
}

const initialState = {
  coachings: [],
  allCoachings: [],
  coaching: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const coachingSlice = createSlice({
  name: "coaching",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allCoaching(state, action) {
      state.coachings = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
      state.coaching = {};
    },
    allCoachingTrue(state, action) {
      state.allCoachings = action.payload;
      state.error = "";
    },
    oneCoaching(state, action) {
      state.coaching = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allCoaching,
  allCoachingTrue,
  oneCoaching,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = coachingSlice.actions;

export const getAllCoaching =
  (
    page?: number,
    adherent_id?: string,
    coach_id?: string,
    datefinmax?: Date | string,
    datefinmin?: Date | string,
    createdonmax?: Date | string,
    createdonmin?: Date | string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.coachingApi}${
          page ? "/?page=" + page : "/?all=true"
        }${adherent_id ? "&adherent_id=" + adherent_id : ""}${
          coach_id ? "&coach_id=" + coach_id : ""
        }${
          datefinmax && datefinmax !== "Invalid date"
            ? "&datefinmax=" + datefinmax
            : ""
        }${
          datefinmin && datefinmin !== "Invalid date"
            ? "&datefinmin=" + datefinmin
            : ""
        }${
          createdonmax && createdonmax !== "Invalid date"
            ? "&createdonmax=" + createdonmax
            : ""
        }${
          createdonmin && createdonmin !== "Invalid date"
            ? "&createdonmin=" + createdonmin
            : ""
        }`
      );
      let coachings: any;
      coachings = page ? response.data : response.data.data;
      page
        ? dispatch(allCoaching(coachings))
        : dispatch(allCoachingTrue(coachings));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneCoaching = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.coachingApi + "/" + id);
    let coaching = response.data;
    dispatch(oneCoaching(coaching));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addCoaching =
  (coaching: NewcoachingInterface) => async (dispatch: any) => {
    try {
      const newCoaching = await axios.post(
        api.baseApi + api.coachingApi,
        coaching
      );
      return newCoaching;
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const editCoaching =
  (coaching: coachingInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.coachingApi + "/" + id, coaching);
      dispatch(
        setToast({
          message: "Coaching est modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export default coachingSlice.reducer;
