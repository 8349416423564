import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  virementInterface,
  editVirement,
  getOneVirement,
} from "../../redux/slices/virementSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SpinnerBlock from "../../components/SpinnerBlock";
import {
  employeInterface,
  getAllEmploye,
} from "../../redux/slices/employeSlice";

export default function EditVirement() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const virement: virementInterface = useSelector(
    (state: any) => state.virement.virement
  );
  const loading = useSelector((state: any) => state.virement.loadingOne);

  const employes: employeInterface[] = useSelector(
    (state: any) => state.employe.allEmployes
  );

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneVirement(id));
      dispatch(getAllEmploye());
    } else {
      navigate("/virement");
    }

    return () => {
      setInitialValues({});
    };
  }, []);

  useEffect(() => {
    if (Object.keys(virement).length > 0) {
      const values = {
        id_virement_salaire: virement.id_virement_salaire,
        montant: virement.montant,
        datevirement: virement.datevirement,
        moisapayer: virement.moisapayer,
        observation: virement.observation,
        modereglement: virement.modereglement,
        employe_id: virement.Employe,
      };
      setInitialValues(values);
    }
  }, [virement]);

  const schema = yup.object({
    employe_id: yup.object().required("Employé est obligatoire"),
    montant: yup.number().required("Montant est obligatoire"),
    modereglement: yup.string().required("Mode de Réglement est obligatoire"),
    datevirement: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
  });

  return (
    <div>
      <Link className="w-auto inline-block" to="/virement">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des Virements Salaires
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Modifier un Virement Salaire{" "}
      </h1>
      {!loading && Object.keys(initialValues).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={async (values) => {
            let object = {
              ...values,
              employe_id: values.employe_id.id_employe,
            };
            delete object.updatedby;
            delete object.updatedon;
            try {
              setLoadingSend(true);
              await dispatch(editVirement(object, values.id_virement_salaire));
            } finally {
              setLoadingSend(false);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="employe_id"
                    size="small"
                    options={employes}
                    getOptionLabel={(option) => {
                      return (option.nom + " " + option.prenom).toUpperCase();
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setFieldValue("employe_id", value);
                        setFieldValue("montant", value?.salaire);
                      } else {
                        setFieldValue("montant", 0);
                      }
                    }}
                    value={values.employe_id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employé"
                        error={touched.employe_id && Boolean(errors.employe_id)}
                        helperText={
                          touched.employe_id &&
                          (typeof errors.employe_id === "string" ||
                            errors.employe_id instanceof String)
                            ? errors.employe_id
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Montant"
                    name="montant"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.montant}
                    value={values.montant}
                    helperText={
                      touched.montant &&
                      (typeof errors.montant === "string" ||
                        errors.montant instanceof String)
                        ? errors.montant
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(value) => {
                        const date = moment(value).format("YYYY-MM-DD");
                        setFieldValue("datevirement", date);
                      }}
                      value={moment(values.datevirement)}
                      label="Date de virement"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.datevirement,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      views={["month", "year"]}
                      format="MM/YYYY"
                      onChange={(value) => {
                        const date = moment(value)
                          .startOf("month")
                          .format("YYYY-MM-DD");
                        setFieldValue("moisapayer", date);
                      }}
                      value={moment(values.moisapayer)}
                      label="Mois à payer"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.moisapayer,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    error={touched.modereglement && !!errors.modereglement}
                    size="small"
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-label">
                      Mode de réglement
                    </InputLabel>
                    <Select
                      name="modereglement"
                      labelId="demo-simple-select-label"
                      label="Mode de réglement"
                      onChange={handleChange}
                      value={values.modereglement}
                    >
                      <MenuItem value={"virement"}>Virement</MenuItem>
                      <MenuItem value={"cheque"}>Chèque</MenuItem>
                      <MenuItem value={"espece"}>Espèce</MenuItem>
                    </Select>
                    {touched.modereglement && errors.modereglement && (
                      <FormHelperText>
                        {(typeof errors.modereglement === "string" ||
                          errors.modereglement instanceof String) &&
                          errors.modereglement}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Observation"
                    name="observation"
                    onChange={handleChange}
                    value={values.observation}
                    fullWidth
                    error={!!errors.observation}
                    helperText={
                      touched.observation &&
                      (typeof errors.observation === "string" ||
                        errors.observation instanceof String)
                        ? errors.observation
                        : ""
                    }
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="border-t-2 border-gray-900/18">
                    <div className="mt-4 flex items-center justify-end gap-x-6">
                      <Link to="/virement">
                        <Button
                          className={styles.ButtonCancel}
                          startIcon={
                            loadingSend && <CircularProgress size={20} />
                          }
                          disabled={loadingSend}
                          variant="contained"
                        >
                          Annuler
                        </Button>
                      </Link>
                      <Button
                        className={styles.ButtonValid}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
