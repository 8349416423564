import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  fraisInterface,
  editFrais,
  getOneFrais,
} from "../../redux/slices/fraisSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SpinnerBlock from "../../components/SpinnerBlock";

const MySwal = withReactContent(Swal);

export default function EditFrais() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [checked, setChecked] = useState(false);
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const frais: fraisInterface = useSelector((state: any) => state.frais.frais);
  const loading = useSelector((state: any) => state.frais.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneFrais(id));
    } else {
      navigate("/frais");
    }

    return () => {
      setChecked(false);
    };
  }, []);

  useEffect(() => {
    if (frais) {
      if (frais.mois === -1) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [frais]);

  const schema = yup.object({
    libelle: yup.string().required("Libélle est obligatoire"),
    montant: yup.string().required("Montant est obligatoire"),
  });

  return (
    <div>
      <Link className="w-auto inline-block" to="/frais">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des frais
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Modifier un frais{" "}
      </h1>

      {!loading && Object.keys(frais).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={frais}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            setLoadingSend(true);
            const newFrais = await dispatch(editFrais(object, values.id_frais));
            if (newFrais) {
              MySwal.fire({
                icon: "success",
                title: "Frais est modifiée avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then((result) => {
                setLoadingSend(false);
                navigate("/Frais");
              });
            }
            setLoadingSend(false);
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Libelle"
                    name="libelle"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    error={!!errors.libelle}
                    value={values.libelle}
                    helperText={errors.libelle ? errors.libelle : ""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Montant"
                    name="montant"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.montant}
                    value={values.montant}
                    helperText={errors.montant ? errors.montant : ""}
                  />
                </Grid>
                <Grid container item xs={12} md={6}>
                  <Grid item xs={8}>
                    <TextField
                      label="Mois"
                      name="mois"
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={handleChange}
                      fullWidth
                      disabled={checked}
                      error={!!errors.mois}
                      value={values.mois}
                      helperText={errors.mois ? errors.mois : ""}
                    />
                  </Grid>
                  <Grid className="!ml-3" item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checked}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.checked) {
                              setFieldValue("mois", -1);
                            } else {
                              setFieldValue("mois", 0);
                            }
                            setChecked(event.target.checked);
                          }}
                        />
                      }
                      label="Indéfini"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className="border-t-2 border-gray-900/18">
                    <div className="mt-4 flex items-center justify-end gap-x-6">
                      <Link to="/frais">
                        <Button
                          className={styles.ButtonCancel}
                          startIcon={
                            loadingSend && <CircularProgress size={20} />
                          }
                          disabled={loadingSend}
                          variant="contained"
                        >
                          Annuler
                        </Button>
                      </Link>
                      <Button
                        className={styles.ButtonValid}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
