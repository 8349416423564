import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  deleteDepense,
  depenseInterface,
  editDepense,
  getOneDepense,
} from "../../redux/slices/depenseSlice";
import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SpinnerBlock from "../../components/SpinnerBlock";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export default function EditDepense() {
  const [loadingSend, setLoadingSend] = useState(false);
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const depense: depenseInterface = useSelector(
    (state: any) => state.depense.depense
  );
  const loading = useSelector((state: any) => state.depense.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneDepense(id));
    } else {
      navigate("/depense");
    }
  }, []);

  const removeDepense = () => {
    MySwal.fire({
      icon: "warning",
      title: "Voulez vous supprimer cette dépense: " + depense.libelle,
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui",
      customClass: {
        confirmButton: "shadow-none",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(deleteDepense(depense.id_depense));
        } finally {
          navigate("/depense");
        }
      }
    });
  };

  const schema = yup.object({
    libelle: yup.string().required("Libélle est obligatoire"),
    montant: yup.string().required("Montant est obligatoire"),
    datedepense: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
  });

  return (
    <div>
      <Link className="w-auto inline-block" to="/depense">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des dépenses
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Modifier une dépense{" "}
      </h1>
      {!loading && Object.keys(depense).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={depense}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            try {
              setLoadingSend(true);
              await dispatch(editDepense(object, values.id_depense));
            } finally {
              setLoadingSend(false);
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Libelle"
                    name="libelle"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    error={!!errors.libelle}
                    value={values.libelle}
                    helperText={errors.libelle ? errors.libelle : ""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Montant"
                    name="montant"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.montant}
                    value={values.montant}
                    helperText={errors.montant ? errors.montant : ""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(value) => {
                        const date = moment(value).format("YYYY-MM-DD");
                        setFieldValue("datedepense", date);
                      }}
                      value={moment(values.datedepense)}
                      label="Date de naissance"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.datedepense,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p className="text-gray-700">Créé par {depense.createdby}</p>
                </Grid>
                <Grid item xs={12}>
                  <div className="border-t-2 border-gray-900/18">
                    <div className="mt-4 flex items-center justify-end gap-x-6">
                      <Button
                        className={styles.ButtonError}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        color="error"
                        onClick={() => removeDepense()}
                      >
                        Supprimer
                      </Button>
                      <Link to="/depense">
                        <Button
                          className={styles.ButtonCancel}
                          startIcon={
                            loadingSend && <CircularProgress size={20} />
                          }
                          disabled={loadingSend}
                          variant="contained"
                        >
                          Annuler
                        </Button>
                      </Link>
                      <Button
                        className={styles.ButtonValid}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
