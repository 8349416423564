import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

export default function InformationForm(props: any) {
  const { formik, isEdit } = props;
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isEdit) {
      if (isFirstRender) {
        setIsFirstRender(false);
      }
    }
  }, []);

  return (
    <>
      <h2 className="mb-2 mt-4 text-xl font-extrabold leading-none tracking-tight text-gray-800 md:text-xl lg:text-xl">
        Information adhérent
      </h2>
      <Grid container spacing={2}>
        {formik.values.reference && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                label="Référence"
                name="reference"
                onChange={formik.handleChange}
                value={formik.values.reference}
                fullWidth
                size="small"
                disabled
                error={!!formik.touched.reference && !!formik.errors.reference}
                helperText={
                  formik.touched.reference ? formik.errors.reference : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            label="Prénom"
            name="prenom"
            onChange={formik.handleChange}
            value={formik.values.prenom}
            fullWidth
            size="small"
            error={!!formik.touched.prenom && !!formik.errors.prenom}
            helperText={formik.touched.prenom ? formik.errors.prenom : ""}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Nom"
            name="nom"
            onChange={formik.handleChange}
            value={formik.values.nom}
            fullWidth
            size="small"
            error={!!formik.touched.nom && !!formik.errors.nom}
            helperText={formik.touched.nom ? formik.errors.nom : ""}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(value) => {
                const date = moment(value).format("YYYY-MM-DD");
                formik.setFieldValue("datenaissance", date);
              }}
              value={moment(formik.values.datenaissance)}
              label="Date de naissance"
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!formik.errors.datenaissance,
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
          {formik.errors.datenaissance && (
            <span className={styles.errorHandle}>
              {formik.errors.datenaissance}
            </span>
          )}
        </Grid>
        <Grid className="!pt-3" item xs={12} md={6}>
          <FormControl fullWidth>
            <FormLabel className="!text-sm !leading-3" id="genre">
              Genre
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="genre"
              name="genre"
              onChange={formik.handleChange}
              value={formik.values.genre}
            >
              <FormControlLabel
                value={"H"}
                control={<Radio size="small" />}
                checked={formik.values.genre === "H"}
                label="H"
              />
              <FormControlLabel
                value={"F"}
                control={<Radio size="small" />}
                checked={formik.values.genre === "F"}
                label="F"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Téléphone"
            name="numero"
            onChange={formik.handleChange}
            value={formik.values.numero}
            fullWidth
            size="small"
            error={!!formik.touched.numero && !!formik.errors.numero}
            helperText={formik.touched.numero ? formik.errors.numero : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            fullWidth
            size="small"
            error={!!formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email ? formik.errors.email : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="CIN"
            name="cin"
            onChange={formik.handleChange}
            value={formik.values.cin}
            fullWidth
            size="small"
            error={!!formik.touched.cin && !!formik.errors.cin}
            helperText={formik.touched.cin ? formik.errors.cin : ""}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Adresse"
            name="adresse"
            onChange={formik.handleChange}
            value={formik.values.adresse}
            fullWidth
            size="small"
            error={!!formik.touched.adresse && !!formik.errors.adresse}
            helperText={formik.touched.adresse ? formik.errors.adresse : ""}
          />
        </Grid>
      </Grid>
    </>
  );
}
