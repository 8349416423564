import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { AppDispatch } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  forfaitInterface,
  getAllForfait,
} from "../../redux/slices/forfaitSlice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import styles from "../../styles/Global.module.scss";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AdditionalInfoForm(props: any) {
  const { formik, formikAdherent } = props;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [showOther, setShowOther] = useState(false);
  const [frais, setFrais] = useState([]);
  const dispatch = useDispatch<AppDispatch>();
  const forfaits: forfaitInterface[] = useSelector(
    (state: any) => state.forfait.allForfaits
  );

  const dict_montant: Record<string, string> = {
    1: "montantunmois",
    3: "montanttroismois",
    6: "montantsixmois",
    12: "montantannee",
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllForfait());
    formik.setFieldValue("sessions", {
      ...formik.values.sessions,
      datedebut: new Date(),
      datefin: new Date(),
      nombresessions: 0,
      total: 0,
    });
  }, []);

  const handleFraisChange = (event: any, values: any) => {
    formik.setFieldValue(
      "frais",
      values.map((value: any) => ({ ...value }))
    );
  };

  const handleMoisForfaitChange = (event: any, typing: boolean) => {
    if (event.target.value === "other") {
      formik.setFieldValue("moisForfait", 0);
      formik.setFieldValue("montantForfait", 0);
      setShowOther(true);
    } else {
      if (!typing) {
        setShowOther(false);
      }
      formik.setFieldValue("moisForfait", parseInt(event.target.value));
      const montantKey = dict_montant[parseInt(event.target.value)];
      if (montantKey) {
        const forfaits: any = formik.values.forfaits && formik.values.forfaits;
        formik.setFieldValue("montantForfait", forfaits[montantKey]);
      } else {
        formik.setFieldValue("montantForfait", 0);
      }
      if (formik.values.datedebut) {
        const originalDate = moment(formik.values.datedebut);
        const newDate = originalDate.add(event.target.value, "months");
        const formattedDate = newDate;
        formik.setFieldValue("datefin", formattedDate);
      }
    }
  };

  return (
    <>
      <h2 className="mb-2 mt-4 text-xl font-extrabold leading-none tracking-tight text-gray-800 md:text-xl lg:text-xl">
        Forfait (
        <span className="uppercase">
          {formikAdherent.values.nom + " " + formikAdherent.values.prenom})
        </span>
      </h2>

      <div>
        <div className="shadow-lg border-2 rounded-md p-4 mb-4">
          <h2 className="text-base text-xl font-semibold text-gray-900">
            Forfait / Sessions
          </h2>
          <div className="mt-4">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue="forfait"
                    onChange={(e) => {
                      props.setForfaitSession(e.target.value);
                      formik.setFieldValue("frais", []);
                    }}
                  >
                    <FormControlLabel
                      value={"forfait"}
                      control={<Radio />}
                      label="Forfaits"
                    />
                    <FormControlLabel
                      value={"session"}
                      control={<Radio />}
                      label="Sessions"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              {props.forfaitSession === "session" && (
                <>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="sessions"
                      size="small"
                      options={forfaits}
                      getOptionLabel={(option) => {
                        return option.libelle;
                      }}
                      onChange={(event, value) => {
                        const listeFrais = value?.frais.map((val: any) => ({
                          ...val.Frais,
                        }));
                        if (listeFrais) {
                          setFrais(listeFrais);
                        }
                        formik.setFieldValue("forfaits", value);
                        formik.setFieldValue("sessions", {
                          ...formik.values.sessions,
                          forfait_id: value,
                        });
                      }}
                      value={formik.values.sessions?.forfait_id}
                      renderInput={(params) => (
                        <TextField {...params} label="Forfait" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Nombre de Sessions"
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(event) => {
                        formik.setFieldValue("sessions", {
                          ...formik.values.sessions,
                          nombresessions: event.target.value,
                        });
                      }}
                      value={formik.values.sessions?.nombresessions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Montant Total"
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(event) => {
                        formik.setFieldValue("sessions", {
                          ...formik.values.sessions,
                          total: event.target.value,
                        });
                      }}
                      value={formik.values.sessions?.total}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(value) => {
                          const date = moment(value).format("YYYY-MM-DD");

                          formik.setFieldValue("sessions", {
                            ...formik.values.sessions,
                            datedebut: date,
                          });
                        }}
                        value={
                          formik.values.sessions?.datedebut
                            ? moment(formik.values.sessions.datedebut)
                            : null
                        }
                        label="Date Debut"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(value) => {
                          const date = moment(value).format("YYYY-MM-DD");

                          formik.setFieldValue("sessions", {
                            ...formik.values.sessions,
                            datefin: date,
                          });
                        }}
                        value={
                          formik.values.sessions?.datefin
                            ? moment(formik.values.sessions.datefin)
                            : null
                        }
                        label="Date Fin"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              )}
              {props.forfaitSession === "forfait" && (
                <>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="forfaits"
                      size="small"
                      options={forfaits}
                      getOptionLabel={(option) => {
                        return option.libelle;
                      }}
                      onChange={(event, value) => {
                        const listeFrais =
                          value?.frais?.map((val: any) => ({
                            ...val.Frais,
                          })) ?? [];
                        if (listeFrais) {
                          setFrais(listeFrais);
                        }
                        formik.setFieldValue("forfaits", value);
                        formik.setFieldValue("moisForfait", 0);
                        formik.setFieldValue("montantForfait", 0);
                        formik.setFieldValue(
                          "datefin",
                          formik.values.datedebut
                        );
                      }}
                      value={formik.values.forfaits}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Forfait"
                          error={
                            formik.touched.forfaits &&
                            Boolean(formik.errors.forfaits)
                          }
                          helperText={
                            formik.touched.forfaits && formik.errors.forfaits
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={12} md={10}>
                    <FormControl
                      error={
                        formik.touched.moisForfait &&
                        !!formik.errors.moisForfait
                      }
                    >
                      <FormLabel className="!text-sm" id="moisForfait">
                        Mois Forfait
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="moisForfait"
                        defaultValue="female"
                        name="moisForfait"
                        onChange={(e) => handleMoisForfaitChange(e, false)}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="1 mois"
                          checked={
                            formik.values.moisForfait === 1 && !showOther
                          }
                          disabled={formik.values.forfaits === null}
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio />}
                          label="3 mois"
                          checked={
                            formik.values.moisForfait === 3 && !showOther
                          }
                          disabled={formik.values.forfaits === null}
                        />
                        <FormControlLabel
                          value={6}
                          control={<Radio />}
                          label="6 mois"
                          checked={
                            formik.values.moisForfait === 6 && !showOther
                          }
                          disabled={formik.values.forfaits === null}
                        />
                        <FormControlLabel
                          value={12}
                          control={<Radio />}
                          label="12 mois"
                          checked={
                            formik.values.moisForfait === 12 && !showOther
                          }
                          disabled={formik.values.forfaits === null}
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Other"
                          checked={showOther}
                          disabled={formik.values.forfaits === null}
                        />
                        {showOther && (
                          <TextField
                            name="moisForfait"
                            size="small"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            sx={{
                              width: "100px",
                            }}
                            onChange={(e) => handleMoisForfaitChange(e, true)}
                            value={formik.values.moisForfait}
                            fullWidth
                            disabled={formik.values.forfaits === null}
                          />
                        )}
                      </RadioGroup>
                      {formik.touched.moisForfait &&
                        formik.errors.moisForfait && (
                          <FormHelperText>
                            {formik.errors.moisForfait}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Montant Forfait"
                      name="montantForfait"
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={formik.handleChange}
                      value={formik.values.montantForfait}
                      fullWidth
                      disabled={formik.values.forfaits === null}
                      error={
                        formik.touched.montantForfait &&
                        !!formik.errors.montantForfait
                      }
                      helperText={
                        formik.touched.montantForfait
                          ? formik.errors.montantForfait
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(value) => {
                          const date = moment(value).format("YYYY-MM-DD");
                          formik.setFieldValue("datedebut", date);
                          if (formik.values.moisForfait) {
                            const originalDate = moment(value);
                            const newDate = originalDate.add(
                              formik.values.moisForfait,
                              "months"
                            );
                            const formattedDate = newDate.format("YYYY-MM-DD");
                            formik.setFieldValue("datefin", formattedDate);
                          }
                        }}
                        value={moment(formik.values.datedebut)}
                        label="Date Debut"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!formik.errors.datedebut,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {formik.errors.datedebut && (
                      <span className={styles.errorHandle}>
                        {formik.errors.datedebut}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        disabled
                        value={moment(formik.values.datefin)}
                        label="Date Fin"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </div>

        {props.forfaitSession === "forfait" && (
          <div className="shadow-lg border-2 rounded-md p-4 mb-4">
            <h2 className="text-base text-xl font-semibold text-gray-900">
              Frais
            </h2>
            <div className="mt-4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    id="frais"
                    size="small"
                    options={frais}
                    disableCloseOnSelect
                    getOptionLabel={(option) => {
                      return (
                        option.libelle +
                        " (" +
                        option.montant +
                        "dhs" +
                        (option.mois === -1
                          ? ")"
                          : " / " + option.mois + "mois)")
                      );
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id_frais === value.id_frais
                    }
                    onChange={handleFraisChange}
                    value={formik.values.frais}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.libelle +
                            " (" +
                            option.montant +
                            "dhs" +
                            (option.mois === -1
                              ? ")"
                              : " / " + option.mois + "mois)")}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Frais"
                        disabled={
                          formik.values.forfaits === null ||
                          formik.values.sessions?.forfait_id === null
                        }
                        error={
                          formik.touched.frais && Boolean(formik.errors.frais)
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        )}
        <div className="shadow-lg border-2 rounded-md p-4 mb-4">
          <h2 className="text-base text-xl font-semibold text-gray-900">
            Information Paiement
          </h2>

          <div className="mt-4">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      formik.setFieldValue("datepaiement", date);
                    }}
                    value={moment(formik.values.datepaiement)}
                    label="Date de Paiement"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!formik.errors.datepaiement,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
                {formik.errors.datepaiement && (
                  <span className={styles.errorHandle}>
                    {formik.errors.datepaiement}
                  </span>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Remise"
                  name="prixremise"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={formik.handleChange}
                  value={formik.values.prixremise}
                  fullWidth
                  error={!!formik.errors.prixremise}
                  helperText={
                    formik.errors.prixremise ? formik.errors.prixremise : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    name="status"
                    labelId="demo-simple-select-label"
                    label="Status"
                    onChange={formik.handleChange}
                    value={formik.values.status}
                  >
                    <MenuItem value={"PAID"}>Payé</MenuItem>
                    <MenuItem value={"PAID_INCOMPLETE"}>
                      Payé avec Facilité
                    </MenuItem>
                    <MenuItem value={"PENDING"}>En Attente</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  error={
                    formik.touched.modereglement &&
                    !!formik.errors.modereglement
                  }
                  size="small"
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-label">
                    Mode de réglement
                  </InputLabel>
                  <Select
                    name="modereglement"
                    labelId="demo-simple-select-label"
                    label="Mode de réglement"
                    onChange={formik.handleChange}
                    value={formik.values.modereglement}
                  >
                    <MenuItem value={"virement"}>Virement</MenuItem>
                    <MenuItem value={"cheque"}>Chèque</MenuItem>
                    <MenuItem value={"espece"}>Espèce</MenuItem>
                  </Select>
                  {formik.touched.modereglement &&
                    formik.errors.modereglement && (
                      <FormHelperText>
                        {formik.errors.modereglement}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              {formik.values.status === "PAID_INCOMPLETE" && (
                <>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(value) => {
                          const date = moment(value).format("YYYY-MM-DD");
                          formik.setFieldValue("datenextpaiement", date);
                        }}
                        value={moment(formik.values.datenextpaiement)}
                        label="Prochain paiement"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!formik.errors.datenextpaiement,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Payé maintenant"
                      name="prixremise"
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) =>
                        props.setMontantPaye(parseFloat(e.target.value))
                      }
                      value={props.montantPaye}
                      fullWidth
                      error={!!formik.errors.prixremise}
                      helperText={
                        formik.errors.prixremise ? formik.errors.prixremise : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Reste à payé"
                      name="resteapayer"
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      value={props.calculTotal - props.montantPaye ?? 0}
                      fullWidth
                      disabled
                      error={!!formik.errors.resteapayer}
                      helperText={
                        formik.errors.resteapayer
                          ? formik.errors.resteapayer
                          : ""
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Observation"
                  name="observation"
                  onChange={formik.handleChange}
                  value={formik.values.observation}
                  fullWidth
                  error={
                    !!formik.touched.observation && !!formik.errors.observation
                  }
                  helperText={
                    formik.touched.observation ? formik.errors.observation : ""
                  }
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
