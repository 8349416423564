import { Chip } from "@mui/material";

export const dict_status: Record<string, string> = {
  PAID: "Payé",
  PENDING: "En attente",
  CANCELLED: "Annulé",
  REFUNDED: "Remboursé",
  PAID_REFUNDED: "Payé ( remoboursé )",
  PAID_INCOMPLETE: "Payé avec Facilité",
};

export const dict_mode_reglement: Record<string, string> = {
  espece: "Espèce",
  cheque: "Chèque",
  virement: "Virement",
};

export const statusPaiement = (status: string) => {
  const statusLabel = dict_status[status];
  switch (status) {
    case "PAID":
      return <Chip variant="outlined" label={statusLabel} color="success" />;
    case "PENDING":
      return <Chip variant="outlined" label={statusLabel} color="info" />;
    case "CANCELLED":
      return <Chip variant="outlined" label={statusLabel} color="error" />;
    case "REFUNDED":
      return <Chip variant="outlined" label={statusLabel} color="warning" />;
    case "PAID_REFUNDED":
      return <Chip variant="outlined" label={statusLabel} color="warning" />;
    case "PAID_INCOMPLETE":
      return <Chip variant="outlined" label={statusLabel} color="warning" />;
  }
};

export const chartColors = [
  "rgba(31,119,180,0.8)",
  "rgba(255,127,14,0.8)",
  "rgba(44,160,44,0.8)",
  "rgba(214,39,40,0.8)",
  "rgba(148,103,189,0.8)",
  "rgba(140,86,75,0.8)",
  "rgba(227,119,194,0.8)",
  "rgba(127,127,127,0.8)",
  "rgba(188,189,34,0.8)",
  "rgba(23,190,207,0.8)",
  "rgba(174,199,232,0.8)",
  "rgba(255,187,120,0.8)",
  "rgba(152,223,138,0.8)",
  "rgba(255,152,150,0.8)",
  "rgba(197,176,213,0.8)",
  "rgba(196,156,148,0.8)",
  "rgba(247,182,210,0.8)",
  "rgba(199,199,199,0.8)",
  "rgba(219,219,141,0.8)",
  "rgba(158,218,229,0.8)",
];
