import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { forfaitInterface } from "./forfaitSlice";
import { adherentInterface } from "./adherentSlice";
import { setToast } from "./ToastSlice";

export interface abonnementInterface {
  id_abonnement: any;
  forfait_id: string;
  adherent_id: string;
  Forfait: forfaitInterface;
  Adherent: adherentInterface;
  expiresat: string;
  lastpaiement: string;
  observation: string;
  status: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewabonnementInterface {
  adherent_id: string;
  forfait_id: string;
  expiresat: string;
  lastpaiement: string;
  observation: string;
  status: string;
}

const initialState = {
  abonnements: [],
  allAbonnements: [],
  abonnement: {},
  fraisAbonnement: [],
  error: "",
  loading: true,
  loadingFraisAbonnement: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const abonnementSlice = createSlice({
  name: "abonnement",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allFraisAbonnement(state, action) {
      state.fraisAbonnement = action.payload;
      state.loadingFraisAbonnement = false;
    },
    allAbonnement(state, action) {
      state.abonnements = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allAbonnementTrue(state, action) {
      state.allAbonnements = action.payload;
      state.error = "";
    },
    oneAbonnement(state, action) {
      state.abonnement = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allAbonnement,
  allAbonnementTrue,
  oneAbonnement,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
  allFraisAbonnement,
} = abonnementSlice.actions;

export const getAllAbonnement =
  (
    page?: number,
    forfait_id?: string,
    adherent_id?: string,
    status?: string,
    expiresatmax?: Date | string,
    expiresatmin?: Date | string,
    createdonmax?: Date | string,
    createdonmin?: Date | string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.abonnementApi}${
          page ? "/?page=" + page : "/?all=true"
        }${adherent_id ? "&adherent_id=" + adherent_id : ""}${
          forfait_id ? "&forfait_id=" + forfait_id : ""
        }${status ? "&status=" + status : ""}${
          expiresatmax && expiresatmax !== "Invalid date"
            ? "&expiresatmax=" + expiresatmax
            : ""
        }${
          expiresatmin && expiresatmin !== "Invalid date"
            ? "&expiresatmin=" + expiresatmin
            : ""
        }${
          createdonmax && createdonmax !== "Invalid date"
            ? "&createdonmax=" + createdonmax
            : ""
        }${
          createdonmin && createdonmin !== "Invalid date"
            ? "&createdonmin=" + createdonmin
            : ""
        }`
      );
      let abonnements: any;
      abonnements = page ? response.data : response.data.data;
      page
        ? dispatch(allAbonnement(abonnements))
        : dispatch(allAbonnementTrue(abonnements));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneabonnement = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.abonnementApi + "/" + id
    );
    let abonnement = response.data;
    dispatch(oneAbonnement(abonnement));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export const getFraisabonnement = (id: number) => async (dispatch: any) => {
  try {
    const response = await axios.get(
      api.baseApi + api.fraisAbonnementApi + "/" + id
    );
    let fraisAbonnement = response.data;
    dispatch(allFraisAbonnement(fraisAbonnement));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export const addAbonnement =
  (abonnement: NewabonnementInterface) => async (dispatch: any) => {
    try {
      await axios.post(api.baseApi + api.abonnementApi, abonnement);
      dispatch(
        setToast({
          message: "Abonnement est ajouté avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const editAbonnement =
  (abonnement: NewabonnementInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.abonnementApi + "/" + id, abonnement);
      dispatch(
        setToast({
          message: "Abonnement est modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export default abonnementSlice.reducer;
