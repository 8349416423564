import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SpinnerPage from "../../components/SpinnerPage";

export function NoMatch() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/adherent");
  });
  return <SpinnerPage />;
}
