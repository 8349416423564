import { Autocomplete, Button, Checkbox, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  forfaitInterface,
  getAllForfait,
} from "../../redux/slices/forfaitSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import styles from "../../styles/Global.module.scss";
import BlockNumbers from "./BlockNumbers";
import ForfaitMonthChart from "./ForfaitMonthChart";
import ForfaitChart from "./ForfaitChart";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getSummaryEntreeApi,
  getSummarySortieApi,
} from "../../redux/slices/statistiquesSlice";
import SkeletonTable from "../../components/SkeletonTable";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SummaryDash() {
  const [forfait, setForfait] = useState<any>([]);
  const [year, setYear] = useState(moment(new Date()).year());
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  const forfaits: forfaitInterface[] = useSelector(
    (state: any) => state.forfait.allForfaits
  );
  const summaryEntree = useSelector(
    (state: any) => state.statistique.summaryEntree
  );
  const summarySortie = useSelector(
    (state: any) => state.statistique.summarySortie
  );

  useEffect(() => {
    dispatch(getAllForfait());
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getSummaryEntreeApi(year, "MONTH"));
    dispatch(getSummarySortieApi(year, "MONTH"));
  }, [year, forfait]);

  useEffect(() => {
    if (summaryEntree && summarySortie) {
      setLoading(false);
    }
  }, [summaryEntree, summarySortie]);

  useEffect(() => {
    setLoading(true);
  }, [year, forfait]);

  return (
    <>
      <div className="m-auto max-w-8xl">
        <Grid
          sx={{
            marginBottom: "16px",
            marginTop: "16px",
            width: "100%!important",
          }}
          justifyContent="center"
          container
          item
          xs={12}
          lg={12}
          spacing={2}
        >
          <Grid
            container
            item
            xs={12}
            lg={4}
            spacing={1}
            justifyContent="center"
          >
            <Grid item xs={10} lg={8}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  views={["year"]}
                  format="YYYY"
                  onChange={(value) => {
                    const date = moment(value).year();
                    setYear(date);
                  }}
                  sx={{
                    backgroundColor: "#fff",
                  }}
                  value={year ? moment().year(year) : null}
                  label="Année"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => setYear(moment(new Date()).year())}
                variant="contained"
                className={`${styles.ButtonCancel} w-full`}
              >
                <RestartAltIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={4}
            spacing={1}
            justifyContent="center"
          >
            <Grid item xs={10} lg={8}>
              <Autocomplete
                multiple
                disablePortal
                size="small"
                id="combo-box-demo"
                disableCloseOnSelect
                options={forfaits}
                getOptionLabel={(option) => {
                  if (option) return option.libelle;
                  return "";
                }}
                onChange={(event, value) => {
                  setForfait(value);
                }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id_forfait === value.id_forfait
                }
                value={forfait}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.libelle}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Forfait" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => setForfait([])}
                variant="contained"
                className={`${styles.ButtonCancel} w-full`}
              >
                <RestartAltIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {!loading ? (
          <>
            <div className=" mt-2 flex flex-col lg:flex-row items-center justify-center gap-5">
              <BlockNumbers
                dataEntree={summaryEntree}
                dataSortie={summarySortie}
                forfait={forfait}
                year={year}
              />
            </div>
            <Grid container gap={2} justifyContent="center">
              <Grid item xl={7} lg={12}>
                <div className="mt-2 flex flex-col lg:flex-row items-center justify-center">
                  <ForfaitMonthChart
                    dataEntree={summaryEntree}
                    forfait={forfait}
                  />
                </div>
              </Grid>
              <Grid item xl={4} lg={12}>
                <div className="mt-2 flex flex-col lg:flex-row items-center justify-center">
                  <ForfaitChart dataEntree={summaryEntree} forfait={forfait} />
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <SkeletonTable />
        )}
      </div>
    </>
  );
}
