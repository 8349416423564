import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { addUser } from "../../redux/slices/userSlice";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getAllRole, roleInterface } from "../../redux/slices/roleSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export interface MyFormValues {
  nom: string;
  prenom: string;
  username: string;
  password: string;
  role_id: string;
}

export default function AddUser() {
  const [loadingSend, setLoadingSend] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(true);
  const roles: roleInterface[] = useSelector(
    (state: any) => state.role.allRoles
  );

  useEffect(() => {
    dispatch(getAllRole());
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const schema = yup.object({
    nom: yup.string().required("Nom est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
    username: yup.string().required("Username est obligatoire"),
    password: yup.string().required("Mot de passe est obligatoire"),
    role_id: yup.string().required("Role est obligatoire"),
  });

  const initialValues: MyFormValues = {
    nom: "",
    prenom: "",
    username: "",
    password: "",
    role_id: "",
  };
  return (
    <div>
      <Link className="w-auto inline-block" to="/user">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des utilisateurs
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Ajouter un utilisateur{" "}
      </h1>
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            setLoadingSend(true);
            await dispatch(addUser(values));
          } finally {
            navigate("/user");
            setLoadingSend(false);
          }
        }}
      >
        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Nom"
                  name="nom"
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  error={!!errors.nom}
                  helperText={errors.nom ? errors.nom : ""}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Prénom"
                  name="prenom"
                  size="small"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.prenom}
                  helperText={errors.prenom ? errors.prenom : ""}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Username"
                  name="username"
                  size="small"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.username}
                  helperText={errors.username ? errors.username : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Mot de passe"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  size="small"
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password}
                  helperText={errors.password ? errors.password : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl error={!!errors.role_id} size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Role de l'utilisateur
                  </InputLabel>
                  <Select
                    name="role_id"
                    labelId="demo-simple-select-label"
                    value={values.role_id}
                    label="Role de l'utilisateur"
                    onChange={handleChange}
                  >
                    {roles.length > 0 &&
                      roles.map((role: any) => {
                        return (
                          <MenuItem value={role.id_role}>
                            {role.libelle}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {errors.role_id && (
                    <FormHelperText>{errors.role_id}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div className="border-t-2 border-gray-900/18">
                  <div className="mt-4 flex items-center justify-end gap-x-6">
                    <Link to="/user">
                      <Button
                        className={styles.ButtonCancel}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                      >
                        Annuler
                      </Button>
                    </Link>
                    <Button
                      className={styles.ButtonValid}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                      variant="contained"
                      type="submit"
                    >
                      Ajouter
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
