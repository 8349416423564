import { Alert, Snackbar } from "@mui/material";
import React, { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import { resetToast } from "../redux/slices/ToastSlice";

export default function Toast({ children }: PropsWithChildren) {
  const dispatch = useDispatch<AppDispatch>();

  const message = useSelector((state: any) => state.toast.message);
  const open = useSelector((state: any) => state.toast.open);
  const type = useSelector((state: any) => state.toast.type);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (open) {
      timeoutId = setTimeout(() => {
        dispatch(resetToast());
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, open]);

  return (
    <>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={() => dispatch(resetToast())}
      >
        <Alert
          onClose={() => dispatch(resetToast())}
          severity={type}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
