import React from "react";
import AddVirement from "../../pages/Virement/AddVirement";
import { Box, Modal } from "@mui/material";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  employeId: any;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};

export default function Virements(props: ModalProps) {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <AddVirement
          modal={true}
          handleClose={props.handleClose}
          employeId={props.employeId}
        />
      </Box>
    </Modal>
  );
}
