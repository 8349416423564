import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  remiseInterface,
  editRemise,
  getOneRemise,
} from "../../redux/slices/remiseSlice";
import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SpinnerBlock from "../../components/SpinnerBlock";

const MySwal = withReactContent(Swal);

export default function EditRemise() {
  const [loadingSend, setLoadingSend] = useState(false);
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const remise: remiseInterface = useSelector(
    (state: any) => state.remise.remise
  );
  const loading = useSelector((state: any) => state.remise.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneRemise(id));
    } else {
      navigate("/remise");
    }
  }, []);

  const schema = yup.object({
    libelle: yup.string().required("Libélle est obligatoire"),
    montant: yup.string().required("Montant est obligatoire"),
  });

  return (
    <div>
      <Link className="w-auto inline-block" to="/remise">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des remises
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Modifier une remise{" "}
      </h1>

      {!loading && Object.keys(remise).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={remise}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            setLoadingSend(true);
            const newRemise = await dispatch(
              editRemise(object, values.id_remise)
            );
            if (newRemise) {
              MySwal.fire({
                icon: "success",
                title: "Remise est modifiée avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then((result) => {
                setLoadingSend(false);
                navigate("/remise");
              });
            }
            setLoadingSend(false);
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Libelle"
                    name="libelle"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    value={values.libelle}
                    error={!!errors.libelle}
                    helperText={errors.libelle ? errors.libelle : ""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Montant"
                    name="montant"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    value={values.montant}
                    fullWidth
                    error={!!errors.montant}
                    helperText={errors.montant ? errors.montant : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="border-t-2 border-gray-900/18">
                    <div className="mt-4 flex items-center justify-end gap-x-6">
                      <Link to="/remise">
                        <Button
                          className={styles.ButtonCancel}
                          startIcon={
                            loadingSend && <CircularProgress size={20} />
                          }
                          disabled={loadingSend}
                          variant="contained"
                        >
                          Annuler
                        </Button>
                      </Link>
                      <Button
                        className={styles.ButtonValid}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
