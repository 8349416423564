import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface fraisInterface {
  id_frais: number;
  montant: number;
  libelle: string;
  Frais: fraisInterface;
  mois: number;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewfraisInterface {
  montant: number | null;
  libelle: string;
  mois: number | null;
}

const initialState = {
  frais_s: [],
  allFrais: [],
  frais: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const fraisSlice = createSlice({
  name: "frais",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allFrais(state, action) {
      state.frais_s = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allFraisTrue(state, action) {
      state.allFrais = action.payload;
      state.error = "";
    },
    oneFrais(state, action) {
      state.frais = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allFrais,
  allFraisTrue,
  oneFrais,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = fraisSlice.actions;

export const getAllFrais =
  (page?: number, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.fraisApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}`
      );
      let frais: any;
      frais = page ? response.data : response.data.data;
      page ? dispatch(allFrais(frais)) : dispatch(allFraisTrue(frais));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneFrais = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.fraisApi + "/" + id);
    let frais = response.data;
    dispatch(oneFrais(frais));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addFrais = (frais: NewfraisInterface) => async (dispatch: any) => {
  try {
    const newFrais = await axios.post(api.baseApi + api.fraisApi, frais);
    return newFrais;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const editFrais =
  (frais: fraisInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.fraisApi + "/" + id, frais);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default fraisSlice.reducer;
