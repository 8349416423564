import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { setToast } from "./ToastSlice";

//Produit
export interface produitInterface {
  id_produit: any;
  libelle: string;
  quantite: number;
  prixventedefault: number;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewProduitInterface {
  libelle: string;
  quantite: number;
  prixventedefault: number;
}

//Achat
export interface achatInterface {
  id_achat: any;
  produit_id: string;
  Produit: produitInterface;
  prixachat: number;
  quantite: number;
  dateachat: Date;
  cancelled: boolean;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewAchatInterface {
  produit_id: string;
  prixachat: number;
  quantite: number;
  dateachat: Date | string;
  cancelled: boolean;
}

//Vente
export interface venteInterface {
  id_vente: any;
  produit_id: string;
  Produit: produitInterface;
  prixvente: number;
  quantite: number;
  datevente: Date;
  cancelled: boolean;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewVenteInterface {
  produit_id: string;
  prixvente: number;
  quantite: number;
  datevente: Date | string;
  cancelled: boolean;
}

//Stock
export interface stockInterface {
  id_stock: any;
  produit_id: string;
  Produit: produitInterface;
  quantiteancienne: number;
  quantitereelle: number;
  observation: string;
  datestock: Date;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewStockInterface {
  produit_id: string;
  quantiteancienne: number;
  quantitereelle: number;
  observation: string;
  datestock: Date | string;
}

const initialState = {
  allProduits: [],
  produits: [],
  produit: {},
  achats: [],
  achat: {},
  ventes: [],
  vente: {},
  stocks: [],
  stock: {},
  loadingAllProduits: true,
  loadingProduit: true,
  loadingAchat: true,
  loadingVente: true,
  loadingStock: true,
  loadingProduitOne: true,
  loadingAchatOne: true,
  loadingVenteOne: true,
  loadingStockOne: true,
};

const gestionStockSlice = createSlice({
  name: "gestionStock",
  initialState,
  reducers: {
    allProduitTrue(state, action) {
      state.allProduits = action.payload;
      state.loadingAllProduits = false;
    },
    allProduit(state, action) {
      state.produits = action.payload;
      state.produit = {};
      state.loadingProduit = false;
      state.loadingProduitOne = true;
    },
    oneProduit(state, action) {
      state.produit = action.payload;
      state.loadingProduitOne = false;
    },
    allAchat(state, action) {
      state.achats = action.payload;
      state.achat = {};
      state.loadingAchat = false;
      state.loadingAchatOne = true;
    },
    oneAchat(state, action) {
      state.achat = action.payload;
      state.loadingAchatOne = false;
    },
    allVente(state, action) {
      state.ventes = action.payload;
      state.vente = {};
      state.loadingVente = false;
      state.loadingVenteOne = true;
    },
    oneVente(state, action) {
      state.vente = action.payload;
      state.loadingVenteOne = false;
    },
    allStock(state, action) {
      state.stocks = action.payload;
      state.stock = {};
      state.loadingStockOne = true;
      state.loadingStock = false;
    },
    oneStock(state, action) {
      state.stocks = action.payload;
      state.loadingStockOne = false;
    },
    setLoadingOneFalse(state) {
      state.loadingAchatOne = true;
      state.loadingProduitOne = true;
      state.loadingVenteOne = true;
      state.loadingStockOne = true;
    },
    setLoadingAllFalse(state) {
      state.loadingAchat = true;
      state.loadingProduit = true;
      state.loadingVente = true;
      state.loadingStock = true;
    },
  },
});

export const {
  allProduitTrue,
  allProduit,
  allAchat,
  allStock,
  allVente,
  oneProduit,
  oneAchat,
  oneStock,
  oneVente,
  setLoadingOneFalse,
  setLoadingAllFalse,
} = gestionStockSlice.actions;

//Produits Api Calls
export const getAllProduits =
  (page?: number, libelle?: string) => async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${api.baseApi}${api.produitApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}`
      );
      let produits: any;
      produits = page ? response.data : response.data.data;
      page
        ? dispatch(allProduit(produits))
        : dispatch(allProduitTrue(produits));
      dispatch(allProduit(response.data));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };

export const addProduit =
  (produit: NewProduitInterface) => async (dispatch: any) => {
    try {
      const newProduit = await axios.post(
        api.baseApi + api.produitApi,
        produit
      );
      dispatch(
        setToast({
          message: "Produit ajouté avec succes",
          type: "success",
        })
      );
      return newProduit;
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };

export const getOneProduit = (id: number | string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.produitApi + "/" + id);
    let produit = response.data;
    dispatch(oneProduit(produit));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

export const editProduit =
  (produit: NewProduitInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.produitApi + "/" + id, produit);
      dispatch(
        setToast({
          message: "Produit modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };

//Ventes Api Calls
export const getAllVentes =
  (
    page: number,
    produit_id?: string,
    dateventemax?: Date | string,
    dateventemin?: Date | string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingAllFalse());
      const response = await axios.get(
        `${api.baseApi}${api.venteApi}${"/?page=" + page}${
          produit_id ? "&produit_id=" + produit_id : ""
        }${
          dateventemax && dateventemax !== "Invalid date"
            ? "&dateventemax=" + dateventemax
            : ""
        }${
          dateventemin && dateventemin !== "Invalid date"
            ? "&dateventemin=" + dateventemin
            : ""
        }`
      );
      dispatch(allVente(response.data));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };

export const addVente = (vente: NewVenteInterface) => async (dispatch: any) => {
  try {
    const newVente = await axios.post(api.baseApi + api.venteApi, vente);
    dispatch(
      setToast({
        message: "Vente ajouté avec succes",
        type: "success",
      })
    );
    return newVente;
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

export const getOneVente = (id: number | string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.venteApi + "/" + id);
    let vente = response.data;
    dispatch(oneVente(vente));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

export const editVente =
  (vente: NewVenteInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.venteApi + "/" + id, vente);
      dispatch(
        setToast({
          message: "Vente modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };
export const cancelVente = (id: number) => async (dispatch: any) => {
  try {
    await axios.put(api.baseApi + api.venteApi + "/cancel/" + id);
    dispatch(
      setToast({
        message: "Vente annulé avec succes",
        type: "success",
      })
    );
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

//Achats Api Calls
export const getAllAchats =
  (
    page: number,
    produit_id?: string,
    dateachatmax?: Date | string,
    dateachatmin?: Date | string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingAllFalse());
      const response = await axios.get(
        `${api.baseApi}${api.achatApi}${"/?page=" + page}${
          produit_id ? "&produit_id=" + produit_id : ""
        }${
          dateachatmax && dateachatmax !== "Invalid date"
            ? "&dateachatmax=" + dateachatmax
            : ""
        }${
          dateachatmin && dateachatmin !== "Invalid date"
            ? "&dateachatmin=" + dateachatmin
            : ""
        }`
      );
      dispatch(allAchat(response.data));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };

export const addAchat = (achat: NewAchatInterface) => async (dispatch: any) => {
  try {
    const newAchat = await axios.post(api.baseApi + api.achatApi, achat);
    dispatch(
      setToast({
        message: "Achat ajouté avec succes",
        type: "success",
      })
    );
    return newAchat;
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

export const getOneAchat = (id: number | string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.achatApi + "/" + id);
    let achat = response.data;
    dispatch(oneAchat(achat));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

export const editAchat =
  (achat: NewAchatInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.achatApi + "/" + id, achat);
      dispatch(
        setToast({
          message: "Achat modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };
export const cancelAchat = (id: number) => async (dispatch: any) => {
  try {
    await axios.put(api.baseApi + api.achatApi + "/cancel/" + id);
    dispatch(
      setToast({
        message: "Achat annulé avec succes",
        type: "success",
      })
    );
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

//Stocks Api Calls
export const getAllStocks =
  (page: number, produit_id?: string) => async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${api.baseApi}${api.stockApi}${"/?page=" + page}${
          produit_id ? "&produit_id=" + produit_id : ""
        }`
      );
      dispatch(allStock(response.data));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };

export const addStock = (stock: NewStockInterface) => async (dispatch: any) => {
  try {
    const newStock = await axios.post(api.baseApi + api.stockApi, stock);
    dispatch(
      setToast({
        message: "Stock ajouté avec succes",
        type: "success",
      })
    );
    return newStock;
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

export const getOneStock = (id: number | string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.stockApi + "/" + id);
    let stock = response.data;
    dispatch(oneStock(stock));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};

export const editStock =
  (stock: NewStockInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.stockApi + "/" + id, stock);
      dispatch(
        setToast({
          message: "Stock modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };

export default gestionStockSlice.reducer;
