import { Text, View, Page, Document, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { dict_status } from "../services/constants";
// Create styles

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },

  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3E3E3E",
  },

  titleContainer: { marginTop: 24 },

  reportTitle: {
    fontSize: 9,
    textAlign: "right",
    fontFamily: "Helvetica-Bold",
  },

  invoiceNumber: { fontSize: 11 },

  bold: { fontFamily: "Helvetica-Bold" },

  invoice: { fontSize: 25, marginBottom: 15, fontFamily: "Helvetica-Bold" },

  theader: {
    marginTop: 20,
    fontSize: 10,
    fontStyle: "bold",
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    height: 20,
    backgroundColor: "#DEDEDE",
    borderColor: "whitesmoke",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },

  theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

  tbody: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    borderColor: "whitesmoke",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },

  total: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1.5,
    borderColor: "whitesmoke",
    borderBottomWidth: 1,
  },

  tbody2: { flex: 2, borderRightWidth: 1 },
});

const InvoiceTitle = ({ gym }: any) => {
  return (
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>{gym.libelle}</Text>
      <Text style={styles.invoice}>Facture </Text>
    </View>
  );
};

const Address = ({ paiement, datedebut, datefin }: any) => {
  return (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.invoiceNumber}>
            <Text style={styles.bold}>Numero de Paiement: </Text>{" "}
            {paiement.numero}{" "}
          </Text>
          <Text style={styles.invoiceNumber}>
            <Text style={styles.bold}>Adhérent : </Text>
            {paiement.Adherent?.nom + " " + paiement.Adherent?.prenom}{" "}
          </Text>
          <Text style={styles.invoiceNumber}>
            <Text style={styles.bold}>Date de Paiement: </Text>
            {paiement.datepaiement
              ? moment(paiement.datepaiement).format("YYYY-MM-DD")
              : ""}{" "}
          </Text>
          {paiement.status === "PAID_INCOMPLETE" && (
            <Text style={styles.invoiceNumber}>
              <Text style={styles.bold}>Prochain Paiement: </Text>
              {paiement.datenextpaiement
                ? moment(paiement.datenextpaiement).format("YYYY-MM-DD")
                : ""}
            </Text>
          )}
          {paiement.status === "PAID_REFUNDED" &&
            paiement.RefundPaiement.length > 0 && (
              <Text style={styles.invoiceNumber}>
                <Text style={styles.bold}>Remboursement de Paiement : </Text>
                {paiement.RefundPaiement[0].numero}
              </Text>
            )}
          {paiement.status === "REFUNDED" && paiement.RefundedPaiement && (
            <Text style={styles.invoiceNumber}>
              <Text style={styles.bold}>Paiement Remboursé : </Text>
              {paiement.RefundedPaiement.numero}
            </Text>
          )}
        </View>
        <View>
          <Text style={styles.invoiceNumber}>
            <Text style={styles.bold}>Date Début : </Text>
            {datedebut ? moment(datedebut).format("YYYY-MM-DD") : ""}
          </Text>
          <Text style={styles.invoiceNumber}>
            <Text style={styles.bold}>Date Fin : </Text>
            {datefin ? moment(datefin).format("YYYY-MM-DD") : ""}
          </Text>
          {paiement.status === "PAID_INCOMPLETE" && (
            <Text style={styles.invoiceNumber}>
              <Text style={styles.bold}>Montat payé : </Text>
              {paiement.resteapayer
                ? parseFloat(paiement.total.toString()) -
                  parseFloat(paiement.resteapayer.toString()) +
                  " dhs"
                : ""}
            </Text>
          )}
          <Text style={styles.invoiceNumber}>
            <Text style={styles.bold}>Status : </Text>
            {dict_status[paiement.status]}
          </Text>
        </View>
      </View>
    </View>
  );
};

const TableHead = () => {
  return (
    <View style={styles.titleContainer}>
      <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
        <View style={[styles.theader, styles.theader2]}>
          <Text>~#</Text>
        </View>
        <View style={styles.theader}>
          <Text>Services</Text>
        </View>
        <View style={styles.theader}>
          <Text></Text>
        </View>
        <View style={styles.theader}>
          <Text>Prix</Text>
        </View>
      </View>
    </View>
  );
};

const TableBody = ({ paiement }: any) => {
  return (
    <>
      {paiement.forfaits
        ? paiement.forfaits.map((value: any) => {
            return (
              <View key={value.Forfait?.libelle}>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  <View style={[styles.tbody, styles.tbody2]}>
                    <Text>1</Text>
                  </View>
                  <View style={styles.tbody}>
                    <Text>{value.Forfait?.libelle ?? ""} </Text>
                  </View>
                  <View style={styles.tbody}>
                    <Text></Text>
                  </View>
                  <View style={styles.tbody}>
                    <Text>{value.prixforfait}</Text>
                  </View>
                </View>
              </View>
            );
          })
        : null}
      {paiement.sessions
        ? paiement.sessions.map((value: any) => {
            return (
              <View key={value.Forfait?.libelle}>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  <View style={[styles.tbody, styles.tbody2]}>
                    <Text>1</Text>
                  </View>
                  <View style={styles.tbody}>
                    <Text>{value.Forfait?.libelle ?? ""} </Text>
                  </View>
                  <View style={styles.tbody}>
                    <Text></Text>
                  </View>
                  <View style={styles.tbody}>
                    <Text>{value.total}</Text>
                  </View>
                </View>
              </View>
            );
          })
        : null}
      {paiement.frais
        ? paiement.frais.map((value: any) => {
            return (
              <View key={value.Frais.libelle}>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  <View style={[styles.tbody, styles.tbody2]}>
                    <Text>1</Text>
                  </View>
                  <View style={styles.tbody}>
                    <Text>{value.Frais.libelle} </Text>
                  </View>
                  <View style={styles.tbody}>
                    <Text></Text>
                  </View>
                  <View style={styles.tbody}>
                    <Text>{value.Frais.montant}</Text>
                  </View>
                </View>
              </View>
            );
          })
        : null}
      {paiement.prixremise ? (
        <View>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <View style={[styles.tbody, styles.tbody2]}>
              <Text>1</Text>
            </View>
            <View style={styles.tbody}>
              <Text>Remise</Text>
            </View>
            <View style={styles.tbody}>
              <Text></Text>
            </View>
            <View style={styles.tbody}>
              <Text>- {paiement.prixremise}</Text>
            </View>
          </View>
        </View>
      ) : null}
    </>
  );
};

const TableTotal = ({ paiement }: any) => {
  return (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={styles.total}>
        <Text></Text>
      </View>
      <View style={styles.total}>
        <Text></Text>
      </View>
      <View style={styles.tbody}>
        <Text>Total</Text>
      </View>
      <View style={styles.tbody}>
        <Text>{paiement.total}</Text>
      </View>
    </View>
  );
};

// Create Document Component
const ReceiptPdf = ({ paiement, gym }: any) => {
  var datedebut = null;
  var datefin = null;

  if (paiement) {
    if (paiement.forfaits?.length > 0) {
      datedebut = paiement.forfaits[0].datedebut;
      datefin = paiement.forfaits[0].datefin;
    }
    if (paiement.sessions?.length > 0) {
      datedebut = paiement.sessions[0].datedebut;
      datefin = paiement.sessions[0].datefin;
    }
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <InvoiceTitle gym={gym} />
          <Address
            paiement={paiement}
            datedebut={datedebut}
            datefin={datefin}
          />
          <TableHead />
          <TableBody paiement={paiement} />
          <TableTotal paiement={paiement} />
        </Page>
      </Document>
    );
  }
  return null;
};

export default ReceiptPdf;
