import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  forfaitInterface,
  editForfait,
  getOneForfait,
} from "../../redux/slices/forfaitSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SpinnerBlock from "../../components/SpinnerBlock";
import { fraisInterface, getAllFrais } from "../../redux/slices/fraisSlice";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EditForfait() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const forfait: forfaitInterface = useSelector(
    (state: any) => state.forfait.forfait
  );
  const frais: fraisInterface[] = useSelector(
    (state: any) => state.frais.allFrais
  );
  const loading = useSelector((state: any) => state.forfait.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneForfait(id));
      dispatch(getAllFrais());
    } else {
      navigate("/forfait");
    }

    return () => {
      setInitialValues({});
    };
  }, []);

  useEffect(() => {
    if (Object.keys(forfait).length > 0) {
      const values = {
        id_forfait: forfait.id_forfait,
        libelle: forfait.libelle,
        montantannee: forfait.montantannee,
        montantsixmois: forfait.montantsixmois,
        montanttroismois: forfait.montanttroismois,
        montantunmois: forfait.montantunmois,
        frais: forfait.frais.map((value: any) => ({
          id_frais: value.Frais.id_frais,
          libelle: value.Frais.libelle,
        })),
      };
      setInitialValues(values);
    }
  }, [forfait]);

  const schema = yup.object({
    libelle: yup.string().required("Libélle est obligatoire"),
    montantannee: yup.number().required("Montant Pour 12 mois est obligatoire"),
    montantsixmois: yup
      .number()
      .required("Montant Pour 6 mois est obligatoire"),
    montanttroismois: yup
      .number()
      .required("Montant Pour 3 mois est obligatoire"),
    montantunmois: yup.number().required("Montant Pour 1 mois est obligatoire"),
    frais: yup
      .array(),
  });

  return (
    <div>
      <Link className="w-auto inline-block" to="/forfait">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des forfaits
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Modifier un forfait{" "}
      </h1>
      {!loading && Object.keys(initialValues).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={async (values) => {
            let object = {
              ...values,
              frais: values.frais.map((value: any) => ({
                frais_id: value.id_frais,
              })),
            };
            try {
              setLoadingSend(true);
              await dispatch(editForfait(object, values.id_forfait));
            } finally {
              setLoadingSend(false);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Libelle"
                    name="libelle"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    error={!!errors.libelle}
                    value={values.libelle}
                    helperText={
                      touched.libelle &&
                      (typeof errors.libelle === "string" ||
                        errors.libelle instanceof String)
                        ? errors.libelle
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Montant 1 mois"
                    name="montantunmois"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.montantunmois}
                    helperText={
                      touched.montantunmois &&
                      (typeof errors.montantunmois === "string" ||
                        errors.montantunmois instanceof String)
                        ? errors.montantunmois
                        : ""
                    }
                    value={values.montantunmois}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Montant 3 mois"
                    name="montanttroismois"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.montanttroismois}
                    helperText={
                      touched.montanttroismois &&
                      (typeof errors.montanttroismois === "string" ||
                        errors.montanttroismois instanceof String)
                        ? errors.montanttroismois
                        : ""
                    }
                    value={values.montanttroismois}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Montant 6 mois"
                    name="montantsixmois"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.montantsixmois}
                    helperText={
                      touched.montantsixmois &&
                      (typeof errors.montantsixmois === "string" ||
                        errors.montantsixmois instanceof String)
                        ? errors.montantsixmois
                        : ""
                    }
                    value={values.montantsixmois}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Montant 12 mois"
                    name="montantannee"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.montantannee}
                    helperText={
                      touched.montantannee &&
                      (typeof errors.montantannee === "string" ||
                        errors.montantannee instanceof String)
                        ? errors.montantannee
                        : ""
                    }
                    value={values.montantannee}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    id="frais"
                    size="small"
                    options={frais}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.libelle}
                    onChange={(_, value) => {
                      setFieldValue("frais", value);
                    }}
                    value={values.frais}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id_frais === value.id_frais
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.libelle}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Frais"
                        error={touched.frais && Boolean(errors.frais)}
                        helperText={
                          touched.frais &&
                          (typeof errors.frais === "string" ||
                            errors.frais instanceof String)
                            ? errors.frais
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="border-t-2 border-gray-900/18">
                    <div className="mt-4 flex items-center justify-end gap-x-6">
                      <Link to="/forfait">
                        <Button
                          className={styles.ButtonCancel}
                          startIcon={
                            loadingSend && <CircularProgress size={20} />
                          }
                          disabled={loadingSend}
                          variant="contained"
                        >
                          Annuler
                        </Button>
                      </Link>
                      <Button
                        className={styles.ButtonValid}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
