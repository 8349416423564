import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  produitInterface,
  editProduit,
  getOneProduit,
} from "../../redux/slices/gestionStockSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SpinnerBlock from "../../components/SpinnerBlock";

const MySwal = withReactContent(Swal);

export default function EditProduit() {
  const [loadingSend, setLoadingSend] = useState(false);
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const produit: produitInterface = useSelector(
    (state: any) => state.gestionStock.produit
  );
  const loading = useSelector((state: any) => state.gestionStock.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneProduit(id));
    } else {
      navigate("/produit");
    }
  }, []);

  const schema = yup.object({
    libelle: yup.string().required("Libélle est obligatoire"),
    prixventedefault: yup.number().required("Prix de Vente est obligatoire"),
  });

  return (
    <div>
      <Link className="w-auto inline-block" to="/produit">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des produits
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Modifier un produit{" "}
      </h1>

      {!loading && Object.keys(produit).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={produit}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            try {
              setLoadingSend(true);
              await dispatch(editProduit(object, values.id_produit));
            } finally {
              setLoadingSend(false);
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Libelle"
                    name="libelle"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    value={values.libelle}
                    error={!!errors.libelle}
                    helperText={errors.libelle ? errors.libelle : ""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Quantité"
                    name="quantite"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    disabled
                    value={values.quantite}
                    error={!!errors.quantite}
                    helperText={errors.quantite ? errors.quantite : ""}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="prix de vente"
                    name="prixventedefault"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleChange}
                    fullWidth
                    value={values.prixventedefault}
                    error={!!errors.prixventedefault}
                    helperText={
                      errors.prixventedefault ? errors.prixventedefault : ""
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="border-t-2 border-gray-900/18">
                    <div className="mt-4 flex items-center justify-end gap-x-6">
                      <Link to="/produit">
                        <Button
                          className={styles.ButtonCancel}
                          startIcon={
                            loadingSend && <CircularProgress size={20} />
                          }
                          disabled={loadingSend}
                          variant="contained"
                        >
                          Annuler
                        </Button>
                      </Link>
                      <Button
                        className={styles.ButtonValid}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
