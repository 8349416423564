import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface remiseInterface {
  id_remise: number;
  montant: number;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewremiseInterface {
  montant: string;
  libelle: string;
}

const initialState = {
  remises: [],
  allRemises: [],
  remise: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const remiseSlice = createSlice({
  name: "remise",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allRemise(state, action) {
      state.remises = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allRemiseTrue(state, action) {
      state.allRemises = action.payload;
      state.error = "";
    },
    oneRemise(state, action) {
      state.remise = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allRemise,
  allRemiseTrue,
  oneRemise,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = remiseSlice.actions;

export const getAllRemise =
  (page?: number, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.remiseApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}`
      );
      let remises: any;
      remises = page ? response.data : response.data.data;
      page ? dispatch(allRemise(remises)) : dispatch(allRemiseTrue(remises));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneRemise = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.remiseApi + "/" + id);
    let remise = response.data;
    dispatch(oneRemise(remise));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addRemise =
  (remise: NewremiseInterface) => async (dispatch: any) => {
    try {
      const newRemise = await axios.post(api.baseApi + api.remiseApi, remise);
      return newRemise;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editRemise =
  (remise: remiseInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.remiseApi + "/" + id, remise);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default remiseSlice.reducer;
