import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Global.module.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { forfaitInterface, getAllForfait } from "../redux/slices/forfaitSlice";
import { employeInterface, getAllEmploye } from "../redux/slices/employeSlice";
import {
  adherentInterface,
  getAllAdherent,
} from "../redux/slices/adherentSlice";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  getAllProduits,
  produitInterface,
} from "../redux/slices/gestionStockSlice";

export default function SearchBar({
  getData,
  AddText,
  loading,
  page,
  setPage,
  isFirstRender,
  noButtonAdd,
  rechecheHolder,
  dateHolder,
  types = [],
  emptySearchs,
  titlePage,
}: any) {
  const [search, setSearch] = useState("");
  const [reference, setReference] = useState("");
  const [telephone, setTelephone] = useState("");
  const [cin, setCin] = useState("");
  const [status, setStatus] = useState("");
  const [numbermois, setNumberMois] = useState("");
  const [forfait, setForfait] = useState<any>(null);
  const [produit, setProduit] = useState<any>(null);
  const [coach, setCoach] = useState<any>(null);
  const [adherent, setAdherent] = useState<any>(null);
  const [date, setDate] = useState<any>(null);
  const [dateMax, setDateMax] = useState<any>(null);
  const [dateMin, setDateMin] = useState<any>(null);
  const [createdOnMax, setCreatedOnMax] = useState<any>(null);
  const [createdOnMin, setCreatedOnMin] = useState<any>(null);
  const [datemois, setDatemois] = useState<any>(null);
  const [type, setType] = useState("");
  const [avecremise, setAvecremise] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const searchInput = useRef(null);

  const forfaits: forfaitInterface[] = useSelector(
    (state: any) => state.forfait.allForfaits
  );
  const coachs: employeInterface[] = useSelector(
    (state: any) => state.employe.allEmployes
  );
  const adherents: adherentInterface[] = useSelector(
    (state: any) => state.adherent.allAdherents
  );
  const produits: produitInterface[] = useSelector(
    (state: any) => state.gestionStock.allProduits
  );
  const referenceInput = useRef(null);
  const telephoneInput = useRef(null);
  const cinInput = useRef(null);

  useEffect(() => {
    if (types.includes("forfait")) {
      dispatch(getAllForfait());
    }
    if (types.includes("coach")) {
      dispatch(getAllEmploye(undefined, "", "Coach"));
    }
    if (types.includes("adherent")) {
      dispatch(getAllAdherent());
    }
    if (types.includes("produit")) {
      dispatch(getAllProduits());
    }
  }, []);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setType("search");
  };
  const handleChangeTelephone = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTelephone(event.target.value);
    setType("tel");
  };
  const handleChangeCin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCin(event.target.value);
    setType("cin");
  };
  const handleChangeReference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReference(event.target.value);
    setType("ref");
  };
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };
  const handleChangeNumberMois = (event: SelectChangeEvent) => {
    setNumberMois(event.target.value as string);
  };

  const handleAvecremiseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAvecremise(event.target.checked);
  };

  const numberOfBlocks = useMemo(() => {
    let total = types.length;
    if (types.includes("createdon")) total++;
    if (types.includes("dateminmax")) total++;
    return total;
  }, [types]);

  const searchData = () => {
    let array: any = [];
    if (emptySearchs) {
      array.length = emptySearchs;
      array.fill("");
    }
    if (types.includes("search")) array.push(search);
    if (types.includes("reference")) array.push(reference);
    if (types.includes("telephone")) array.push(telephone);
    if (types.includes("cin")) array.push(cin);
    if (types.includes("forfait")) array.push(forfait?.id_forfait);
    if (types.includes("produit")) array.push(produit?.id_produit);
    if (types.includes("adherent")) array.push(adherent?.id_adherent);
    if (types.includes("coach")) array.push(coach?.id_employe);
    if (types.includes("statusabo") || types.includes("statuspaiement"))
      array.push(status);
    if (types.includes("numbermois")) array.push(numbermois);
    if (types.includes("avecremise")) array.push(avecremise);
    if (types.includes("date")) array.push(date);
    if (types.includes("datemois")) array.push(datemois);
    if (types.includes("dateminmax")) {
      array.push(dateMax);
      array.push(dateMin);
    }
    if (types.includes("createdon")) {
      array.push(createdOnMax);
      array.push(createdOnMin);
    }
    console.log(array);
    return array;
  };

  const resetFilter = () => {
    setSearch("");
    setReference("");
    setTelephone("");
    setCin("");
    setStatus("");
    setForfait(null);
    setProduit(null);
    setCoach(null);
    setAdherent(null);
    setDate(null);
    setDateMax(null);
    setDateMin(null);
    setCreatedOnMax(null);
    setCreatedOnMin(null);
    setDatemois(null);
    setType("");
    setAvecremise(false);
    setNumberMois("");
  };

  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        if (page === 1) {
          dispatch(getData(1, ...searchData()));
        } else {
          setPage("", 1);
        }
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [search, telephone, cin, reference]);

  useEffect(() => {
    if (!isFirstRender) {
      if (page === 1) {
        dispatch(getData(1, ...searchData()));
      } else {
        setPage("", 1);
      }
    }
  }, [
    date,
    dateMin,
    dateMax,
    datemois,
    createdOnMax,
    createdOnMin,
    status,
    forfait,
    produit,
    adherent,
    coach,
    avecremise,
    numbermois,
  ]);

  useEffect(() => {
    if (!isFirstRender) {
      dispatch(getData(page, ...searchData()));
    }
  }, [page]);

  useEffect(() => {
    if (searchInput.current && type === "search")
      (searchInput.current as HTMLInputElement).focus();
    if (telephoneInput.current && type === "tel")
      (telephoneInput.current as HTMLInputElement).focus();
    if (cinInput.current && type === "cin")
      (cinInput.current as HTMLInputElement).focus();
    if (referenceInput.current && type === "ref")
      (referenceInput.current as HTMLInputElement).focus();
  }, [loading]);

  return (
    <>
      <div
        className={`relative ${
          numberOfBlocks > 5 ? "lg:grid lg:grid-cols-5" : ""
        } flex flex-col lg:flex-row gap-2 mb-2 lg:mb-4`}
      >
        <Button
          onClick={() => resetFilter()}
          variant="contained"
          className={`${styles.ButtonCancel} self-end !block lg:!hidden`}
        >
          <RestartAltIcon />
        </Button>
        {types.includes("reference") && (
          <form className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleChangeReference}
                value={reference}
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="Référence ..."
                required
                ref={referenceInput}
                disabled={loading}
              />
            </div>
          </form>
        )}
        {types.includes("search") && (
          <form className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                onChange={handleChangeSearch}
                value={
                  rechecheHolder === "Nom ou Prénom"
                    ? search.toUpperCase()
                    : search
                }
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder={rechecheHolder ? rechecheHolder : "Rechercher ..."}
                required
                ref={searchInput}
                disabled={loading}
              />
            </div>
          </form>
        )}
        {types.includes("telephone") && (
          <form className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search-telephone"
                onChange={handleChangeTelephone}
                value={telephone}
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="Téléphone ..."
                required
                ref={telephoneInput}
                disabled={loading}
              />
            </div>
          </form>
        )}
        {types.includes("cin") && (
          <form className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4H1m3 4H1m3 4H1m3 4H1m6.071.286a3.429 3.429 0 1 1 6.858 0M4 1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm9 6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search-cin"
                onChange={handleChangeCin}
                value={cin}
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                placeholder="CIN ..."
                required
                ref={cinInput}
                disabled={loading}
              />
            </div>
          </form>
        )}
        {types.includes("forfait") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo"
                options={forfaits}
                disabled={loading}
                getOptionLabel={(option) => {
                  if (option) return option.libelle;
                }}
                onChange={(event, value) => {
                  setForfait(value);
                }}
                value={forfait ?? null}
                renderInput={(params) => (
                  <TextField {...params} label="Forfait" />
                )}
              />
            </div>
          </div>
        )}
        {types.includes("produit") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo"
                options={produits}
                disabled={loading}
                getOptionLabel={(option) => {
                  if (option) return option.libelle;
                }}
                onChange={(event, value) => {
                  setProduit(value);
                }}
                value={produit ?? null}
                renderInput={(params) => (
                  <TextField {...params} label="Produit" />
                )}
              />
            </div>
          </div>
        )}
        {types.includes("adherent") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo"
                options={adherents}
                disabled={loading}
                getOptionLabel={(option) => {
                  return (option.nom + " " + option.prenom).toUpperCase();
                }}
                onChange={(event, value) => {
                  setAdherent(value);
                }}
                value={adherent}
                renderInput={(params) => (
                  <TextField {...params} label="Adherent" />
                )}
              />
            </div>
          </div>
        )}
        {types.includes("coach") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo"
                options={coachs}
                disabled={loading}
                getOptionLabel={(option) => {
                  return (option.nom + " " + option.prenom).toUpperCase();
                }}
                onChange={(event, value) => {
                  setCoach(value);
                }}
                value={coach}
                renderInput={(params) => (
                  <TextField {...params} label="Coach" />
                )}
              />
            </div>
          </div>
        )}
        {types.includes("date") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={(value) => {
                    const date = moment(value).format("YYYY-MM-DD");
                    setDate(date);
                  }}
                  disabled={loading}
                  value={date ? moment(date) : null}
                  label={dateHolder}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        )}
        {types.includes("dateminmax") && (
          <>
            <div className="flex items-center flex-1 lg:w-full">
              <div className="relative w-full">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setDateMin(date);
                    }}
                    disabled={loading}
                    value={dateMin ? moment(dateMin) : null}
                    label={dateHolder ? dateHolder + " après" : "Date après"}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="flex items-center flex-1 lg:w-full">
              <div className="relative w-full">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setDateMax(date);
                    }}
                    disabled={loading}
                    value={dateMax ? moment(dateMax) : null}
                    label={dateHolder ? dateHolder + " avant" : "Date avant"}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </>
        )}
        {types.includes("datemois") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  views={["month", "year"]}
                  format="MM/YYYY"
                  onChange={(value) => {
                    const date = moment(value);
                    date.date(1);
                    setDatemois(date.format("YYYY-MM-DD"));
                  }}
                  disabled={loading}
                  value={datemois ? moment(datemois) : null}
                  label="Mois payé"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        )}
        {types.includes("statuspaiement") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  name="status"
                  labelId="demo-simple-select-label"
                  value={status}
                  label="Status"
                  disabled={loading}
                  onChange={handleChangeStatus}
                >
                  <MenuItem value="">-----</MenuItem>
                  <MenuItem value="PAID">Payé</MenuItem>
                  <MenuItem value="PENDING">En attente</MenuItem>
                  <MenuItem value="CANCELLED">Annulé</MenuItem>
                  <MenuItem value="REFUNDED">Remboursé</MenuItem>
                  <MenuItem value="PAID_REFUNDED">Payé ( remboursé )</MenuItem>
                  <MenuItem value="PAID_INCOMPLETE">
                    Payé avec Facilité
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        )}
        {types.includes("numbermois") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Nombre de mois
                </InputLabel>
                <Select
                  name="numberMois"
                  labelId="demo-simple-select-label"
                  value={numbermois}
                  label="Nombre de mois"
                  disabled={loading}
                  onChange={handleChangeNumberMois}
                >
                  <MenuItem value="">-----</MenuItem>
                  <MenuItem value="1">1 Mois</MenuItem>
                  <MenuItem value="3">3 Mois</MenuItem>
                  <MenuItem value="6">6 Mois</MenuItem>
                  <MenuItem value="12">12 Mois</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        )}
        {types.includes("statusabo") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  name="status"
                  labelId="demo-simple-select-label"
                  value={status}
                  label="Status"
                  disabled={loading}
                  onChange={handleChangeStatus}
                >
                  <MenuItem value="">-----</MenuItem>
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="EXPIRED">Expiré</MenuItem>
                  <MenuItem value="PENDING">En attente</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        )}
        {types.includes("createdon") && (
          <>
            <div className="flex items-center flex-1 lg:w-full">
              <div className="relative w-full">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setCreatedOnMin(date);
                    }}
                    disabled={loading}
                    value={createdOnMin ? moment(createdOnMin) : null}
                    label={"Crée après"}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="flex items-center flex-1 lg:w-full">
              <div className="relative w-full">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setCreatedOnMax(date);
                    }}
                    disabled={loading}
                    value={createdOnMax ? moment(createdOnMax) : null}
                    label={"Crée avant"}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </>
        )}
        {types.includes("avecremise") && (
          <div className="flex items-center flex-1 lg:w-full">
            <div className="relative w-full">
              <FormControlLabel
                disabled={loading}
                control={
                  <Checkbox
                    checked={avecremise}
                    onChange={handleAvecremiseChange}
                  />
                }
                label="Avec Remise"
                labelPlacement="end"
              />
            </div>
          </div>
        )}
        <Button
          onClick={() => resetFilter()}
          variant="contained"
          disabled={loading}
          className={`${styles.ButtonCancel} lg:!block !hidden w-fit `}
        >
          <RestartAltIcon />
        </Button>
      </div>
      <div className="flex flex-col-reverse lg:flex-row mb-8 items-center justify-between">
        <h1 className="text-2xl text-center font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
          {titlePage}
        </h1>

        {!noButtonAdd && (
          <Button
            variant="contained"
            onClick={() => navigate("new")}
            className={`${styles.ButtonValid} w-full !mb-3 lg:!mb-0 lg:w-auto`}
          >
            + Ajouter {AddText}
          </Button>
        )}
      </div>
    </>
  );
}
