import Dashboard from "../pages/Dashboard/DetailsDash";
import SummaryDash from "../pages/Dashboard/SummaryDash";
import Adherent from "../pages/Adherent";
import AddAdherent from "../pages/Adherent/AddAdherent";
import User from "../pages/User";
import AddUser from "../pages/User/AddUser";
import Remise from "../pages/Remise";
import Forfait from "../pages/Forfait";
import AddRemise from "../pages/Remise/AddRemise";
import AddForfait from "../pages/Forfait/AddForfait";
import Frais from "../pages/Frais";
import AddFrais from "../pages/Frais/AddFrais";
import Paiement from "../pages/Paiement";
import AdherentDetails from "../pages/Adherent/AdherentDetails";
import Role from "../pages/Role";
import AddRole from "../pages/Role/AddRole";
import AddPaiement from "../pages/Paiement/AddPaiement";
import Depense from "../pages/Depense";
import AddDepense from "../pages/Depense/AddDepense";
import EditRemise from "../pages/Remise/EditRemise";
import EditForfait from "../pages/Forfait/EditForfait";
import EditFrais from "../pages/Frais/EditFrais";
import EditRole from "../pages/Role/EditRole";
import EditDepense from "../pages/Depense/EditDepense";
import PaiementDetails from "../pages/Paiement/PaiementDetails";
import EditUser from "../pages/User/EditUser";
import Employe from "../pages/Employe";
import AddEmploye from "../pages/Employe/AddEmploye";
import Virement from "../pages/Virement";
import AddVirement from "../pages/Virement/AddVirement";
import EditVirement from "../pages/Virement/EditVirement";
import EmployeDetails from "../pages/Employe/EmployeDetails";
import Abonnement from "../pages/Abonnement";
import AddAbonnement from "../pages/Abonnement/AddAbonnement";
import AbonnementDetails from "../pages/Abonnement/AbonnementDetails";
import Coaching from "../pages/Coaching";
import AddCoaching from "../pages/Coaching/AddCoaching";
import EditCoaching from "../pages/Coaching/EditCoaching";
import Session from "../pages/Session";
import DetailSession from "../pages/Session/DetailSession";
import Produit from "../pages/Produit";
import AddProduit from "../pages/Produit/AddProduit";
import EditProduit from "../pages/Produit/EditProduit";
import Achat from "../pages/Achat";
import AddAchat from "../pages/Achat/AddAchat";
import Vente from "../pages/Vente";
import AddVente from "../pages/Vente/AddVente";
import Stock from "../pages/Stock";
import AddStock from "../pages/Stock/AddStock";
import Journal from "../pages/JournalPaiement";
import Revenus from "../pages/revenuMensuel";
interface ChildRoute {
  path: string;
  element: () => JSX.Element;
  show: string[];
}

export interface Route {
  path: string;
  element?: () => JSX.Element;
  children?: ChildRoute[];
  show?: string[];
}

const routes: Route[] = [
  {
    path: "/summary-dashboard",
    element: SummaryDash,
    show: ["admin"],
  },
  {
    path: "/details-dashboard",
    element: Dashboard,
    show: ["admin"],
  },
  {
    path: "/revenu-mensuel",
    element: Revenus,
    show: ["admin"],
  },
  {
    path: "/adherent",
    children: [
      {
        path: "",
        element: Adherent,
        show: ["admin", "recept"],
      },
      {
        path: "new",
        element: AddAdherent,
        show: ["admin", "recept"],
      },
      {
        path: ":id",
        element: AdherentDetails,
        show: ["admin", "recept"],
      },
    ],
  },
  {
    path: "/user",
    children: [
      {
        path: "",
        element: User,
        show: ["admin"],
      },
      {
        path: "new",
        element: AddUser,
        show: ["admin"],
      },
      {
        path: ":id",
        element: EditUser,
        show: ["admin"],
      },
    ],
  },
  {
    path: "/coaching",
    children: [
      {
        path: "",
        element: Coaching,
        show: ["admin", "recept"],
      },
      {
        path: "new",
        element: AddCoaching,
        show: ["admin", "recept"],
      },
      {
        path: ":id",
        element: EditCoaching,
        show: ["admin", "recept"],
      },
    ],
  },
  {
    path: "/remise",
    children: [
      {
        path: "",
        element: Remise,
        show: ["admin"],
      },
      {
        path: "new",
        element: AddRemise,
        show: ["admin"],
      },
      {
        path: ":id",
        element: EditRemise,
        show: ["admin"],
      },
    ],
  },
  {
    path: "/abonnement",
    children: [
      {
        path: "",
        element: Abonnement,
        show: ["admin", "recept"],
      },
      {
        path: "new",
        element: AddAbonnement,
        show: ["admin", "recept"],
      },
      {
        path: ":id",
        element: AbonnementDetails,
        show: ["admin", "recept"],
      },
    ],
  },
  {
    path: "/session",
    children: [
      {
        path: "",
        element: Session,
        show: ["admin"],
      },
      {
        path: ":id",
        element: DetailSession,
        show: ["admin"],
      },
    ],
  },
  {
    path: "/forfait",
    children: [
      {
        path: "",
        element: Forfait,
        show: ["admin"],
      },
      {
        path: "new",
        element: AddForfait,
        show: ["admin"],
      },
      {
        path: ":id",
        element: EditForfait,
        show: ["admin"],
      },
    ],
  },
  {
    path: "/frais",
    children: [
      {
        path: "",
        element: Frais,
        show: ["admin"],
      },
      {
        path: "new",
        element: AddFrais,
        show: ["admin"],
      },
      {
        path: ":id",
        element: EditFrais,
        show: ["admin"],
      },
    ],
  },
  {
    path: "/role",
    children: [
      {
        path: "",
        element: Role,
        show: ["admin"],
      },
      {
        path: "new",
        element: AddRole,
        show: ["admin"],
      },
      {
        path: ":id",
        element: EditRole,
        show: ["admin"],
      },
    ],
  },
  {
    path: "/depense",
    children: [
      {
        path: "",
        element: Depense,
        show: ["admin", "recept"],
      },
      {
        path: "new",
        element: AddDepense,
        show: ["admin", "recept"],
      },
      {
        path: ":id",
        element: EditDepense,
        show: ["admin", "recept"],
      },
    ],
  },
  {
    path: "/paiement",
    children: [
      {
        path: "",
        element: Paiement,
        show: ["admin", "recept"],
      },
      {
        path: "new",
        element: AddPaiement,
        show: ["admin", "recept"],
      },
      {
        path: ":id",
        element: PaiementDetails,
        show: ["admin", "recept"],
      },
    ],
  },
  {
    path: "/journal",
    children: [
      {
        path: "",
        element: Journal,
        show: ["admin", "recept"],
      },
    ],
  },
  {
    path: "/employe",
    children: [
      {
        path: "",
        element: Employe,
        show: ["admin"],
      },
      {
        path: "new",
        element: AddEmploye,
        show: ["admin"],
      },
      {
        path: ":id",
        element: EmployeDetails,
        show: ["admin"],
      },
    ],
  },
  {
    path: "/virement",
    children: [
      {
        path: "",
        element: Virement,
        show: ["admin"],
      },
      {
        path: "new",
        element: () => <AddVirement />,
        show: ["admin"],
      },
      {
        path: ":id",
        element: EditVirement,
        show: ["admin"],
      },
    ],
  },
  {
    path: "/produit",
    children: [
      {
        path: "",
        element: Produit,
        show: ["admin", "recept"],
      },
      {
        path: "new",
        element: AddProduit,
        show: ["admin", "recept"],
      },
      {
        path: ":id",
        element: EditProduit,
        show: ["admin", "recept"],
      },
    ],
  },
  {
    path: "/achat",
    children: [
      {
        path: "",
        element: Achat,
        show: ["admin", "recept"],
      },
      {
        path: "new",
        element: AddAchat,
        show: ["admin", "recept"],
      },
    ],
  },
  {
    path: "/vente",
    children: [
      {
        path: "",
        element: Vente,
        show: ["admin", "recept"],
      },
      {
        path: "new",
        element: AddVente,
        show: ["admin", "recept"],
      },
    ],
  },
  {
    path: "/stock",
    children: [
      {
        path: "",
        element: Stock,
        show: ["admin", "recept"],
      },
      {
        path: "new",
        element: AddStock,
        show: ["admin", "recept"],
      },
    ],
  },
];

export default routes;
