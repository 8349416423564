import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../styles/Global.module.scss";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import SouthIcon from "@mui/icons-material/South";
import { AppDispatch } from "../../redux/store";
import Spinner from "../../components/Spinner";
import NorthIcon from "@mui/icons-material/North";
interface Props {
  dataEntree: any;
  dataSortie: any;
  forfait: any[];
  year: any;
}

function formatNumber(n: number) {
  return new Intl.NumberFormat("fr-FR").format(n);
}
export default function BlockNumbers({
  dataEntree,
  dataSortie,
  forfait,
  year,
}: Props) {
  const [totalSortie, setTotalSortie] = useState(0);
  const [totalEntree, setTotalEntree] = useState(0);

  useEffect(() => {
    if (
      dataEntree &&
      dataEntree.length > 0 &&
      dataSortie &&
      dataSortie.length > 0
    ) {
      const totalS = dataSortie.reduce(function (
        accumulator: any,
        currentValue: any
      ) {
        return accumulator + currentValue.total;
      },
      0);
      var arrayToCalcul = [];
      if (forfait && forfait.length > 0) {
        arrayToCalcul = forfait.map((forf: any) => {
          return dataEntree.find((e: any) => {
            return e.forfait === forf.libelle;
          });
        });
        arrayToCalcul = arrayToCalcul.filter((value) => value !== undefined);
      }

      if (forfait && forfait.length === 0) {
        arrayToCalcul = [
          dataEntree.find((e: any) => {
            return e.forfait === "Tous les forfaits";
          }),
        ];
      }
      const totalData = arrayToCalcul.map((element) => {
        return element.data.reduce(function (
          accumulator: any,
          currentValue: any
        ) {
          return accumulator + currentValue.revenus;
        },
        0);
      });
      const totalE = totalData.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue;
      }, 0);
      setTotalSortie(totalS);
      setTotalEntree(totalE);
    }
  }, [dataEntree, dataSortie, forfait, year]);

  return (
    <>
      <div className={`shadow-sm border-2 rounded-md ${styles.ClientInfo2}`}>
        {true ? (
          <>
            <SouthIcon
              style={{
                color: "green",
              }}
            />
            <div>
              <p>Total Entrées</p>
              <h1
                style={{
                  color: "green",
                }}
              >
                {formatNumber(totalEntree)} Dhs
              </h1>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
      <div className={`shadow-sm border-2 rounded-md ${styles.ClientInfo2}`}>
        {true ? (
          <>
            <NorthIcon
              style={{
                color: "red",
              }}
            />
            <div>
              <p>Total Sorties</p>
              <h1
                style={{
                  color: "red",
                }}
              >
                {formatNumber(totalSortie)} Dhs
              </h1>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
}
