import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface disciplineInterface {
  id_discipline: number;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewdisciplineInterface {
  libelle: string;
}

const initialState = {
  disciplines: [],
  allDisciplines: [],
  discipline: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const disciplineSlice = createSlice({
  name: "discipline",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allDiscipline(state, action) {
      state.disciplines = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allDisciplineTrue(state, action) {
      state.allDisciplines = action.payload;
      state.error = "";
    },
    oneDiscipline(state, action) {
      state.discipline = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allDiscipline,
  allDisciplineTrue,
  oneDiscipline,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = disciplineSlice.actions;

export const getAllDiscipline =
  (page?: number, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.disciplineApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}`
      );
      let disciplines: any;
      disciplines = page ? response.data : response.data.data;
      page
        ? dispatch(allDiscipline(disciplines))
        : dispatch(allDisciplineTrue(disciplines));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneDiscipline = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.disciplineApi + "/" + id
    );
    let discipline = response.data;
    dispatch(oneDiscipline(discipline));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addDiscipline =
  (discipline: NewdisciplineInterface) => async (dispatch: any) => {
    try {
      const newDiscipline = await axios.post(
        api.baseApi + api.disciplineApi,
        discipline
      );
      return newDiscipline;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editDiscipline =
  (discipline: disciplineInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.disciplineApi + "/" + id, discipline);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default disciplineSlice.reducer;
