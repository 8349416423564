import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Checkbox, TextField } from "@mui/material";
import styles from "../../styles/Global.module.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  forfaitInterface,
  getAllForfait,
} from "../../redux/slices/forfaitSlice";
import moment from "moment";
import axios from "axios";
import api from "../../redux/api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export default function Journal() {
  const [forfait, setForfait] = useState<any>([]);
  const [datepaiementmax, setdatepaiementmax] = useState<any>(null);
  const [datepaiementmin, setdatepaiementmin] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const dispatch = useDispatch<AppDispatch>();
  const localStorageUser = localStorage.getItem("user");
  let gymid = 1;
  if (localStorageUser) {
    let userObject = JSON.parse(localStorageUser);
    gymid = userObject.gym_id;
  }

  const forfaits: forfaitInterface[] = useSelector(
    (state: any) => state.forfait.allForfaits
  );

  useEffect(() => {
    dispatch(getAllForfait());
  }, []);

  const downloadJournal = () => {
    if (
      forfait &&
      datepaiementmin != null &&
      datepaiementmin !== "Invalid date" &&
      datepaiementmax != null &&
      datepaiementmax !== "Invalid date"
    ) {
      axios
        .post(
          `${api.pythonApi}/journal/paiement`,
          {
            gym_id: gymid,
            forfait_ids: forfait.map((d: any) => {
              return d.id_forfait;
            }),
            datepaiementmax: datepaiementmax,
            datepaiementmin: datepaiementmin,
          },
          {
            responseType: "blob", // Set the responseType to 'arraybuffer' to get binary data
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");

          link.href = url;
          const noSpecialChars =
            forfait.id_forfait +
            "_" +
            datepaiementmin.replace(/[^a-zA-Z0-9 ]/g, "") +
            "_" +
            datepaiementmax.replace(/[^a-zA-Z0-9 ]/g, "");
          link.download = "paiement_" + noSpecialChars + ".pdf"; // The default name for the downloaded file
          link.click();
          URL.revokeObjectURL(url);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error downloading PDF:", error);
        });
    }
  };

  return (
    <>
      <div className="flex flex-col-reverse lg:flex-row mb-8 items-center justify-between">
        <h1 className="text-2xl text-center font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
          Journal de Paiement
        </h1>
      </div>
      <div className={`relative flex flex-col lg:flex-row gap-2 mb-2 lg:mb-4`}>
        <div className="flex items-center flex-1 lg:w-full">
          <div className="relative w-full">
            <Autocomplete
              multiple
              disablePortal
              disableCloseOnSelect
              size="small"
              id="combo-box-demo"
              options={forfaits}
              disabled={loading}
              getOptionLabel={(option) => {
                if (option) return option.libelle;
              }}
              onChange={(event, value) => {
                setForfait(value);
              }}
              value={forfait ?? null}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.libelle}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Forfait" />
              )}
            />
          </div>
        </div>
        <div className="flex items-center flex-1 lg:w-full">
          <div className="relative w-full">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) => {
                  const date = moment(value).format("YYYY-MM-DD");
                  setdatepaiementmin(date);
                }}
                disabled={loading}
                value={datepaiementmin ? moment(datepaiementmin) : null}
                label={"Date après"}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="flex items-center flex-1 lg:w-full">
          <div className="relative w-full">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) => {
                  const date = moment(value).format("YYYY-MM-DD");
                  setdatepaiementmax(date);
                }}
                disabled={loading}
                value={datepaiementmax ? moment(datepaiementmax) : null}
                label={"Date avant"}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <Button
          onClick={() => downloadJournal()}
          variant="contained"
          disabled={loading}
          className={`${styles.ButtonValid}`}
        >
          Télécharger
        </Button>
      </div>
    </>
  );
}
