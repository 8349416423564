import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Location,
  Outlet,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { logout, refreshToken } from "../redux/slices/authSclice";
import Header from "./Header";
import SideBar from "./SideBar";
import SpinnerPage from "./SpinnerPage";
import protectedRoute from "../utils/protectedRoute";
import Cookies from "universal-cookie";
import { AppDispatch } from "../redux/store";
import moment from "moment";
const cookies = new Cookies();

const getRoutePath = (location: Location, params: Params): string => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

export default function Layout() {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);
  const [user, setUser] = useState<any>({});
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const userStorage = localStorage.getItem("user");
      if (userStorage !== null) {
        setUser(JSON.parse(userStorage));
      }
    }
    const date = new Date(cookies.get("date"));
    const todayDate = new Date();
    const days = moment(todayDate).diff(moment(date), "days", true);
    if (days >= 0.8) {
      const localRefreshToken: string = cookies.get("refresh_token") ?? "";
      dispatch(refreshToken(localRefreshToken));
    }
  }, [location, dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (path.charAt(path.length - 1) === "/") {
        let str = path.slice(0, -1);
        if (!protectedRoute(str.toLowerCase(), user.role))
          navigate("/adherent");
      } else {
        if (!protectedRoute(path.toLowerCase(), user.role))
          navigate("/adherent");
      }
    }
  }, [user, path]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  return (
    <>
      {isAuthenticated ? (
        <div>
          <SideBar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            user={user}
            logout={handleLogout}
          />
          <div className="px-4 py-2 md:ml-64">
            <Header
              user={user}
              handleDrawerToggle={handleDrawerToggle}
              logout={handleLogout}
            />
            <div
              className={path.includes("dashboard") ? "" : "m-auto max-w-5xl"}
            >
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <SpinnerPage />
      )}
    </>
  );
}
