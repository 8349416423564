import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  deleteDepense,
  depenseInterface,
  getAllDepense,
} from "../../redux/slices/depenseSlice";
import { Meta } from "../../services/interfaces";
import { Link } from "react-router-dom";
import moment from "moment";
import SkeletonTable from "../../components/SkeletonTable";
import { Pagination, ThemeProvider } from "@mui/material";
import { theme } from "../../styles/global";
import SearchBar from "../../components/SearchBar";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
const MySwal = withReactContent(Swal);

interface depensePaginationInterface {
  meta: Meta;
  data: depenseInterface[];
}
export default function Depense() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.depense.loadingListe);
  const depenses: depensePaginationInterface = useSelector(
    (state: any) => state.depense.depenses
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllDepense(1));
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const removeDepense = (id: number, libelle: string) => {
    MySwal.fire({
      icon: "warning",
      title: "Voulez vous supprimer cette dépense: " + libelle,
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui",
      customClass: {
        confirmButton: "shadow-none",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(deleteDepense(id));
        } finally {
          dispatch(getAllDepense(page));
        }
      }
    });
  };

  return (
    <>
      <SearchBar
        getData={getAllDepense}
        page={page}
        setPage={handleChange}
        loading={loading}
        isFirstRender={isFirstRender}
        AddText="une dépense"
        rechecheHolder="Libellé"
        types={["search", "dateminmax"]}
        titlePage={"Liste des Dépenses"}
      />

      {!loading ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Libellé
                </th>
                <th scope="col" className="px-6 py-3">
                  Montant
                </th>
                <th scope="col" className="px-6 py-3">
                  Date de dépense
                </th>
                <th scope="col" className="px-6 py-3"></th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {depenses?.data?.length > 0 ? (
                depenses.data.map((depense, key) => {
                  return (
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {depense.libelle}
                      </th>
                      <td className="px-6 py-4">{depense.montant}</td>
                      <td className="px-6 py-4">
                        {depense.datedepense
                          ? moment(depense.datedepense).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td className="px-6 py-4">
                        <Link
                          to={`` + depense.id_depense + ``}
                          className="font-medium text-blue-600 hover:underline"
                        >
                          <svg
                            className="w-4 h-4 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                          </svg>
                        </Link>
                      </td>
                      <td className="px-6 py-4">
                        <span
                          onClick={() =>
                            removeDepense(depense.id_depense, depense.libelle)
                          }
                          className="font-medium text-red-700 hover:underline cursor-pointer"
                        >
                          <DeleteIcon />
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div
                      className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                      role="alert"
                    >
                      <span className="font-medium">Aucun Dépense Trouvé</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {depenses.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 15,
                marginBottom: 5,
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={depenses.meta.totalPages}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}
