import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  coachingInterface,
  editCoaching,
  getOneCoaching,
} from "../../redux/slices/coachingSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SpinnerBlock from "../../components/SpinnerBlock";
import {
  employeInterface,
  getAllEmploye,
} from "../../redux/slices/employeSlice";

export default function EditCoaching() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [adherentId, setAdherentId] = useState("");

  const coaching: coachingInterface = useSelector(
    (state: any) => state.coaching.coaching
  );
  const loading = useSelector((state: any) => state.coaching.loadingOne);

  const employes: employeInterface[] = useSelector(
    (state: any) => state.employe.allEmployes
  );

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneCoaching(id));
      dispatch(getAllEmploye(undefined, "", "Coach"));
      const localStorageAbonnement = localStorage.getItem("adherent");
      if (localStorageAbonnement) {
        let abonnementObject = JSON.parse(localStorageAbonnement);
        setAdherentId(abonnementObject.id_adherent);
      }
    } else {
      navigate("/coaching");
    }
    return () => {
      localStorage.removeItem("adherent");
    };
  }, []);

  useEffect(() => {
    if (Object.keys(coaching).length > 0) {
      const values = {
        id_paiement_coaching: coaching.id_paiement_coaching,
        moiscoaching: coaching.moiscoaching,
        prixcoaching: coaching.prixcoaching,
        datefin: coaching.datefin,
        datedebut: coaching.datedebut,
        adherent_id: coaching.adherent_id,
        coach_id: { ...coaching.Coach },
      };
      setInitialValues(values);
    }
  }, [coaching]);

  const schema = yup.object({
    coach_id: yup.object().required("Coach est obligatoire"),
    prixcoaching: yup.number().required("Prix est obligatoire"),
    moiscoaching: yup.number().required("Mois est obligatoire"),
    datedebut: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
    datefin: yup.string().test("valid-date", "Invalid date", function (value) {
      return moment(value, "YYYY-MM-DD", true).isValid();
    }),
  });

  return (
    <div>
      <Link
        className="w-auto inline-block"
        to={adherentId ? "/adherent/" + adherentId : "/coaching"}
      >
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" />
          {adherentId ? "Retour Adhérent" : "Liste des Coaching"}
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Modifier Coaching{" "}
      </h1>
      {!loading && Object.keys(initialValues).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={async (values) => {
            let object = { ...values, coach_id: values.coach_id.id_employe };
            delete object.updatedby;
            delete object.updatedon;
            try {
              setLoadingSend(true);
              await dispatch(editCoaching(object, values.id_paiement_coaching));
            } finally {
              setLoadingSend(false);
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="coach_id"
                    size="small"
                    options={employes}
                    getOptionLabel={(option) => {
                      return (option.nom + " " + option.prenom).toUpperCase();
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setFieldValue("coach_id", value);
                      }
                    }}
                    value={values.coach_id}
                    renderInput={(params) => (
                      <TextField {...params} label="Coach" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Prix"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(event) => {
                      setFieldValue("prixcoaching", event.target.value);
                    }}
                    value={values.prixcoaching ?? 0}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Nombre de Mois"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(event) => {
                      if (values.datedebut) {
                        const originalDate = moment(values.datedebut);
                        const newDate = originalDate.add(
                          event.target.value,
                          "months"
                        );
                        const formattedDate = newDate.format("YYYY-MM-DD");
                        setFieldValue("datefin", formattedDate);
                        setFieldValue("moiscoaching", event.target.value);
                      } else {
                        setFieldValue("moiscoaching", event.target.value);
                      }
                    }}
                    value={values.moiscoaching ?? 0}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(value) => {
                        const date = moment(value).format("YYYY-MM-DD");
                        if (values.moiscoaching) {
                          const originalDate = moment(value);
                          const newDate = originalDate.add(
                            values.moiscoaching,
                            "months"
                          );
                          const formattedDate = newDate.format("YYYY-MM-DD");
                          setFieldValue("datefin", formattedDate);
                          setFieldValue("datedebut", date);
                        } else {
                          setFieldValue("datedebut", date);
                        }
                      }}
                      value={values.datedebut ? moment(values.datedebut) : null}
                      label="Date Debut"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(value) => {
                        const date = moment(value).format("YYYY-MM-DD");

                        setFieldValue("datefin", date);
                      }}
                      value={values.datefin ? moment(values.datefin) : null}
                      label="Date Fin"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p className="text-sm text-gray-500">
                    créé par: {coaching.createdby}
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <div className="border-t-2 border-gray-900/18">
                    <div className="mt-4 flex items-center justify-end gap-x-6">
                      <Link to="/coaching">
                        <Button
                          className={styles.ButtonCancel}
                          startIcon={
                            loadingSend && <CircularProgress size={20} />
                          }
                          disabled={loadingSend}
                          variant="contained"
                        >
                          Annuler
                        </Button>
                      </Link>
                      <Button
                        className={styles.ButtonValid}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </Grid>

              </Grid>

            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerBlock />
      )}
    </div>
  );
}
