import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { addEmploye } from "../../redux/slices/employeSlice";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

export interface MyFormValues {
  nom: string;
  prenom: string;
  profession: string;
  datedebut: Date | null | string;
  salaire: number | null;
}

export default function AddEmploye() {
  const [loadingSend, setLoadingSend] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const schema = yup.object({
    nom: yup.string().required("Nom est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
    profession: yup.string().required("Profession est obligatoire"),
    salaire: yup.number().required("Salaire est obligatoire"),
    datedebut: yup.string().required("Date de début est obligatoire"),
  });

  const initialValues: MyFormValues = {
    nom: "",
    prenom: "",
    profession: "",
    salaire: null,
    datedebut: null,
  };
  return (
    <div>
      <Link className="w-auto inline-block" to="/employe">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des utilisateurs
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Ajouter un employé{" "}
      </h1>
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            setLoadingSend(true);
            await dispatch(addEmploye(values));
          } finally {
            setLoadingSend(false);
            navigate("/employe");
          }
        }}
      >
        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Nom"
                  name="nom"
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  error={!!errors.nom}
                  helperText={errors.nom ? errors.nom : ""}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Prénom"
                  name="prenom"
                  size="small"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.prenom}
                  helperText={errors.prenom ? errors.prenom : ""}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Salaire"
                  name="salaire"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.salaire}
                  helperText={errors.salaire ? errors.salaire : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setFieldValue("datedebut", date);
                    }}
                    value={moment(values.datedebut)}
                    label="Date de Début"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.datedebut,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
                {errors.datedebut && (
                  <span className={styles.errorHandle}>{errors.datedebut}</span>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl error={!!errors.profession} size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Profession
                  </InputLabel>
                  <Select
                    name="profession"
                    labelId="demo-simple-select-label"
                    value={values.profession}
                    label="Role de l'utilisateur"
                    onChange={handleChange}
                  >
                    <MenuItem value="Coach">Coach</MenuItem>
                    <MenuItem value="Reception">Récéption</MenuItem>
                    <MenuItem value="FemmeDeMenage">Femme de Ménage</MenuItem>
                  </Select>
                  {errors.profession && (
                    <FormHelperText>{errors.profession}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div className="border-t-2 border-gray-900/18">
                  <div className="mt-4 flex items-center justify-end gap-x-6">
                    <Link to="/employe">
                      <Button
                        className={styles.ButtonCancel}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                      >
                        Annuler
                      </Button>
                    </Link>
                    <Button
                      className={styles.ButtonValid}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                      variant="contained"
                      type="submit"
                    >
                      Ajouter
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
