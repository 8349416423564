import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface roleInterface {
  id_role: number;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewroleInterface {
  libelle: string;
}

const initialState = {
  roles: [],
  allRoles: [],
  role: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allRole(state, action) {
      state.roles = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allRoleTrue(state, action) {
      state.allRoles = action.payload;
      state.error = "";
    },
    oneRole(state, action) {
      state.role = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allRole,
  allRoleTrue,
  oneRole,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = roleSlice.actions;

export const getAllRole =
  (page?: number, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.roleApi}${page ? "/?page=" + page : "/?all=true"}${
          libelle ? "&libelle=" + libelle : ""
        }`
      );
      let roles: any;
      roles = page ? response.data : response.data.data;
      page ? dispatch(allRole(roles)) : dispatch(allRoleTrue(roles));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneRole = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.roleApi + "/" + id);
    let role = response.data;
    dispatch(oneRole(role));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addRole = (role: NewroleInterface) => async (dispatch: any) => {
  try {
    const newRole = await axios.post(api.baseApi + api.roleApi, role);
    return newRole;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const editRole =
  (role: roleInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.roleApi + "/" + id, role);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default roleSlice.reducer;
