import routes from "../services/routes";

const cleanRoutes = () => {
  const newRoutes: any = [];

  routes.map((route) => {
    if (route.children) {
      route.children.map((routeChild) => {
        newRoutes?.push({
          path:
            routeChild.path === ""
              ? route.path
              : route.path + "/" + routeChild.path,
          show: routeChild.show,
        });
      });
    } else {
      newRoutes.push({ path: route.path, show: route.show });
    }
  });
  return newRoutes;
};

const protectedRoute = (path: string, role: string) => {
  const routes = cleanRoutes();
  return routes.some(
    (element: any) => element.path === path && element.show.includes(role)
  );
};

export default protectedRoute;
