import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  addStock,
  getAllProduits,
  produitInterface,
} from "../../redux/slices/gestionStockSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export interface MyFormValues {
  produit_id: any;
  quantiteancienne: number;
  quantitereelle: number;
  observation: string;
  datestock: Date | string;
}

export default function AddStock() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const produits: produitInterface[] = useSelector(
    (state: any) => state.gestionStock.allProduits
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllProduits());
  }, []);

  const schema = yup.object({
    produit_id: yup.object().required("Produit est obligatoire"),
    quantiteancienne: yup
      .number()
      .required("Quantité Ancienne est obligatoire"),
    quantitereelle: yup.number().required("Quantité Réelle est obligatoire"),
    observation: yup.string().required("Observation est obligatoire"),
    datestock: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
  });

  const initialValues: MyFormValues = {
    produit_id: "",
    quantiteancienne: 0,
    quantitereelle: 0,
    observation: "",
    datestock: moment(new Date()).format("YYYY-MM-DD"),
  };

  return (
    <div>
      <Link className="w-auto inline-block" to="/stock">
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" /> Liste des stocks
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Ajouter un stock{" "}
      </h1>
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          const object = {
            ...values,
            produit_id: values.produit_id.id_produit,
          };
          try {
            setLoadingSend(true);
            await dispatch(addStock(object));
          } finally {
            setLoadingSend(false);
            navigate("/stock");
          }
        }}
      >
        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="produits"
                  size="small"
                  options={produits}
                  getOptionLabel={(option) => {
                    return option.libelle;
                  }}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("produit_id", value);
                      setFieldValue("quantiteancienne", value?.quantite);
                    } else {
                      setFieldValue("quantiteancienne", 0);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Produits" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Quantité Anciénne"
                  name="quantiteancienne"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange}
                  fullWidth
                  disabled
                  value={values.quantiteancienne}
                  error={!!errors.quantiteancienne}
                  helperText={
                    errors.quantiteancienne ? errors.quantiteancienne : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Quantité Réellé"
                  name="quantitereelle"
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange}
                  fullWidth
                  value={values.quantitereelle}
                  error={!!errors.quantitereelle}
                  helperText={
                    errors.quantitereelle ? errors.quantitereelle : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");

                      setFieldValue("datestock", date);
                    }}
                    value={values.datestock ? moment(values.datestock) : null}
                    label="Date de Stock"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observation"
                  name="observation"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.observation}
                  helperText={errors.observation ? errors.observation : ""}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="border-t-2 border-gray-900/18">
                  <div className="mt-4 flex items-center justify-end gap-x-6">
                    <Link to="/stock">
                      <Button
                        className={styles.ButtonCancel}
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                      >
                        Annuler
                      </Button>
                    </Link>
                    <Button
                      className={styles.ButtonValid}
                      startIcon={loadingSend && <CircularProgress size={20} />}
                      disabled={loadingSend}
                      variant="contained"
                      type="submit"
                    >
                      Ajouter
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
