import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { addCoaching } from "../../redux/slices/coachingSlice";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import styles from "../../styles/Global.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import {
  employeInterface,
  getAllEmploye,
} from "../../redux/slices/employeSlice";

export interface MyFormValues {
  adherent_id: string;
  coach_id: any;
  prixcoaching: number;
  moiscoaching: number;
  datedebut: Date | null | string;
  datefin: Date | null | string;
}

export default function AddCoaching() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [adherentId, setAdherentId] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const employes: employeInterface[] = useSelector(
    (state: any) => state.employe.allEmployes
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllEmploye(undefined, "", "Coach"));
  }, []);

  useEffect(() => {
    const localStorageAdherent = localStorage.getItem("adherent");
    if (localStorageAdherent) {
      let adherentObject = JSON.parse(localStorageAdherent);
      if (adherentObject) {
        setAdherentId(adherentObject.id_adherent);
        if (isFirstRender) {
          setIsFirstRender(false);
        }
        dispatch(getAllEmploye(undefined, "", "Coach"));
        formik.setFieldValue("adherent_id", adherentObject.id_adherent);
      }
    } else {
      navigate("/adherent");
    }
    return () => {
      localStorage.removeItem("adherent");
    };
  }, []);

  const schema = yup.object({
    coach_id: yup.object().required("Coach est obligatoire"),
    prixcoaching: yup.number().required("Prix est obligatoire"),
    moiscoaching: yup.number().required("Mois est obligatoire"),
    datedebut: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
    datefin: yup.string().test("valid-date", "Invalid date", function (value) {
      return moment(value, "YYYY-MM-DD", true).isValid();
    }),
  });

  const formik = useFormik<MyFormValues>({
    initialValues: {
      adherent_id: "",
      coach_id: null,
      prixcoaching: 0,
      moiscoaching: 0,
      datedebut: moment().format("YYYY-MM-DD"),
      datefin: moment().format("YYYY-MM-DD"),
    },
    validationSchema: schema,
    onSubmit: (values) => {
      saveInformationPaiement();
    },
  });
  const saveInformationPaiement = async () => {
    var object = { ...formik.values };
    object.coach_id = object.coach_id.id_employe;
    try {
      setLoadingSend(true);
      await dispatch(addCoaching(object));
    } finally {
      if (localStorage.getItem("adherent")) {
        navigate("/adherent/" + object.adherent_id);
        localStorage.removeItem("adherent");
      } else {
        navigate("/adherent");
      }
      setLoadingSend(false);
    }
  };
  return (
    <div>
      <Link
        className="w-auto inline-block"
        to={adherentId ? "/adherent/" + adherentId : "/adherent"}
      >
        <div className="flex items-center w-auto text-gray-800 text-base focus:outline-none border-b-2 border-white hover:border-gray-800 font-medium me-2 mb-6">
          <ArrowBackIosIcon className="!text-sm	" />{" "}
          {adherentId ? "Retour Adhérent" : "Liste des coaching"}
        </div>
      </Link>
      <h1 className="mb-8 text-2xl font-extrabold leading-none tracking-tight text-gray-800 md:text-2xl lg:text-2xl">
        Ajouter un coaching{" "}
      </h1>
      <div className="mt-4">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="coach_id"
              size="small"
              options={employes}
              getOptionLabel={(option) => {
                return (option.nom + " " + option.prenom).toUpperCase();
              }}
              onChange={(event, value) => {
                if (value) {
                  formik.setFieldValue("coach_id", value);
                }
              }}
              value={formik.values.coach_id}
              renderInput={(params) => <TextField {...params} label="Coach" />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Prix"
              size="small"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(event) => {
                formik.setFieldValue("prixcoaching", event.target.value);
              }}
              value={formik.values.prixcoaching ?? 0}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Nombre de Mois"
              size="small"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(event) => {
                if (formik.values.datedebut) {
                  const originalDate = moment(formik.values.datedebut);
                  const newDate = originalDate.add(
                    event.target.value,
                    "months"
                  );
                  const formattedDate = newDate.format("YYYY-MM-DD");
                  formik.setFieldValue("datefin", formattedDate);
                  formik.setFieldValue("moiscoaching", event.target.value);
                } else {
                  formik.setFieldValue("moiscoaching", event.target.value);
                }
              }}
              value={formik.values.moiscoaching ?? 0}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) => {
                  const date = moment(value).format("YYYY-MM-DD");
                  if (formik.values.moiscoaching) {
                    const originalDate = moment(value);
                    const newDate = originalDate.add(
                      formik.values.moiscoaching,
                      "months"
                    );
                    const formattedDate = newDate.format("YYYY-MM-DD");
                    formik.setFieldValue("datefin", formattedDate);
                    formik.setFieldValue("datedebut", date);
                  } else {
                    formik.setFieldValue("datedebut", date);
                  }
                }}
                value={
                  formik.values.datedebut
                    ? moment(formik.values.datedebut)
                    : null
                }
                label="Date Debut"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) => {
                  const date = moment(value).format("YYYY-MM-DD");

                  formik.setFieldValue("datefin", date);
                }}
                value={
                  formik.values.datefin ? moment(formik.values.datefin) : null
                }
                label="Date Fin"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <div className="border-t-2 border-gray-900/18">
              <div className="mt-4 flex items-center justify-end gap-x-6">
                <Link to="/coaching">
                  <Button
                    className={styles.ButtonCancel}
                    startIcon={loadingSend && <CircularProgress size={20} />}
                    disabled={loadingSend}
                    variant="contained"
                    onClick={() => navigate("/coaching")}
                  >
                    Annuler
                  </Button>
                </Link>
                <Button
                  className={styles.ButtonValid}
                  onClick={() => formik.handleSubmit()}
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  disabled={loadingSend}
                  variant="contained"
                >
                  Ajouter
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
