import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { employeInterface } from "./employeSlice";
import { setToast } from "./ToastSlice";

export interface virementInterface {
  id_virement_salaire: any;
  observation: string;
  employe_id: string;
  Employe: employeInterface;
  datevirement: Date;
  moisapayer: Date;
  modereglement: string;
  montant: number;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewvirementInterface {
  observation: string;
  employe_id: string;
  modereglement: string;
  datevirement: Date | null | string;
  moisapayer: Date | null | string;
  montant: number | null;
}

const initialState = {
  virements: [],
  allVirements: [],
  virement: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const virementSlice = createSlice({
  name: "virement",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allVirement(state, action) {
      state.virements = action.payload;
      state.loadingListe = false;
      state.virement = {};
      state.errorListe = "";
    },
    allVirementTrue(state, action) {
      state.allVirements = action.payload;
      state.error = "";
    },
    oneVirement(state, action) {
      state.virement = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allVirement,
  allVirementTrue,
  oneVirement,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = virementSlice.actions;

export const getAllVirement =
  (page?: number, employe_id?: string, moisapayer?: Date | string) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.virementApi}${
          page ? "/?page=" + page : "/?all=true"
        }${employe_id ? "&employe_id=" + employe_id : ""}${
          moisapayer && moisapayer !== "Invalid date"
            ? "&moisapayer=" + moisapayer
            : ""
        }`
      );
      let virements: any;
      virements = page ? response.data : response.data.data;
      page
        ? dispatch(allVirement(virements))
        : dispatch(allVirementTrue(virements));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneVirement = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.virementApi + "/" + id);
    let virement = response.data;
    dispatch(oneVirement(virement));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export const addVirement =
  (virement: NewvirementInterface) => async (dispatch: any) => {
    try {
      await axios.post(api.baseApi + api.virementApi, virement);
      dispatch(
        setToast({
          message: "Virement est ajouté avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const editVirement =
  (virement: NewvirementInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.virementApi + "/" + id, virement);
      dispatch(
        setToast({
          message: "Virement est modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export default virementSlice.reducer;
