import { NavLink } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menus, { MenuProps } from "../services/Menus";
import { Divider } from "@mui/material";
interface Props {
  window?: () => Window;
  user: any;
  mobileOpen: boolean;
  logout: () => void;
  handleDrawerToggle: () => void;
}
export default function SideBar(props: Props) {
  const { user } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  function DrawerContent(propsDrawer: any) {
    return (
      <aside
        id="default-sidebar"
        className="z-40 w-64 h-screen"
        aria-label="Sidebar"
      >
        <div className="h-full flex flex-col px-3 pt-6 pb-2 overflow-y-auto bg-gray-800">
          <ul className="space-y-2 font-medium flex flex-col">
            {Menus.map((menu: MenuProps) => {
              if (menu.title && menu.items && menu.items?.length > 0) {
                if (menu.show.includes(user.role)) {
                  return (
                    <>
                      <Divider
                        key={`${menu.title}-divider`}
                        sx={{ bgcolor: "#4b5563", marginTop: "15px!important" }}
                      />
                      <p className="text-md	text-gray-400 !mt-1">{menu.title}</p>
                      {menu.items.map((item) => {
                        if (item.show.includes(user.role)) {
                          if (item.link) {
                            return (
                              <li key={item.name}>
                                <NavLink
                                  to={item.link}
                                  className={({ isActive }) =>
                                    isActive
                                      ? "flex items-center p-2 rounded-lg primary-color bg-gray-700 group"
                                      : "flex items-center p-2 rounded-lg text-gray-500 primary-color-hover hover:bg-gray-700 group"
                                  }
                                >
                                  {item.icon}
                                  <span className="ms-3 text-white">
                                    {item.name}
                                  </span>
                                </NavLink>
                              </li>
                            );
                          } else {
                            return (
                              <li key={item.name}>
                                <Accordion
                                  expanded={expanded === item.params}
                                  onChange={handleChange(item.params ?? "")}
                                  className="!bg-inherit !shadow-none !min-h-0 !m-0"
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon sx={{ color: "white" }} />
                                    }
                                    aria-controls={item.params + "bh-content"}
                                    id={item.params + "bh-header"}
                                    className="!p-0 !min-h-0 transition duration-75 !rounded-lg group hover:bg-gray-700"
                                  >
                                    <button
                                      type="button"
                                      className="flex items-center w-full p-2 text-base text-gray-500 primary-color-hover hover:bg-gray-700 group"
                                    >
                                      {item.icon}

                                      <span className="flex-1 ms-3 text-left !text-white">
                                        {item.name}
                                      </span>
                                    </button>
                                  </AccordionSummary>
                                  <AccordionDetails className="!p-0">
                                    <ul className="py-2 space-y-2">
                                      {item.children?.map((child) => {
                                        if (child.show.includes(user.role)) {
                                          return (
                                            <li key={`${child.name}-child`}>
                                              <NavLink
                                                to={child.link ?? ""}
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? "flex items-center w-full p-2 transition duration-75 rounded-lg pl-11 group !text-white bg-gray-700"
                                                    : "flex items-center w-full p-2 transition duration-75 rounded-lg pl-11 group !text-white hover:bg-gray-700"
                                                }
                                              >
                                                {child.name}
                                              </NavLink>
                                            </li>
                                          );
                                        }
                                      })}
                                    </ul>
                                  </AccordionDetails>
                                </Accordion>
                              </li>
                            );
                          }
                        }
                      })}
                    </>
                  );
                }
              } else {
                if (menu.show && menu.show.includes(user.role)) {
                  return (
                    <li key={menu.name}>
                      <NavLink
                        to={menu.link ?? "/"}
                        className={({ isActive }) =>
                          isActive
                            ? "flex items-center p-2 rounded-lg primary-color bg-gray-700 group"
                            : "flex items-center p-2 rounded-lg text-gray-500 primary-color-hover hover:bg-gray-700 group"
                        }
                      >
                        {menu.icon}
                        <span className="ms-3 !text-white">{menu.name}</span>
                      </NavLink>
                    </li>
                  );
                }
              }
            })}
          </ul>
          {propsDrawer.isMobile && (
            <li
              onClick={() => props.logout()}
              data-testid="logout-item"
              className="cursor-pointer !mt-auto block md:hidden"
            >
              <span className="flex items-center p-2 rounded-lg text-gray-500 hover:bg-gray-700 group">
                <svg
                  className="flex-shrink-0 w-5 h-5  transition duration-75 group-hover:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 15"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 7.5h11m0 0L8 3.786M12 7.5l-4 3.714M12 1h3c.53 0 1.04.196 1.414.544.375.348.586.82.586 1.313v9.286c0 .492-.21.965-.586 1.313A2.081 2.081 0 0 1 15 14h-3"
                  />
                </svg>
                <span className="ms-3 text-white">Déconnexion</span>
              </span>
            </li>
          )}
        </div>
      </aside>
    );
  }

  return (
    <>
      <Drawer
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        className="block md:hidden"
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box" },
        }}
      >
        <DrawerContent isMobile={true} />
      </Drawer>
      <div className="fixed hidden md:block">
        <DrawerContent isMobile={false} />
      </div>
    </>
  );
}
