import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  stockInterface,
  getAllStocks,
} from "../../redux/slices/gestionStockSlice";
import { Meta } from "../../services/interfaces";
import { Link } from "react-router-dom";
import SkeletonTable from "../../components/SkeletonTable";
import { Pagination, ThemeProvider } from "@mui/material";
import { theme } from "../../styles/global";
import SearchBar from "../../components/SearchBar";
import moment from "moment";

interface stockPaginationInterface {
  meta: Meta;
  data: stockInterface[];
}

export default function Stock() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: any) => state.gestionStock.loadingStock);
  const stocks: stockPaginationInterface = useSelector(
    (state: any) => state.gestionStock.stocks
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllStocks(1));
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <>
      <SearchBar
        getData={getAllStocks}
        page={page}
        setPage={handleChange}
        loading={loading}
        isFirstRender={isFirstRender}
        AddText="un stock"
        types={["produit"]}
        titlePage={"Liste des Stocks"}
      />

      {!loading ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Nom Produit
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantité Ancienne
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantité Réelle
                </th>
                <th scope="col" className="px-6 py-3">
                  Observation
                </th>
                <th scope="col" className="px-6 py-3">
                  Date de Stock
                </th>
              </tr>
            </thead>
            <tbody>
              {stocks?.data?.length > 0 ? (
                stocks.data.map((stock, key) => {
                  return (
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {stock.Produit?.libelle}
                      </th>
                      <td className="px-6 py-4">{stock.quantiteancienne}</td>
                      <td className="px-6 py-4 uppercase">
                        {stock.quantitereelle}
                      </td>
                      <td className="px-6 py-4">{stock.observation}</td>
                      <td className="px-6 py-4 uppercase">
                        {stock.datestock
                          ? moment(stock.datestock).format("YYYY-MM-DD")
                          : ""}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div
                      className="p-4 mb-4 flex justify-center text-sm text-red-800 rounded-lg bg-red-50"
                      role="alert"
                    >
                      <span className="font-medium">Aucun Stock Trouvé</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {stocks.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 15,
                marginBottom: 5,
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={stocks.meta.totalPages}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}
