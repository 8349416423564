import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { roleInterface } from "./roleSlice";
import { setToast } from "./ToastSlice";

export interface userInterface {
  id_user: any;
  nom: string;
  prenom: string;
  username: string;
  password: string;
  role_id: string;
  Role: roleInterface;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewuserInterface {
  nom: string;
  prenom: string;
  username: string;
  password?: string;
  role_id: string;
}
export interface EdituserInterface {
  nom: string;
  prenom: string;
  username: string;
  role_id: string;
}

const initialState = {
  users: [],
  allUsers: [],
  user: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allUser(state, action) {
      state.users = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allUserTrue(state, action) {
      state.allUsers = action.payload;
      state.error = "";
    },
    oneUser(state, action) {
      state.user = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.loadingListe = true;
      state.loadingOne = true;
      state.error = action.payload;
    },
  },
});

export const {
  allUser,
  allUserTrue,
  oneUser,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = userSlice.actions;

export const getAllUser =
  (page?: number, nomprenom?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.userApi}${page ? "/?page=" + page : "/?all=true"}${
          nomprenom ? "&nomprenom=" + nomprenom : ""
        }`
      );
      let users: any;
      users = page ? response.data : response.data.data;
      page ? dispatch(allUser(users)) : dispatch(allUserTrue(users));
    } catch (error: any) {
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneUser = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.userApi + "/" + id);
    let user = response.data;
    dispatch(oneUser(user));
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export const addUser = (user: NewuserInterface) => async (dispatch: any) => {
  try {
    await axios.post(api.baseApi + api.userApi, user);
    dispatch(
      setToast({
        message: "Utilisateur est ajouté avec succes",
        type: "success",
      })
    );
  } catch (error: any) {
    dispatch(
      setToast({
        message: error.response.data.message,
        type: "error",
      })
    );
    dispatch(getError(error.response.data.message));
  }
};

export const editUser =
  (user: EdituserInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.userApi + "/" + id, user);
      dispatch(
        setToast({
          message: "Utilisateur est modifié avec succes",
          type: "success",
        })
      );
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
      dispatch(
        setToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };

export default userSlice.reducer;
